import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const getAccountsList = createAsyncThunk(
  "accounts/list",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/getAllAccounts", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admin_jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateCustomerPortalSelection = createAsyncThunk(
  "accounts/updateCustomerPortalSelection",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/updateCustomerPortalSelection", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAccountSettings = createAsyncThunk(
  "account/settings",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/getAccountSettings", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addAccountSettings = createAsyncThunk(
  "account/settings/add",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/addAccountSettings", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type" : "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const inviteAccount = createAsyncThunk(
  "account/create/add",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/inviteAccount", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAccountDetailsByUniqueId = createAsyncThunk(
  "account/details/unique",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/getAccountDetailsByUniqueId", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admin_jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState: {
    isGetAccountsListError: false,
    isGetAccountsListSuccess: false,
    isGetAccountsListFetching: false,
    getAccountsListErrorMessage: "",
    accountsList: [],
    isUpdateCustomerPortalSelectionFetching: false,
    isUpdateCustomerPortalSelectionSuccess: false,
    isUpdateCustomerPortalSelectionError: false,
    updateCustomerPortalSelectionErrorMessage: "",
    updateCustomerPortalSelectionData: "",
    accountSessionToken:"",

    isGetAccountSettingsError: false,
    isGetAccountSettingsSuccess: false,
    isGetAccountSettingsFetching: false,
    getAccountsSettingsErrorMessage: "",
    accountSettings: [],

    isGetAccountDetailsByUniqueIdError: false,
    isGetAccountDetailsByUniqueIdSuccess: false,
    isGetAccountDetailsByUniqueIdFetching: false,
    getAccountDetailsByUniqueIdErrorMessage: "",
    accountDetails: [],

    isAddAccountSettingsError: false,
    isAddAccountSettingsSuccess: false,
    isAddAccountSettingsFetching: false,
    addAccountSettingsErrorMessage: "",    

    isInviteAccountError: false,
    isInviteAccountSuccess: false,
    isInviteAccountFetching: false,
    inviteAccountErrorMessage: "",
  },
  reducers: {
    clearGetAccountsListState: (state) => {
      state.isGetAccountsListError = false;
      state.isGetAccountsListSuccess = false;
      state.isGetAccountsListFetching = false;
      state.getAccountsListErrorMessage = "";
      state.accountsList = [];
      return state;
    },
    clearGetAccountDetailsByUniqueIdState: (state) => {
      state.isGetAccountDetailsByUniqueIdError = false;
      state.isGetAccountDetailsByUniqueIdSuccess = false;
      state.isGetAccountDetailsByUniqueIdFetching = false;
      state.getAccountDetailsByUniqueIdErrorMessage = "";
      state.accountDetails=[];
      return state;
    },
    clearInviteAccountState: (state) => {
      state.isInviteAccountError = false;
      state.isInviteAccountSuccess = false;
      state.isInviteAccountFetching = false;
      state.inviteAccountErrorMessage = "";
      return state;
    },
    clearGetAccountSettingsState: (state) => {
      state.isGetAccountSettingsError = false;
      state.isGetAccountSettingsFetching = false;
      state.isGetAccountSettingsSuccess = false;
      state.getAccountsListErrorMessage = "";
      state.accountSettings = [];
      return state;
    },
    clearAddAccountSettingsState: (state) => {
      state.isAddAccountSettingsError = false;
      state.isAddAccountSettingsSuccess = false;
      state.isAddAccountSettingsFetching = false;
      state.addAccountSettingsErrorMessage = "";
      return state;
    },
    clearUpdateCustomerPortalSelectionState: (state) => {
      state.isUpdateCustomerPortalSelectionError = false;
      state.isUpdateCustomerPortalSelectionFetching = false;
      state.isUpdateCustomerPortalSelectionSuccess = false;
      state.updateCustomerPortalSelectionErrorMessage = "";
      state.updateCustomerPortalSelectionData = "";
      state.accountSessionToken = "";
      return state;
    },
  },
  extraReducers: {
    [getAccountDetailsByUniqueId.fulfilled]: (state, { payload }) => {
      state.isGetAccountDetailsByUniqueIdFetching = false;
      state.accountDetails = payload && payload.data || [];
      state.isGetAccountDetailsByUniqueIdSuccess = true;
      return state;
    },
    [getAccountDetailsByUniqueId.rejected]: (state, { payload }) => {
      state.isGetAccountDetailsByUniqueIdFetching = false;
      state.isGetAccountDetailsByUniqueIdError = true;
      state.getAccountDetailsByUniqueIdErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [getAccountDetailsByUniqueId.pending]: (state) => {
      state.isGetAccountDetailsByUniqueIdFetching = true;
      return state;
    },
    [getAccountsList.fulfilled]: (state, { payload }) => {
      state.isGetAccountsListFetching = false;
      state.accountsList = payload;
      state.isGetAccountsListSuccess = true;
      return state;
    },
    [getAccountsList.rejected]: (state, { payload }) => {
      state.isGetAccountsListFetching = false;
      state.isGetAccountsListError = true;
      state.getAccountsListErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [getAccountsList.pending]: (state) => {
      state.isGetAccountsListFetching = true;
      return state;
    },
    [inviteAccount.fulfilled]: (state, { payload }) => {
      state.isInviteAccountFetching = false;
      if (payload.status == "error") {
        state.isInviteAccountError = true;
        state.inviteAccountErrorMessage = payload.error || "Please try again.";
      } else {
        state.isInviteAccountSuccess = true;
      }
      return state;
    },
    [inviteAccount.rejected]: (state, { payload }) => {
      state.isInviteAccountFetching = false;
      state.isInviteAccountError = true;
      state.inviteAccountErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [inviteAccount.pending]: (state) => {
      state.isInviteAccountFetching = true;
      return state;
    },
    [updateCustomerPortalSelection.fulfilled]: (state, { payload }) => {
      state.isUpdateCustomerPortalSelectionFetching = false;
      if (payload.status == "error") {
        state.isUpdateCustomerPortalSelectionError = true;
        state.updateCustomerPortalSelectionErrorMessage = payload.error;
      } else {
        state.isUpdateCustomerPortalSelectionSuccess = true;
        state.updateCustomerPortalSelectionData = payload.data;
      }
      return state;
    },
    [updateCustomerPortalSelection.rejected]: (state, { payload }) => {
      state.isUpdateCustomerPortalSelectionFetching = false;
      state.isUpdateCustomerPortalSelectionError = true;
      state.updateCustomerPortalSelectionErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [updateCustomerPortalSelection.pending]: (state) => {
      state.isUpdateCustomerPortalSelectionFetching = true;
      return state;
    },
    [getAccountSettings.fulfilled]: (state, { payload }) => {
      state.isGetAccountSettingsFetching = false;
      state.accountSettings = payload && payload.data || [];
      state.isGetAccountSettingsSuccess = true;
      return state;
    },
    [getAccountSettings.rejected]: (state, { payload }) => {
      state.isGetAccountSettingsFetching = false;
      state.isGetAccountSettingsError = true;
      state.getAccountsSettingsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [getAccountSettings.pending]: (state) => {
      state.isGetAccountSettingsFetching = true;
    },
    [addAccountSettings.fulfilled]: (state, { payload }) => {
      state.isAddAccountSettingsFetching = false;
      state.accountSettings = payload && payload.data || "";
      state.isAddAccountSettingsSuccess = true;
      return state;
    },
    [addAccountSettings.rejected]: (state, { payload }) => {
      state.isAddAccountSettingsFetching = false;
      state.isAddAccountSettingsError = true;
      state.addAccountSettingsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [addAccountSettings.pending]: (state) => {
      state.isAddAccountSettingsFetching = true;
      return state;
    },
  },
});

export const { clearGetAccountDetailsByUniqueIdState ,clearInviteAccountState,clearGetAccountsListState, clearUpdateCustomerPortalSelectionState, clearAddAccountSettingsState, clearGetAccountSettingsState } = accountsSlice.actions;
export const accountsSelector = (state) => state.accounts;

export default accountsSlice.reducer;
