import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const getJobsReport = createAsyncThunk(
  "jobs/report",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/getJobsReport", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuotesReport = createAsyncThunk(
  "quotes/report",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Quotes/getQuotesReport", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    isGetJobsReportFetching: false,
    isGetJobsReportSuccess: false,
    isGetJobsReportError: false,
    getJobsReportErrorMessage: "",
    isGetQuotesReportFetching: false,
    isGetQuotesReportSuccess: false,
    isGetQuotesReportError: false,
    getQuotesReportMessage: "",
    jobsReportData: [],
    quotesReportData: [],
  },
  reducers: {
    clearGetQuotesReportState: (state) => {
      state.isGetQuotesReportError = false;
      state.isGetQuotesReportSuccess = false;
      state.isGetQuotesReportFetching = false;
      state.getQuotesReportMessage = "";
      state.jobsReportData = [];
      return state;
    },
    clearGetJobsReportState: (state) => {
      state.isGetJobsReportError = false;
      state.isGetJobsReportSuccess = false;
      state.isGetJobsReportFetching = false;
      state.getJobsReportErrorMessage = "";
      state.jobsReportData = [];
      return state;
    },
  },
  extraReducers: {
    [getJobsReport.fulfilled]: (state, { payload }) => {
      state.isGetJobsReportFetching = false;
      if (payload.status == "error") {
        state.isGetJobsReportError = true;
        state.getJobsReportErrorMessage =
          payload.error || "Unable to get the Job Reports. Please try again.";
      } else {
        state.isGetJobsReportSuccess = true;
        state.jobsReportData = payload && payload.data;
      }
    },
    [getJobsReport.pending]: (state) => {
      state.isGetJobsReportFetching = true;
    },
    [getJobsReport.rejected]: (state, { payload }) => {
      state.isGetJobsReportFetching = false;
      state.isGetJobsReportError = true;
      state.getJobsReportErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getQuotesReport.fulfilled]: (state, { payload }) => {
      state.isGetQuotesReportFetching = false;
      if (payload.status == "error") {
        state.isGetQuotesReportError = true;
        state.getQuotesReportErrorMessage =
          payload.error || "Unable to add team member. Please try again.";
      } else {
        state.isGetQuotesReportSuccess = true;
        state.quotesReportData = payload && payload.data;
      }
    },
    [getQuotesReport.pending]: (state) => {
      state.isGetQuotesReportFetching = true;
    },
    [getQuotesReport.rejected]: (state, { payload }) => {
      state.isGetQuotesReportFetching = false;
      state.isGetQuotesReportError = true;
      state.getQuotesReportErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
  },
});

export const { clearGetJobsReportState, clearGetQuotesReportState } =
  reportsSlice.actions;

export const reportsSelector = (state) => state.reports;

export default reportsSlice.reducer;
