import {
  clearInvoiceStatusUpdateState,
  getInvoicesByJob,
  invoicesListByJobSelector,
  invoicesSelector,
  updateInvoiceStatus,
} from "app/store/invoicesSlice";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Button } from "@mui/material";
import history from '@history';


export default function InvoiceStatusUpdate() {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { jobUniqueId, invoiceUniqueId } = routeParams;
  const invoices = useSelector(invoicesListByJobSelector);
  const [invoiceFileType, setInvoiceFileType] = useState("");
  const {
    isInvoiceStatusUpdateFetching,
    isInvoiceStatusUpdateSuccess,
    isInvoiceStatusUpdateError,
    invoiceStatusUpdateErrorMessage,
  } = useSelector(invoicesSelector);

  useEffect(() => {
    dispatch(getInvoicesByJob({ jobUniqueId: jobUniqueId }));
    // dispatch(updateInvoiceStatus({ jobUniqueId, invoiceUniqueId }));
  }, [jobUniqueId, invoiceUniqueId]);

  const handlePaidInvoice = () => {
    dispatch(updateInvoiceStatus({ jobUniqueId, invoiceUniqueId }));
  }

  useEffect(() => {
    if (isInvoiceStatusUpdateSuccess) {
      dispatch(clearInvoiceStatusUpdateState());
      toast.success(
        "Invoice Status updated to Paid"
      );
      setTimeout(() => history.push('/sign-in'), 300);
    }
    if (isInvoiceStatusUpdateError) {
      dispatch(clearInvoiceStatusUpdateState());
      toast.error(invoiceStatusUpdateErrorMessage);
    }
  }, [isInvoiceStatusUpdateSuccess, isInvoiceStatusUpdateError]);

  useEffect(() => {
    if (
      invoices &&
      invoices.length > 0 &&
      invoices[0] &&
      invoices[0]["file_type"]
    ) {
      if (invoices[0]["file_type"] == "pdf") {
        setInvoiceFileType("pdf");
      } else if (
        invoices[0]["file_type"] === "jpg" ||
        invoices[0]["file_type"] === "jpeg" ||
        invoices[0]["file_type"] === "png" ||
        invoices[0]["file_type"] === "gif"
      ) {
        setInvoiceFileType("image");
      }
    }
  }, [invoices]);

  return (
    <div>
      <div className={invoiceFileType == 'image' ? "invoice-paid-status invoice-image-status" : (invoiceFileType == 'pdf' ? "invoice-paid-status invoice-pdf-status" : "invoice-paid-status")}>
        <div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32 flex-60">
          <div className="invoice-buttons-list">
            {invoices && invoices.length > 0 && (
              <>
                {invoices[0] &&
                  invoices[0]["file_type"] &&
                  invoices[0]["file_type"] == "pdf" && (
                    <iframe src={invoices[0].uri} height={600} width="100%" />
                  )}
                {invoices[0] &&
                  invoices[0]["file_type"] &&
                  (invoices[0]["file_type"] === "jpg" ||
                    invoices[0]["file_type"] === "jpeg" ||
                    invoices[0]["file_type"] === "png" ||
                    invoices[0]["file_type"] === "gif") && (
                    // <img src={invoices[0].uri} className="invoice-image" />
                    <Zoom>
                      <img
                        alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                        src={invoices[0].uri}
                        // width="500"
                      />
                    </Zoom>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="invoice-paid-status-button">
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={() => handlePaidInvoice()}
          className="invoice-paid-button"
        >
          CLICK TO CONFIRM INVOICE IS PAID
        </Button>
      </div>
    </div>
  );
}
