import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createJobCustomField = createAsyncThunk(
    'job/createJobCustomField',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'JobCustomFields/createJobCustomField', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getJobCustomFieldsByAccount = createAsyncThunk(
    'job/getJobCustomFieldsByAccount',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'JobCustomFields/getJobCustomFieldsByAccount';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteJobCustomField = createAsyncThunk(
    'job/deleteJobCustomField',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'JobCustomFields/deleteJobCustomField', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateJobCustomFieldStatus = createAsyncThunk(
    'job/updateJobCustomField',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'JobCustomFields/updateJobCustomFieldStatus', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getAllJobCustomFields = createAsyncThunk(
    'job/getAllJobCustomFields',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'JobCustomFields/getAllJobCustomFields';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const jobCustomFieldsSlice = createSlice({
    name: 'jobCustomFields',
    initialState: {
        isGetJobCustomFieldsFetching: false,
        isGetJobCustomFieldsSuccess: false,
        isGetJobCustomFieldsError: false,
        getJobCustomFieldsErrorMessage: '',

        isGetAllJobCustomFieldsFetching: false,
        isGetAllJobCustomFieldsSuccess: false,
        isGetAllJobCustomFieldsError: false,
        getAllJobCustomFieldsErrorMessage: '',

        isCreateJobCustomFieldFetching: false,
        isCreateJobCustomFieldSuccess: false,
        isCreateJobCustomFieldError: false,
        createJobCustomFieldErrorMessage: '',
        jobCustomFields: [],
        totalJobCustomFields: [],

        isDeleteJobCustomFieldFetching: false,
        isDeleteJobCustomFieldSuccess: false,
        isDeleteJobCustomFieldError: false,
        deleteJobCustomFieldErrorMessage: '',

        isUpdateJobCustomFieldFetching: false,
        isUpdateJobCustomFieldSuccess: false,
        isUpdateJobCustomFieldError: false,
        updateJobCustomFieldErrorMessage: '',

    },
    reducers: {
        clearCreateJobCustomFieldState: (state) => {
            state.isCreateJobCustomFieldError = false;
            state.isCreateJobCustomFieldSuccess = false;
            state.isCreateJobCustomFieldFetching = false;
            state.createJobCustomFieldErrorMessage = "";
            return state;
        },

        clearDeleteJobCustomFieldState: (state) => {
            state.isDeleteJobCustomFieldError = false;
            state.isDeleteJobCustomFieldSuccess = false;
            state.isDeleteJobCustomFieldFetching = false;
            state.deleteJobCustomFieldErrorMessage = "";
            return state;
        },
        clearUpdateJobCustomFieldState: (state) => {
            state.isUpdateJobCustomFieldError = false;
            state.isUpdateJobCustomFieldSuccess = false;
            state.isUpdateJobCustomFieldFetching = false;
            state.updateJobCustomFieldErrorMessage = "";
            return state;
        },
        clearGetJobCustomFieldsState: (state) => {
            state.isGetJobCustomFieldsError = false;
            state.isGetJobCustomFieldsSuccess = false;
            state.isGetJobCustomFieldsFetching = false;
            state.getJobCustomFieldsErrorMessage = "";
            return state;
        },
        clearGetAllJobCustomFieldsState: (state) => {
            state.isGetAllJobCustomFieldsError = false;
            state.isGetAllJobCustomFieldsSuccess = false;
            state.isGetAllJobCustomFieldsFetching = false;
            state.getAllJobCustomFieldsErrorMessage = "";
            return state;
        },
        clearGetJobCustomFieldsFullState: (state) => {
            state.isGetJobCustomFieldsError = false;
            state.isGetJobCustomFieldsSuccess = false;
            state.isGetJobCustomFieldsFetching = false;
            state.getJobCustomFieldsErrorMessage = "";
            state.jobCustomFields = [];
            return state;
        },
    },
    extraReducers: {
        [createJobCustomField.fulfilled]: (state, { payload }) => {
            state.isCreateJobCustomFieldFetching = false;
            state.isCreateJobCustomFieldSuccess = true;
        },
        [createJobCustomField.pending]: (state) => {
            state.isCreateJobCustomFieldFetching = true;
        },
        [createJobCustomField.rejected]: (state, { payload }) => {
            state.isCreateJobCustomFieldFetching = false;
            state.isCreateJobCustomFieldError = true;
            state.createJobCustomFieldErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [deleteJobCustomField.fulfilled]: (state, { payload }) => {
            state.isDeleteJobCustomFieldFetching = false;
            state.isDeleteJobCustomFieldSuccess = true;
        },
        [deleteJobCustomField.pending]: (state) => {
            state.isDeleteJobCustomFieldFetching = true;
        },
        [deleteJobCustomField.rejected]: (state, { payload }) => {
            state.isDeleteJobCustomFieldFetching = false;
            state.isDeleteJobCustomFieldError = true;
            state.deleteJobCustomFieldErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateJobCustomFieldStatus.fulfilled]: (state, { payload }) => {
            state.isUpdateJobCustomFieldFetching = false;
            state.isUpdateJobCustomFieldSuccess = true;
        },
        [updateJobCustomFieldStatus.pending]: (state) => {
            state.isUpdateJobCustomFieldFetching = true;
        },
        [updateJobCustomFieldStatus.rejected]: (state, { payload }) => {
            state.isUpdateJobCustomFieldFetching = false;
            state.isUpdateJobCustomFieldError = true;
            state.updateJobCustomFieldErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getJobCustomFieldsByAccount.fulfilled]: (state, { payload }) => {
            state.isGetJobCustomFieldsFetching = false;
            state.jobCustomFields = payload && payload.data || [];
            state.isGetJobCustomFieldsSuccess = true;
            return state;
        },
        [getJobCustomFieldsByAccount.rejected]: (state, { payload }) => {
            state.isGetJobCustomFieldsFetching = false;
            state.isGetJobCustomFieldsError = true;
            state.getJobCustomFieldsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getJobCustomFieldsByAccount.pending]: (state) => {
            state.isGetJobCustomFieldsFetching = true;
        },
        [getAllJobCustomFields.fulfilled]: (state, { payload }) => {
            state.isGetAllJobCustomFieldsFetching = false;
            state.totalJobCustomFields = payload && payload.data || [];
            state.isGetAllJobCustomFieldsSuccess = true;
            return state;
        },
        [getAllJobCustomFields.rejected]: (state, { payload }) => {
            state.isGetAllJobCustomFieldsFetching = false;
            state.isGetAllJobCustomFieldsError = true;
            state.getAllJobCustomFieldsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getAllJobCustomFields.pending]: (state) => {
            state.isGetJobCustomFieldsFetching = true;
        },
    }
});

export const { clearGetAllJobCustomFieldsState ,clearCreateJobCustomFieldState, clearUpdateJobCustomFieldState, clearGetJobCustomFieldsState, clearDeleteJobCustomFieldState, clearGetJobCustomFieldsFullState } = jobCustomFieldsSlice.actions;

export const jobCustomFieldsSelector = (state) => state.jobCustomFields;

export default jobCustomFieldsSlice.reducer;