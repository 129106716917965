import FuseLoading from "@fuse/core/FuseLoading";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useThemeMediaQuery } from "@fuse/hooks";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import {
  getCustomerPortalDetails,
  customerPortalSelector,
  clearGetCustomerPortalDetailsState,
  updateInstallationDate,
  clearUpdateInstallationDateState,
} from "app/store/customerPortalSlice";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { motion } from "framer-motion";
import ProductImageViewer from "../product-catalogs/ProductImageViewer";
import ProductOrderImageViewer from "./ProductOrderImageViewer";

function CustomerPortalView(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useThemeMediaQuery((_theme) =>
    _theme.breakpoints.down("lg")
  );
  const routeParams = useParams();
  const [validPage, setValidPage] = useState(false);
  const { visibilityId } = routeParams;
  const [loading, setLoading] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [showInstallationDatesOnly, setShowInstallationDatesOnly] =
    useState(false);
  const [selectedInstallationDate, setSelectedInstallationDate] = useState("");
  const [productImageViewerOpen, setProductImageViewerOpen] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [showInstallationDate, setShowInstallationDate] = useState(false);
  const [showShingleColorSelection, setShowShingleColorSelection] =
    useState(true);
  const [installationDates, setInstallationDates] = useState([]);
  const [installationSelectedIndex, setInstallationSelectedIndex] =
    useState("");

  const {
    customerPortalDetails,
    customerPortalDetailsErrorMessage,
    isCustomerPortalDetailsError,
    isCustomerPortalDetailsSuccess,
    isCustomerPortalDetailsFetching,
    isUpdateInstallationDateError,
    isUpdateInstallationDateSuccess,
  } = useSelector(customerPortalSelector);

  useEffect(() => {
    if (visibilityId) {
      dispatch(getCustomerPortalDetails({ visibilityId: visibilityId }));
    }
  }, [visibilityId]);

  useEffect(() => {
    if (customerPortalDetails) {
      if (customerPortalDetails && customerPortalDetails.products) {
        setProductsList(customerPortalDetails.products);
      }
      let scheduleDateAvailable = false;
      let installationDatesAvailable = false;
      if (
        customerPortalDetails &&
        customerPortalDetails.job_details &&
        customerPortalDetails.job_details.schedule_date &&
        customerPortalDetails.job_details.schedule_date != "0000-00-00" &&
        customerPortalDetails.job_details.schedule_date != null
      ) {
        scheduleDateAvailable = true;
        setSelectedInstallationDate(
          moment(customerPortalDetails.job_details.schedule_date).format(
            "dddd, MMMM Do"
          )
        );
      }
      if (customerPortalDetails && customerPortalDetails.installation_dates) {
        let installDates = [];
        customerPortalDetails.installation_dates.forEach((eachDate) => {
          installationDatesAvailable = true;
          if (eachDate && eachDate.installation_date) {
            installDates.push(
              moment(eachDate.installation_date).format("dddd, MMMM Do")
            );
          }
          if (eachDate && eachDate.status === "2") {
            scheduleDateAvailable = true;
            setSelectedInstallationDate(
              moment(eachDate.installation_date).format("dddd, MMMM Do")
            );
          }
        });
        setInstallationDates(installDates);
      }
      if (!scheduleDateAvailable && installationDatesAvailable) {
        setShowInstallationDate(true);
        if (
          customerPortalDetails &&
          customerPortalDetails.products &&
          customerPortalDetails.products.length == 0
        ) {
          setShowInstallationDatesOnly(true);
        }
      } else if (scheduleDateAvailable) {
        if (
          customerPortalDetails &&
          customerPortalDetails.products &&
          customerPortalDetails.products.length == 0
        ) {
          setShowOrderConfirmation(true);
        }
      }
      if (
        customerPortalDetails &&
        customerPortalDetails.job_details &&
        customerPortalDetails.job_details.job_product_order_status == "1"
      ) {
        setShowShingleColorSelection(false);
        setSelectedTab("2");
      }
    }
  }, [customerPortalDetails]);

  const handleImagePreview = (product) => {
    setImagesData(product.images);
    setProductImageViewerOpen(true);
    setProductData(product);
  };

  function handleProductImageViewerClose() {
    setProductImageViewerOpen(false);
  }

  function handleSelectInstallationDates() {
    dispatch(
      updateInstallationDate({
        date: customerPortalDetails.installation_dates[
          installationSelectedIndex
        ].installation_date,
        jobId: customerPortalDetails.job_details.id,
        installationDateId:
          customerPortalDetails.installation_dates[installationSelectedIndex]
            .id,
      })
    );
  }

  function handleDateSelection(index) {
    setInstallationSelectedIndex(index);
  }

  useEffect(() => {
    if (isCustomerPortalDetailsSuccess) {
      setValidPage(true);
      setLoading(false);
    }
    if (isCustomerPortalDetailsError) {
      setValidPage(false);
      setLoading(false);
    }
  }, [isCustomerPortalDetailsSuccess, isCustomerPortalDetailsError]);

  useEffect(() => {
    if (isUpdateInstallationDateError) {
      toast.error("Unable update the Installation Date");
      dispatch(clearUpdateInstallationDateState());
    }
    if (isUpdateInstallationDateSuccess) {
      toast.success("Succesfully updated Installation Date");
      dispatch(clearUpdateInstallationDateState());
      location.reload();
    }
  }, [isUpdateInstallationDateError, isUpdateInstallationDateSuccess]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    );
  }

  if (!validPage) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.1 } }}
        className="flex flex-col flex-1 items-center justify-center h-full"
      >
        <Typography color="text.secondary" variant="h5">
          There is no such Product!
        </Typography>
      </motion.div>
    );
  }

  return (
    <>
      <ProductOrderImageViewer
        open={productImageViewerOpen}
        handleProductImageViewerClose={handleProductImageViewerClose}
        imagesData={imagesData}
        productData={productData}
        jobId={
          (customerPortalDetails &&
            customerPortalDetails.job_details &&
            customerPortalDetails.job_details.id) ||
          ""
        }
      />
      <div className="customer-portal-view">
        <div className="customer-portal-view-web">
          {((customerPortalDetails &&
            customerPortalDetails.job_details &&
            customerPortalDetails.job_details.job_product_order_status &&
            customerPortalDetails.job_details.job_product_order_status ==
              "0") ||
            showInstallationDate) && !showOrderConfirmation && (
            <>
              {isMobile ? (
                <>
                  <div className="customer-portal-view-left-panel-mobile">
                    <div className="mb-24 mt-14">
                      <div className="w-full">
                        <div className="md:flex">
                          <div className="flex flex-1 md:ltr:pr-32 md:rtl:pl-32 customer-portal-header-content-mobile">
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:user
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Customer:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .customer_name) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:location-marker
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Customer Address:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details max-width-200"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .address) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:cog
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Installer:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .assigned_partner) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:color-swatch
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Shingle Color:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.selected_product &&
                                      customerPortalDetails.selected_product
                                        .product_name) ||
                                      "Not Defined Yet"}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:calendar
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Installation Date:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {selectedInstallationDate &&
                                    !showInstallationDate
                                      ? selectedInstallationDate
                                      : "Not Defined Yet"}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="customer-portal-view-right-panel-mobile">
                    <div className="customer-portal-view-right-mobile">
                      <div className="customer-portal-view-right-header-mobile">
                        <div
                          className={
                            selectedTab == "1"
                              ? !showInstallationDate
                                ? "customer-portal-view-right-heading-title-mobile active float-left customer-portal-view-right-heading-title-mobile-single"
                                : "customer-portal-view-right-heading-title-mobile active float-left"
                              : "customer-portal-view-right-heading-title-mobile float-left"
                          }
                        >
                          <div className="customer-portal-view-right-number-mobile">
                            1
                          </div>
                          {showShingleColorSelection && (
                            <div
                              className="customer-portal-view-right-heading-text-mobile"
                              onClick={() => setSelectedTab("1")}
                            >
                              Roof Shingle Color
                            </div>
                          )}
                          {!showShingleColorSelection && (
                            <div
                              className="customer-portal-view-right-heading-text-mobile"
                              // onClick={() => setSelectedTab("1")}
                            >
                              Roof Shingle Color
                            </div>
                          )}
                        </div>
                        {showInstallationDate && (
                          <div
                            className={
                              selectedTab == "2"
                                ? "customer-portal-view-right-heading-title-mobile active float-right"
                                : "customer-portal-view-right-heading-title-mobile float-right"
                            }
                          >
                            <div className="customer-portal-view-right-number-mobile">
                              2
                            </div>
                            <div
                              className="customer-portal-view-right-heading-text-mobile"
                              onClick={() => setSelectedTab("2")}
                            >
                              Select Installation Date
                            </div>
                          </div>
                        )}
                        <div className="clear"></div>
                      </div>
                      {selectedTab == "1" && (
                        <div className="customer-portal-view-right-body-mobile">
                          <div className="customer-portal-view-right-heading">
                            Choose Shingle Color
                          </div>
                          <div className="customer-portal-view-right-products">
                            {productsList.map((product, index) => {
                              return (
                                <div className="customer-portal-view-right-product-mobile">
                                  <div className="customer-portal-view-right-product-image">
                                    <img
                                      src={product.images[0].image_url}
                                      onClick={(e) =>
                                        handleImagePreview(product)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="customer-portal-view-right-product-label"
                                    onClick={(e) => handleImagePreview(product)}
                                  >
                                    {product.product_name}
                                  </div>
                                </div>
                              );
                            })}

                            <div className="clear"></div>
                          </div>
                        </div>
                      )}
                      {showInstallationDate && selectedTab == "2" && (
                        <div className="customer-portal-view-right-body-mobile">
                          <div className="customer-portal-view-right-heading">
                            Select Installation Date
                          </div>
                          <div className="customer-portal-view-right-sub-heading">
                            Choose time you prefer to start installation
                          </div>
                          <div className="customer-portal-view-dates-list-mobile">
                            {installationDates &&
                              installationDates.map(
                                (installation_date, index) => {
                                  return (
                                    <div
                                      className={
                                        installationSelectedIndex === index
                                          ? "customer-portal-view-each-date active"
                                          : "customer-portal-view-each-date"
                                      }
                                      key={index}
                                      onClick={(e) =>
                                        handleDateSelection(index)
                                      }
                                    >
                                      {installation_date}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <div className="customer-portal-view-date-selection-button-mobile">
                            <Button
                              onClick={() => handleSelectInstallationDates()}
                              size="medium"
                              className="customer-engagement-portal-date-mobile"
                              disabled={
                                installationSelectedIndex ||
                                installationSelectedIndex === 0
                                  ? false
                                  : true
                              }
                            >
                              Confirm Installation Date
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="clear"></div>
                </>
              ) : (
                <>
                  <div className="customer-portal-view-left-panel float-left">
                    <div className="mb-24 mt-14">
                      <div className="w-full">
                        <div className="md:flex">
                          <div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:user
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Customer:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .customer_name) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:location-marker
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Customer Address:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .address) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:cog
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Installer:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.job_details &&
                                      customerPortalDetails.job_details
                                        .assigned_partner) ||
                                      ""}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:color-swatch
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Shingle Color:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {(customerPortalDetails &&
                                      customerPortalDetails.selected_product &&
                                      customerPortalDetails.selected_product
                                        .product_name) ||
                                      "Not Defined Yet"}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="pb-12 items-center">
                              <div className="float-left flex">
                                <div className="float-left">
                                  <FuseSvgIcon size={18} color="action">
                                    heroicons-outline:calendar
                                  </FuseSvgIcon>
                                </div>
                                <div className="float-left">
                                  <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                    Installation Date:
                                  </Typography>
                                  <Typography
                                    className="mx-12 font-medium job-detail-details"
                                    color="text.secondary"
                                  >
                                    {selectedInstallationDate &&
                                    !showInstallationDate
                                      ? selectedInstallationDate
                                      : "Not Defined Yet"}
                                  </Typography>
                                </div>
                                <div className="clear"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showInstallationDatesOnly ? (
                    <div className="customer-portal-view-right-panel float-left height-100-vh">
                      <div className="customer-portal-view-right">
                        <div>
                          <div className="customer-portal-view-right-header">
                            <div className="active float-left customer-portal-view-right-heading-title">
                              <div className="customer-portal-view-right-number">
                                01
                              </div>
                              <div className="customer-portal-view-right-heading-text">
                                Select Installation Date
                              </div>
                            </div>
                            <div className="clear"></div>
                          </div>
                          <div className="customer-portal-view-right-body">
                            <div className="customer-portal-view-dates-selection">
                              <div className="customer-portal-view-right-heading">
                                Select Installation Date
                              </div>
                              <div className="customer-portal-view-right-sub-heading">
                                Choose time you prefer to start Installation
                              </div>
                              <div className="customer-portal-view-dates-list">
                                {installationDates &&
                                  installationDates.map(
                                    (installation_date, index) => {
                                      return (
                                        <div
                                          className={
                                            installationSelectedIndex === index
                                              ? "customer-portal-view-each-date active"
                                              : "customer-portal-view-each-date"
                                          }
                                          key={index}
                                          onClick={(e) =>
                                            handleDateSelection(index)
                                          }
                                        >
                                          {installation_date}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                              <div className="customer-portal-view-date-selection-button">
                                <Button
                                  onClick={() =>
                                    handleSelectInstallationDates()
                                  }
                                  size="medium"
                                  className="customer-engagement-portal-date"
                                  disabled={
                                    installationSelectedIndex ||
                                    installationSelectedIndex === 0
                                      ? false
                                      : true
                                  }
                                >
                                  Confirm Installation Date
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        selectedTab == "1"
                          ? "customer-portal-view-right-panel float-left height-100-vh"
                          : "customer-portal-view-right-panel float-left"
                      }
                    >
                      <div className="customer-portal-view-right">
                        <div>
                          <div className="customer-portal-view-right-header">
                            <div
                              className={
                                selectedTab == "1"
                                  ? "active float-left customer-portal-view-right-heading-title"
                                  : "float-left customer-portal-view-right-heading-title"
                              }
                            >
                              <div className="customer-portal-view-right-number">
                                01
                              </div>
                              {showShingleColorSelection && (
                                <div
                                  className="customer-portal-view-right-heading-text"
                                  onClick={() => setSelectedTab("1")}
                                >
                                  Roof Shingle Color
                                </div>
                              )}
                              {!showShingleColorSelection && (
                                <div
                                  className="customer-portal-view-right-heading-text"
                                  // onClick={() => setSelectedTab("1")}
                                >
                                  Roof Shingle Color
                                </div>
                              )}
                            </div>
                            {showInstallationDate && (
                              <div
                                className={
                                  selectedTab == "2"
                                    ? "float-right customer-portal-view-right-heading-title active mr-100"
                                    : "float-right customer-portal-view-right-heading-title mr-100"
                                }
                              >
                                <div className="customer-portal-view-right-number">
                                  02
                                </div>
                                <div
                                  className="customer-portal-view-right-heading-text"
                                  onClick={() => setSelectedTab("2")}
                                >
                                  Select Installation Date
                                </div>
                              </div>
                            )}
                            <div className="clear"></div>
                          </div>
                          <div className="customer-portal-view-right-body">
                            {selectedTab == "1" ? (
                              <div>
                                <div className="customer-portal-view-right-heading">
                                  Choose Shingle Color
                                </div>
                                <div className="customer-portal-view-right-products">
                                  {productsList.map((product, index) => {
                                    return (
                                      <div className="customer-portal-view-right-product">
                                        <div className="customer-portal-view-right-product-image">
                                          <img
                                            src={product.images[0].image_url}
                                            onClick={(e) =>
                                              handleImagePreview(product)
                                            }
                                          />
                                        </div>
                                        <div
                                          className="customer-portal-view-right-product-label"
                                          onClick={(e) =>
                                            handleImagePreview(product)
                                          }
                                        >
                                          {product.product_name}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="clear"></div>
                                </div>
                              </div>
                            ) : (
                              showInstallationDate && (
                                <div className="customer-portal-view-dates-selection">
                                  <div className="customer-portal-view-right-heading">
                                    Select Installation Date
                                  </div>
                                  <div className="customer-portal-view-right-sub-heading">
                                    Choose time you prefer to start Installation
                                  </div>
                                  <div className="customer-portal-view-dates-list">
                                    {installationDates &&
                                      installationDates.map(
                                        (installation_date, index) => {
                                          return (
                                            <div
                                              className={
                                                installationSelectedIndex ===
                                                index
                                                  ? "customer-portal-view-each-date active"
                                                  : "customer-portal-view-each-date"
                                              }
                                              key={index}
                                              onClick={(e) =>
                                                handleDateSelection(index)
                                              }
                                            >
                                              {installation_date}
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  <div className="customer-portal-view-date-selection-button">
                                    <Button
                                      onClick={() =>
                                        handleSelectInstallationDates()
                                      }
                                      size="medium"
                                      className="customer-engagement-portal-date"
                                      disabled={
                                        installationSelectedIndex ||
                                        installationSelectedIndex === 0
                                          ? false
                                          : true
                                      }
                                    >
                                      Confirm Installation Date
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="clear"></div>
                </>
              )}
            </>
          )}
          {((customerPortalDetails &&
            customerPortalDetails.job_details &&
            customerPortalDetails.job_details.job_product_order_status &&
            customerPortalDetails.job_details.job_product_order_status == "1" &&
            !showInstallationDate) || (showOrderConfirmation)) && (
              <>
                <div
                  className={
                    isMobile
                      ? "mobile-customer-portal-order-complete"
                      : "web-customer-portal-order-complete"
                  }
                >
                  <div className="customer-portal-order-heading">
                    <div className="customer-portal-view-right-heading">
                      Order Completed
                    </div>
                    <div className="customer-portal-view-order-complete-sub-heading">
                      Review your Order details
                    </div>
                  </div>
                  <div className="mb-24 mt-14">
                    <div className="w-full">
                      <div className="md:flex">
                        <div className="flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
                          <div className="pb-12 items-center">
                            <div className="float-left flex">
                              <div className="float-left">
                                <FuseSvgIcon size={18} color="action">
                                  heroicons-outline:user
                                </FuseSvgIcon>
                              </div>
                              <div className="float-left">
                                <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                  Customer:
                                </Typography>
                                <Typography
                                  className="mx-12 font-medium job-detail-details"
                                  color="text.secondary"
                                >
                                  {(customerPortalDetails &&
                                    customerPortalDetails.job_details &&
                                    customerPortalDetails.job_details
                                      .customer_name) ||
                                    ""}
                                </Typography>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                          <div className="pb-12 items-center">
                            <div className="float-left flex">
                              <div className="float-left">
                                <FuseSvgIcon size={18} color="action">
                                  heroicons-outline:location-marker
                                </FuseSvgIcon>
                              </div>
                              <div className="float-left">
                                <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                  Customer Address:
                                </Typography>
                                <Typography
                                  className="mx-12 font-medium job-detail-details"
                                  color="text.secondary"
                                >
                                  {(customerPortalDetails &&
                                    customerPortalDetails.job_details &&
                                    customerPortalDetails.job_details
                                      .address) ||
                                    ""}
                                </Typography>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                          <div className="pb-12 items-center">
                            <div className="float-left flex">
                              <div className="float-left">
                                <FuseSvgIcon size={18} color="action">
                                  heroicons-outline:cog
                                </FuseSvgIcon>
                              </div>
                              <div className="float-left">
                                <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                  Installer:
                                </Typography>
                                <Typography
                                  className="mx-12 font-medium job-detail-details"
                                  color="text.secondary"
                                >
                                  {(customerPortalDetails &&
                                    customerPortalDetails.job_details &&
                                    customerPortalDetails.job_details
                                      .assigned_partner) ||
                                    ""}
                                </Typography>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                          <div className="pb-12 items-center">
                            <div className="float-left flex">
                              <div className="float-left">
                                <FuseSvgIcon size={18} color="action">
                                  heroicons-outline:color-swatch
                                </FuseSvgIcon>
                              </div>
                              <div className="float-left">
                                <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                  Shingle Color:
                                </Typography>
                                <Typography
                                  className="mx-12 font-medium job-detail-details"
                                  color="text.secondary"
                                >
                                  {(customerPortalDetails &&
                                    customerPortalDetails.selected_product &&
                                    customerPortalDetails.selected_product
                                      .product_name) ||
                                    ""}
                                </Typography>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                          <div className="pb-12 items-center">
                            <div className="float-left flex">
                              <div className="float-left">
                                <FuseSvgIcon size={18} color="action">
                                  heroicons-outline:calendar
                                </FuseSvgIcon>
                              </div>
                              <div className="float-left">
                                <Typography className="mx-12 font-medium job-detail-details customer-portal-view-left-label">
                                  Installation Date:
                                </Typography>
                                <Typography
                                  className="mx-12 font-medium job-detail-details"
                                  color="text.secondary"
                                >
                                  {selectedInstallationDate &&
                                  !showInstallationDate
                                    ? selectedInstallationDate
                                    : ""}
                                </Typography>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="customer-portal-view-order-message">
                    In case you need to change your order details. Please
                    contact installer: +123 456 789
                  </div>
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
}

export default CustomerPortalView;
