import { lazy } from 'react';
import UserLoginSession from './UserLoginSession';

const UsersList = lazy(() => import('./UsersList'));

const UsersListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'users',
      element: <UsersList />,
    },
    {
      path: 'user/login/:sessionId',
      element: <UserLoginSession />,
    },
  ],
};

export default UsersListConfig;
