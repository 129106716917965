import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const getUserData = createAsyncThunk(
  "user/getUserData",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/getUserData", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/updateProfileData", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProfilePassword = createAsyncThunk(
  "user/updateProfilePassword",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/updateProfilePassword", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProfilePhoto = createAsyncThunk(
  "user/updateProfilePhoto",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/uploadPhoto", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    isGetUserDataFetching: false,
    isGetUserDataSuccess: false,
    isGetUserDataError: false,
    getUserDataErrorMessage: "",
    userData: [],
    isUpdateProfileFetching: false,
    isUpdateProfileSuccess: false,
    isUpdateProfileError: false,
    updateProfileErrorMessage: "",
    isUpdateProfilePhotoFetching: false,
    isUpdateProfilePhotoSuccess: false,
    isUpdateProfilePhotoError: false,
    updateProfilePhotoErrorMessage: "",
    isUpdateProfilePasswordFetching: false,
    isUpdateProfilePasswordSuccess: false,
    isUpdateProfilePasswordError: false,
    updateProfilePasswordErrorMessage: "",
  },
  reducers: {
    clearGetUserDataState: (state) => {
      state.isGetUserDataError = false;
      state.isGetUserDataFetching = false;
      state.isGetUserDataSuccess = false;
      state.getUserDataErrorMessage = "";
      state.userData = [];
      return state;
    },
    clearUpdateProfilePasswordState: (state) => {
      state.isUpdateProfilePasswordError = false;
      state.isUpdateProfilePasswordFetching = false;
      state.isUpdateProfilePasswordSuccess = false;
      state.updateProfilePasswordErrorMessage = "";
      return state;
    },
    clearUpdateProfilePhotoState: (state) => {
      state.isUpdateProfilePhotoError = false;
      state.isUpdateProfilePhotoFetching = false;
      state.isUpdateProfilePhotoSuccess = false;
      state.updateProfilePhotoErrorMessage = "";
      return state;
    },
    clearUpdateProfileState: (state) => {
      state.isUpdateProfileError = false;
      state.isUpdateProfileFetching = false;
      state.isUpdateProfileSuccess = false;
      state.updateProfileErrorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [getUserData.fulfilled]: (state, { payload }) => {
      state.isGetUserDataFetching = false;
      if (payload.status && payload.status == "error") {
        state.getUserDataErrorMessage = payload.error;
      } else if (payload.status && payload.status == "success") {
        state.isGetUserDataSuccess = true;
        state.userData = payload.data;
      }
      return state;
    },
    [getUserData.rejected]: (state, { payload }) => {
      state.isGetUserDataFetching = false;
      state.isGetUserDataError = true;
      state.getUserDataErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getUserData.pending]: (state) => {
      state.isGetUserDataFetching = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.isUpdateProfileFetching = false;
      if (payload.status && payload.status == "error") {
        state.isUpdateProfileError = true;
        state.updateProfileErrorMessage = payload.error;
      } else if (payload.status && payload.status == "success") {
        state.isUpdateProfileSuccess = true;
      }
      return state;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.isUpdateProfileFetching = false;
      state.isUpdateProfileError = true;
      state.updateProfileErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateProfile.pending]: (state) => {
      state.isUpdateProfileFetching = true;
    },
    [updateProfilePhoto.fulfilled]: (state, { payload }) => {
      state.isUpdateProfilePhotoFetching = false;
      if (payload.status && payload.status == "error") {
        state.isUpdateProfilePhotoError = true;
        state.updateProfilePhotoErrorMessage = payload.error;
      } else if (payload.status && payload.status == "success") {
        state.isUpdateProfilePhotoSuccess = true;
      }
      return state;
    },
    [updateProfilePhoto.rejected]: (state, { payload }) => {
      state.isUpdateProfilePhotoFetching = false;
      state.isUpdateProfilePhotoError = true;
      state.updateProfilePhotoErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateProfilePhoto.pending]: (state) => {
      state.isUpdateProfilePhotoFetching = true;
    },
    [updateProfilePassword.fulfilled]: (state, { payload }) => {
      state.isUpdateProfilePasswordFetching = false;
      if (payload.status && payload.status == "error") {
        state.isUpdateProfilePasswordError = true;
        state.updateProfilePasswordErrorMessage = payload.error;
      } else if (payload.status && payload.status == "success") {
        state.isUpdateProfilePasswordSuccess = true;
      }
      return state;
    },
    [updateProfilePassword.rejected]: (state, { payload }) => {
      state.isUpdateProfilePasswordFetching = false;
      state.isUpdateProfilePasswordError = true;
      state.updateProfilePasswordErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateProfilePassword.pending]: (state) => {
      state.isUpdateProfilePasswordFetching = true;
    },
  },
});

export const {
  clearGetUserDataState,
  clearUpdateProfilePhotoState,
  clearUpdateProfilePasswordState,
  clearUpdateProfileState,
} = profileSlice.actions;

export const profileSelector = (state) => state.profile;
export default profileSlice.reducer;
