function TermsAndConditions() {
    const myHTML = `<html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40">
    
    <head>
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
    <meta name=ProgId content=Word.Document>
    <meta name=Generator content="Microsoft Word 15">
    <meta name=Originator content="Microsoft Word 15">
    <link rel=File-List href="SubC%20T&amp;C_files/filelist.xml">
    <!--[if gte mso 9]><xml>
     <o:DocumentProperties>
      <o:Author>Manikanta Suryadevara</o:Author>
      <o:LastAuthor>Manikanta Suryadevara</o:LastAuthor>
      <o:Revision>2</o:Revision>
      <o:TotalTime>0</o:TotalTime>
      <o:Created>2023-06-15T17:14:00Z</o:Created>
      <o:LastSaved>2023-06-15T17:14:00Z</o:LastSaved>
      <o:Pages>2</o:Pages>
      <o:Words>533</o:Words>
      <o:Characters>3043</o:Characters>
      <o:Lines>25</o:Lines>
      <o:Paragraphs>7</o:Paragraphs>
      <o:CharactersWithSpaces>3569</o:CharactersWithSpaces>
      <o:Version>16.00</o:Version>
     </o:DocumentProperties>
    </xml><![endif]-->
    <link rel=themeData href="SubC%20T&amp;C_files/themedata.thmx">
    <link rel=colorSchemeMapping href="SubC%20T&amp;C_files/colorschememapping.xml">
    <!--[if gte mso 9]><xml>
     <w:WordDocument>
      <w:TrackMoves>false</w:TrackMoves>
      <w:TrackFormatting/>
      <w:PunctuationKerning/>
      <w:ValidateAgainstSchemas/>
      <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
      <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
      <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
      <w:DoNotPromoteQF/>
      <w:LidThemeOther>EN-IN</w:LidThemeOther>
      <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
      <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
      <w:Compatibility>
       <w:BreakWrappedTables/>
       <w:SnapToGridInCell/>
       <w:WrapTextWithPunct/>
       <w:UseAsianBreakRules/>
       <w:DontGrowAutofit/>
       <w:SplitPgBreakAndParaMark/>
       <w:EnableOpenTypeKerning/>
       <w:DontFlipMirrorIndents/>
       <w:OverrideTableStyleHps/>
      </w:Compatibility>
      <w:DoNotOptimizeForBrowser/>
      <m:mathPr>
       <m:mathFont m:val="Cambria Math"/>
       <m:brkBin m:val="before"/>
       <m:brkBinSub m:val="&#45;-"/>
       <m:smallFrac m:val="off"/>
       <m:dispDef/>
       <m:lMargin m:val="0"/>
       <m:rMargin m:val="0"/>
       <m:defJc m:val="centerGroup"/>
       <m:wrapIndent m:val="1440"/>
       <m:intLim m:val="subSup"/>
       <m:naryLim m:val="undOvr"/>
      </m:mathPr></w:WordDocument>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
      DefSemiHidden="false" DefQFormat="false" DefPriority="99"
      LatentStyleCount="376">
      <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index 9"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 1"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 2"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 3"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 4"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 5"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 6"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 7"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 8"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" Name="toc 9"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footnote text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="header"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footer"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="index heading"/>
      <w:LsdException Locked="false" Priority="35" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="caption"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="table of figures"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="envelope address"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="envelope return"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="footnote reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="line number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="page number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="endnote reference"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="endnote text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="table of authorities"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="macro"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="toa heading"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Bullet 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Number 5"/>
      <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Closing"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Signature"/>
      <w:LsdException Locked="false" Priority="1" SemiHidden="true"
       UnhideWhenUsed="true" Name="Default Paragraph Font"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="List Continue 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Message Header"/>
      <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Salutation"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Date"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text First Indent"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text First Indent 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Note Heading"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Body Text Indent 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Block Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Hyperlink"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="FollowedHyperlink"/>
      <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
      <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Document Map"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Plain Text"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="E-mail Signature"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Top of Form"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Bottom of Form"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal (Web)"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Acronym"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Address"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Cite"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Code"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Definition"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Keyboard"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Preformatted"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Sample"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Typewriter"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="HTML Variable"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Normal Table"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="annotation subject"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="No List"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Outline List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Simple 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Classic 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Colorful 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Columns 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Grid 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 4"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 5"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 7"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table List 8"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table 3D effects 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Contemporary"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Elegant"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Professional"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Subtle 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Subtle 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 1"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 2"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Web 3"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Balloon Text"/>
      <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Table Theme"/>
      <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
      <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
      <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
      <w:LsdException Locked="false" Priority="34" QFormat="true"
       Name="List Paragraph"/>
      <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
      <w:LsdException Locked="false" Priority="30" QFormat="true"
       Name="Intense Quote"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
      <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
      <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
      <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="19" QFormat="true"
       Name="Subtle Emphasis"/>
      <w:LsdException Locked="false" Priority="21" QFormat="true"
       Name="Intense Emphasis"/>
      <w:LsdException Locked="false" Priority="31" QFormat="true"
       Name="Subtle Reference"/>
      <w:LsdException Locked="false" Priority="32" QFormat="true"
       Name="Intense Reference"/>
      <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
      <w:LsdException Locked="false" Priority="37" SemiHidden="true"
       UnhideWhenUsed="true" Name="Bibliography"/>
      <w:LsdException Locked="false" Priority="39" SemiHidden="true"
       UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
      <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
      <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
      <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
      <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
      <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
      <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
      <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
      <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
      <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 1"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 2"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 3"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 4"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 5"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="46"
       Name="Grid Table 1 Light Accent 6"/>
      <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
      <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
      <w:LsdException Locked="false" Priority="51"
       Name="Grid Table 6 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="52"
       Name="Grid Table 7 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
      <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
      <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 1"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 1"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 2"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 2"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 3"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 3"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 4"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 4"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 5"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 5"/>
      <w:LsdException Locked="false" Priority="46"
       Name="List Table 1 Light Accent 6"/>
      <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
      <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
      <w:LsdException Locked="false" Priority="51"
       Name="List Table 6 Colorful Accent 6"/>
      <w:LsdException Locked="false" Priority="52"
       Name="List Table 7 Colorful Accent 6"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Mention"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Smart Hyperlink"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Hashtag"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Unresolved Mention"/>
      <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
       Name="Smart Link"/>
     </w:LatentStyles>
    </xml><![endif]-->
    <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;
        mso-font-charset:0;
        mso-generic-font-family:roman;
        mso-font-pitch:variable;
        mso-font-signature:3 0 0 0 1 0;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {mso-style-unhide:no;
        mso-style-qformat:yes;
        mso-style-parent:"";
        margin:0cm;
        line-height:115%;
        mso-pagination:widow-orphan;
        font-size:11.0pt;
        font-family:"Arial",sans-serif;
        mso-fareast-font-family:Arial;
        mso-ansi-language:EN-GB;}
    h1
        {mso-style-priority:9;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:20.0pt;
        margin-right:0cm;
        margin-bottom:6.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:1;
        font-size:20.0pt;
        font-family:"Arial",sans-serif;
        mso-font-kerning:0pt;
        mso-ansi-language:EN-GB;
        font-weight:normal;}
    h2
        {mso-style-noshow:yes;
        mso-style-priority:9;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:18.0pt;
        margin-right:0cm;
        margin-bottom:6.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:2;
        font-size:16.0pt;
        font-family:"Arial",sans-serif;
        mso-ansi-language:EN-GB;
        font-weight:normal;}
    h3
        {mso-style-noshow:yes;
        mso-style-priority:9;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:16.0pt;
        margin-right:0cm;
        margin-bottom:4.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:3;
        font-size:14.0pt;
        font-family:"Arial",sans-serif;
        color:#434343;
        mso-ansi-language:EN-GB;
        font-weight:normal;}
    h4
        {mso-style-noshow:yes;
        mso-style-priority:9;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:14.0pt;
        margin-right:0cm;
        margin-bottom:4.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:4;
        font-size:12.0pt;
        font-family:"Arial",sans-serif;
        color:#666666;
        mso-ansi-language:EN-GB;
        font-weight:normal;}
    h5
        {mso-style-noshow:yes;
        mso-style-priority:9;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:12.0pt;
        margin-right:0cm;
        margin-bottom:4.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:5;
        font-size:11.0pt;
        font-family:"Arial",sans-serif;
        color:#666666;
        mso-ansi-language:EN-GB;
        font-weight:normal;}
    h6
        {mso-style-noshow:yes;
        mso-style-priority:9;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:12.0pt;
        margin-right:0cm;
        margin-bottom:4.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        mso-outline-level:6;
        font-size:11.0pt;
        font-family:"Arial",sans-serif;
        color:#666666;
        mso-ansi-language:EN-GB;
        font-weight:normal;
        font-style:italic;
        mso-bidi-font-style:normal;}
    p.MsoTitle, li.MsoTitle, div.MsoTitle
        {mso-style-priority:10;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:0cm;
        margin-right:0cm;
        margin-bottom:3.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        font-size:26.0pt;
        font-family:"Arial",sans-serif;
        mso-fareast-font-family:Arial;
        mso-ansi-language:EN-GB;}
    p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
        {mso-style-priority:11;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        mso-style-next:Normal;
        margin-top:0cm;
        margin-right:0cm;
        margin-bottom:16.0pt;
        margin-left:0cm;
        line-height:115%;
        mso-pagination:widow-orphan lines-together;
        page-break-after:avoid;
        font-size:15.0pt;
        font-family:"Arial",sans-serif;
        mso-fareast-font-family:Arial;
        color:#666666;
        mso-ansi-language:EN-GB;}
    .MsoChpDefault
        {mso-style-type:export-only;
        mso-default-props:yes;
        font-family:"Arial",sans-serif;
        mso-ascii-font-family:Arial;
        mso-fareast-font-family:Arial;
        mso-hansi-font-family:Arial;
        mso-bidi-font-family:Arial;
        mso-font-kerning:0pt;
        mso-ligatures:none;
        mso-ansi-language:EN-GB;}
    .MsoPapDefault
        {mso-style-type:export-only;
        line-height:115%;}
    @page WordSection1
        {size:595.45pt 841.7pt;
        margin:72.0pt 72.0pt 72.0pt 72.0pt;
        mso-header-margin:36.0pt;
        mso-footer-margin:36.0pt;
        mso-page-numbers:1;
        mso-paper-source:0;}
    div.WordSection1
        {page:WordSection1;}
    -->
    </style>
    <!--[if gte mso 10]>
    <style>
     /* Style Definitions */
     table.MsoNormalTable
        {mso-style-name:"Table Normal";
        mso-tstyle-rowband-size:0;
        mso-tstyle-colband-size:0;
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-parent:"";
        mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
        mso-para-margin:0cm;
        line-height:115%;
        mso-pagination:widow-orphan;
        font-size:11.0pt;
        font-family:"Arial",sans-serif;
        mso-ansi-language:EN-GB;}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
     <o:shapedefaults v:ext="edit" spidmax="1026"/>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <o:shapelayout v:ext="edit">
      <o:idmap v:ext="edit" data="1"/>
     </o:shapelayout></xml><![endif]-->
    </head>
    
    <body lang=EN-IN style='tab-interval:36.0pt;word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal><span lang=EN-GB>Terms and Conditions</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Introduction</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Welcome to www.subcontractit.com (the
    “Website”). This Website is operated and controlled by Connected Contractors
    LLC (“we”, “our”, or “us”). The terms “you”, “your”, “User” refer to the user
    of the Website.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>By using this Website, you agree to be
    bound by these Terms and Conditions (“Terms”) which form a legally binding
    agreement. If you do not want to be bound by these Terms, do not use this
    Website.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>We may alter these Terms at any time and
    your use of the whole/any part of this Website following such change shall be
    deemed to be your acceptance of such change.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Use of the Website</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>This Website is intended for use by
    businesses looking to manage partners that perform work for them as
    subcontractors. By using this Website, you warrant that you have the necessary
    permissions and consents to bind your company to these Terms.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>By signing up for a “Free Trial” on our
    Website, you acknowledge and agree that the &quot;Free Trial&quot; is provided
    on a temporary basis and does not entitle you to the indefinite use of our software.
    We reserve the right to revoke your access to the Website and its services at
    any time we deem necessary, without prior notice to you.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Intellectual Property</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>All intellectual property rights in the
    content and design of this Website are owned by Connected Contractors LLC, its
    licensors or other providers of such material and are protected by copyright,
    trademark, patent, trade secret, and other intellectual property or proprietary
    rights laws.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Liability and Indemnity</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>To the fullest extent permissible by law,
    we exclude and disclaim all warranties, terms, conditions and representations
    that might otherwise be implied by law in relation to this Website. We are not
    liable for any loss or damage caused by a distributed denial-of-service attack,
    viruses, or other technologically harmful material that may infect your
    computer equipment, programs, data or other proprietary material due to your
    use of the Website.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>You agree to indemnify and hold Connected
    Contractors LLC harmless from any demands, loss, liability, claims or expenses
    (including attorneys’ fees), made against them by any third party due to, or
    arising out of, or in connection with your use of the Website.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Confidentiality and Data Protection</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>By using this Website, you agree to comply
    with all applicable laws and regulations, including those relating to the
    transmission of technical data exported from your country of residence.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>We have procedures in place to ensure that
    your confidential information is maintained in a secure and confidential manner.
    Our Privacy Policy, which is incorporated by reference into these Terms,
    further describes the collection and use of information on this Website.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Dispute Resolution</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>If a dispute arises under this agreement,
    we agree to first try to resolve it with the help of a mutually agreed-upon
    mediator. Any costs and fees other than attorney fees associated with the
    mediation will be shared equally by each of us.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Severability</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>If any provision of these Terms is found to
    be unenforceable or invalid, that provision shall be limited or eliminated to
    the minimum extent necessary so that these Terms shall otherwise remain in full
    force and effect.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Entire Agreement</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>These Terms are the entire agreement
    between us and replace all prior or contemporaneous understandings or
    agreements, written or oral, regarding the subject matter of these Terms.</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Contact Information</span></p>
    
    <p class=MsoNormal><span lang=EN-GB>If you have any questions about these
    Terms, please contact us at: info@subcontractit.com</span></p>
    
    <p class=MsoNormal><span lang=EN-GB><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoNormal><span lang=EN-GB>Last updated: 6/15/2023</span></p>
    
    </div>
    
    </body>
    
    </html>`;

    return (
        <div className="terms-and-conditions-div">
        <div dangerouslySetInnerHTML={{ __html: myHTML }}></div>
        </div>
    );

}

export default TermsAndConditions;