import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createDocument = createAsyncThunk(
  "document/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/createDocument", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const requestDocument = createAsyncThunk(
  "document/request",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/createDocument", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const editDocument = createAsyncThunk(
  "document/edit",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/editDocument", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getDocuments = createAsyncThunk(
  "documents/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/getDocuments", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPartnerDocuments = createAsyncThunk(
  "/partner/documents/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/getPartnerDocuments", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "documents/uploadDocument",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/uploadDocument", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "document/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/deleteDocument", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    isDocumentsFetching: false,
    isDocumentsSuccess: false,
    isDocumentsError: false,
    isPartnerDocumentsFetching: false,
    isPartnerDocumentsSuccess: false,
    isPartnerDocumentsError: false,
    documentsErrorMessage: "",
    partnerDocumentsErrorMessage: "",
    isCreateDocumentFetching: false,
    isCreateDocumentSuccess: false,
    isCreateDocumentError: false,
    createDocumentErrorMessage: "",
    isRequestDocumentFetching: false,
    isRequestDocumentSuccess: false,
    isRequestDocumentError: false,
    requestDocumentErrorMessage: "",
    isEditDocumentFetching: false,
    isEditDocumentSuccess: false,
    isEditDocumentError: false,
    editDocumentErrorMessage: "",
    documentsList: [],
    partnerDocumentsList: [],
    documentId: "",
    documentName: "",
    isUploadDocumentFetching: false,
    isUploadDocumentSuccess: false,
    isUploadDocumentError: false,
    uploadDocumentErrorMessage: "",
    isDeleteDocumentFetching: false,
    isDeleteDocumentSuccess: false,
    isDeleteDocumentError: false,
    deleteDocumentErrorMessage: "",
  },
  reducers: {
    clearCreateDocumentState: (state) => {
      state.isCreateDocumentError = false;
      state.isCreateDocumentSuccess = false;
      state.isCreateDocumentFetching = false;
      state.createDocumentErrorMessage = "";
      return state;
    },
    clearRequestDocumentState: (state) => {
      state.isRequestDocumentError = false;
      state.isRequestDocumentSuccess = false;
      state.isRequestDocumentFetching = false;
      state.requestDocumentErrorMessage = "";
      return state;
    },
    clearEditDocumentState: (state) => {
      state.isEditDocumentError = false;
      state.isEditDocumentSuccess = false;
      state.isEditDocumentFetching = false;
      state.editDocumentErrorMessage = "";
      return state;
    },
    clearUploadDocumentState: (state) => {
      state.isUploadDocumentError = false;
      state.isUploadDocumentSuccess = false;
      state.isUploadDocumentFetching = false;
      state.uploadDocumentErrorMessage = "";
      return state;
    },
    clearDeleteDocumentState: (state) => {
      state.isDeleteDocumentError = false;
      state.isDeleteDocumentSuccess = false;
      state.isDeleteDocumentFetching = false;
      state.deleteDocumentErrorMessage = "";
      return state;
    },
    clearDocumentsState: (state) => {
      state.isDocumentsError = false;
      state.isDocumentsSuccess = false;
      state.isDocumentsFetching = false;
      state.documentsList = [];
      return state;
    },
    clearPartnerDocumentsState: (state) => {
      state.isPartnerDocumentsError = false;
      state.isPartnerDocumentsSuccess = false;
      state.isPartnerDocumentsFetching = false;
      state.partnerDocumentsList = [];
      return state;
    },
    updateDocumentDetailId: (state, payload) => {
      if (payload.payload.documentId) {
        state.documentId = payload.payload.documentId;
      }
      if (payload.payload.documentName) {
        state.documentName = payload.payload.documentName;
      }
    },
    updateDocumentsList: (state, payload) => {
      state.documentsList = payload.payload;
    },
  },
  extraReducers: {
    [createDocument.fulfilled]: (state, { payload }) => {
      state.isCreateDocumentFetching = false;
      if (payload.status == "error") {
        state.isCreateDocumentError = true;
        state.createDocumentErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isCreateDocumentSuccess = true;
      }
    },
    [createDocument.pending]: (state) => {
      state.isCreateDocumentFetching = true;
    },
    [createDocument.rejected]: (state, { payload }) => {
      state.isCreateDocumentFetching = false;
      state.isCreateDocumentError = true;
      state.createDocumentErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [requestDocument.fulfilled]: (state, { payload }) => {
      state.isRequestDocumentFetching = false;
      if (payload.status == "error") {
        state.isRequestDocumentError = true;
        state.requestDocumentErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isRequestDocumentSuccess = true;
      }
    },
    [requestDocument.pending]: (state) => {
      state.isRequestDocumentFetching = true;
    },
    [requestDocument.rejected]: (state, { payload }) => {
      state.isRequestDocumentFetching = false;
      state.isRequestDocumentError = true;
      state.requestDocumentErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [editDocument.fulfilled]: (state, { payload }) => {
      state.isEditDocumentFetching = false;
      if (payload.status == "error") {
        state.isEditDocumentError = true;
        state.editDocumentErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isEditDocumentSuccess = true;
      }
    },
    [editDocument.pending]: (state) => {
      state.isEditDocumentFetching = true;
    },
    [editDocument.rejected]: (state, { payload }) => {
      state.isEditDocumentFetching = false;
      state.isEditDocumentError = true;
      state.editDocumentErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getPartnerDocuments.fulfilled]: (state, { payload }) => {
      state.isPartnerDocumentsFetching = false;
      state.partnerDocumentsList = payload;
      state.isPartnerDocumentsSuccess = true;
      return state;
    },
    [getPartnerDocuments.rejected]: (state, { payload }) => {
      state.isPartnerDocumentsFetching = false;
      state.isPartnerDocumentsError = true;
      state.partnerDocumentsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getPartnerDocuments.pending]: (state) => {
      state.isPartnerDocumentsFetching = true;
    },
    [getDocuments.fulfilled]: (state, { payload }) => {
      state.isDocumentsFetching = false;
      state.documentsList = payload;
      state.isDocumentsSuccess = true;
      return state;
    },
    [getDocuments.rejected]: (state, { payload }) => {
      state.isDocumentsFetching = false;
      state.isDocumentsError = true;
      state.documentsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getDocuments.pending]: (state) => {
      state.isDocumentsFetching = true;
    },
    [uploadDocument.fulfilled]: (state, { payload }) => {
      state.isUploadDocumentFetching = false;
      state.isUploadDocumentSuccess = true;
      return state;
    },
    [uploadDocument.rejected]: (state, { payload }) => {
      state.isUploadDocumentFetching = false;
      state.isUploadDocumentError = true;
      state.uploadDocumentErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [uploadDocument.pending]: (state) => {
      state.isUploadDocumentFetching = true;
    },
    [deleteDocument.fulfilled]: (state, { payload }) => {
      state.isDeleteDocumentFetching = false;
      state.isDeleteDocumentSuccess = true;
      return state;
    },
    [deleteDocument.rejected]: (state, { payload }) => {
      state.isDeleteDocumentFetching = false;
      state.isDeleteDocumentError = true;
      state.deleteDocumentErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteDocument.pending]: (state) => {
      state.isDeleteDocumentFetching = true;
    },
  },
});

export const {
  clearDocumentsState,
  clearPartnerDocumentsState,
  clearCreateDocumentState,
  updateDocumentsList,
  updateDocumentDetailId,
  clearUploadDocumentState,
  clearDeleteDocumentState,
  clearEditDocumentState,
  clearRequestDocumentState,
} = documentsSlice.actions;

export const documentsSelector = (state) => state.documents;
export const publicDocumentSelector = (state) =>
  (state.documents &&
    state.documents.documentsList &&
    state.documents.documentsList.public) ||
  [];
export const partnerDocumentSelector = (state) =>
  (state.documents &&
    state.documents.documentsList &&
    state.documents.documentsList.partner) ||
  [];
export const expiredDocumentSelector = (state) =>
  (state.documents &&
    state.documents.documentsList &&
    state.documents.documentsList.expired) ||
  [];
export const pendingDocumentSelector = (state) =>
  (state.documents &&
    state.documents.documentsList &&
    state.documents.documentsList.pending) ||
  [];

export default documentsSlice.reducer;
