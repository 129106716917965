import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import {Route, Link, Routes, useLocation} from 'react-router-dom';

function ToolbarLayout2(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const location = useLocation();
  var pageTitle = 'Dashboard';
  if(location.pathname.indexOf("/jobs") !== -1){
    pageTitle = "Jobs";
  }
  else if(location.pathname.indexOf("/partners") !== -1){
    pageTitle = "Partners";
  }
  else if(location.pathname.indexOf("/job/") !== -1){
    pageTitle = "Job Details";
  }
  else if(location.pathname.indexOf("/quotes") !== -1){
    pageTitle = "Quotes";
  }

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.dark
              : toolbarTheme.palette.background.dark,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64" >
          <div className="w-full items-center px-8 overflow-x-auto toolbar-color text-center">{pageTitle}</div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout2);
