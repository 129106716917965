import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createWorkType = createAsyncThunk(
  "workType/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/createWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createPartnerSearchWorkType = createAsyncThunk(
  "partnerSearchWorkType/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/createPartnerSearchWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createRoofingSeachableState = createAsyncThunk(
  "partnerSearchState/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Partners/createPartnerSearchableState", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteSearchableState = createAsyncThunk(
  "deleteSearchableState/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Partners/deleteSearchableState", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createRoofingWorkType = createAsyncThunk(
    "roofingWorkType/create",
    async (data, thunkAPI) => {
      try {
        const userData = data;
        return await axios
          .post(Constants.API_URL + "WorkTypes/createRoofingWorkType", userData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
            },
          })
          .then(function (response) {
            if (response.status === 200) {
              return response.data;
            } else {
              return thunkAPI.rejectWithValue(response);
            }
          });
      } catch (e) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

export const getWorkTypes = createAsyncThunk(
  "workTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/getWorkTypesList", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getSearchWorkTypes = createAsyncThunk(
  "searchWorkTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/getSearchableWorkTypes", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPartnerSearchWorkTypes = createAsyncThunk(
  "partnerSearchWorkTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/getSearchablePartnerWorkTypes", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getRoofingWorkTypes = createAsyncThunk(
  "roofingWorkTypes/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "WorkTypes/getRoofingWorkTypesList",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateWorkType = createAsyncThunk(
  "workType/updateWorkType",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/updateWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteWorkType = createAsyncThunk(
  "workType/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/deleteWorkType", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getWorkTypeSettings = createAsyncThunk(
  "workType//get/settings",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/getWorkTypeSettings", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addWorkTypeSettings = createAsyncThunk(
  "workTypes/settings/add",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/addWorkTypeSettings", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addWorkTypeSetting = createAsyncThunk(
  "workTypes/setting/add",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "WorkTypes/addWorkTypeSetting", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const workTypesSlice = createSlice({
  name: "workTypes",
  initialState: {
    isWorkTypesFetching: false,
    isWorkTypesSuccess: false,
    isWorkTypesError: false,
    workTypesErrorMessage: "",
    isRoofingWorkTypesFetching: false,
    isRoofingWorkTypesSuccess: false,
    isRoofingWorkTypesError: false,
    roofingWorkTypesErrorMessage: "",
    isSearchWorkTypesFetching: false,
    isSearchWorkTypesSuccess: false,
    isSearchWorkTypesError: false,
    searchWorkTypesErrorMessage: "",
    isPartnerSearchWorkTypesFetching: false,
    isPartnerSearchWorkTypesSuccess: false,
    isPartnerSearchWorkTypesError: false,
    partnerSearchWorkTypesErrorMessage: "",
    isCreateWorkTypeFetching: false,
    isCreateWorkTypeSuccess: false,
    isCreateWorkTypeError: false,
    createWorkTypeErrorMessage: "",
    isCreatePartnerSearchWorkTypeFetching: false,
    isCreatePartnerSearchWorkTypeSuccess: false,
    isCreatePartnerSearchWorkTypeError: false,
    createPartnerSearchWorkTypeErrorMessage: "",
    isCreateRoofingSeachableStateFetching: false,
    isCreateRoofingSeachableStateSuccess: false,
    isCreateRoofingSeachableStateError: false,
    createRoofingSeachableStateErrorMessage: "",
    isCreateRoofingWorkTypeFetching: false,
    isCreateRoofingWorkTypeSuccess: false,
    isCreateRoofingWorkTypeError: false,
    createRoofingWorkTypeErrorMessage: "",
    workTypesList: [],
    roofingWorkTypesList: [],
    searchWorkTypesList:[],
    partnerSearchWorkTypesList:[],
    workTypeId: "",
    workTypeName: "",
    isUpdateWorkTypeFetching: false,
    isUpdateWorkTypeSuccess: false,
    isUpdateWorkTypeError: false,
    updateWorkTypeErrorMessage: "",
    isDeleteWorkTypeFetching: false,
    isDeleteWorkTypeSuccess: false,
    isDeleteWorkTypeError: false,
    deleteWorkTypeErrorMessage: "",
    isDeleteSearchableStateFetching: false,
    isDeleteSearchableStateSuccess: false,
    isDeleteSearchableStateError: false,
    deleteSearchableStateErrorMessage: "",

    isGetWorkTypeSettingsError: false,
    isGetWorkTypeSettingsSuccess: false,
    isGetWorkTypeSettingsFetching: false,
    getWorkTypeSettingsErrorMessage: "",
    workTypeSettings: [],

    isAddWorkTypeSettingsError: false,
    isAddWorkTypeSettingsSuccess: false,
    isAddWorkTypeSettingsFetching: false,
    addWorkTypeSettingsErrorMessage: "",    

    isAddWorkTypeSettingError: false,
    isAddWorkTypeSettingSuccess: false,
    isAddWorkTypeSettingFetching: false,
    addWorkTypeSettingErrorMessage: "",
  },
  reducers: {
    clearCreateWorkTypeState: (state) => {
      state.isCreateWorkTypeError = false;
      state.isCreateWorkTypeSuccess = false;
      state.isCreateWorkTypeFetching = false;
      return state;
    },
    clearCreatePartnerSearchWorkTypeState: (state) => {
      state.isCreatePartnerSearchWorkTypeError = false;
      state.isCreatePartnerSearchWorkTypeSuccess = false;
      state.isCreatePartnerSearchWorkTypeFetching = false;
      state.createPartnerSearchWorkTypeErrorMessage = "";
      return state;
    },
    clearGetWorkTypeSettingsState: (state) => {
      state.isGetWorkTypeSettingsError = false;
      state.isGetWorkTypeSettingsFetching = false;
      state.isGetWorkTypeSettingsSuccess = false;
      state.getWorkTypeSettingsErrorMessage = "";
      state.workTypeSettings = [];
      return state;
    },
    clearAddWorkTypeSettingsState: (state) => {
      state.isAddWorkTypeSettingsError = false;
      state.isAddWorkTypeSettingsSuccess = false;
      state.isAddWorkTypeSettingsFetching = false;
      state.addWorkTypeSettingsErrorMessage = "";
      return state;
    },
    clearAddWorkTypeSettingState: (state) => {
      state.isAddWorkTypeSettingError = false;
      state.isAddWorkTypeSettingSuccess = false;
      state.isAddWorkTypeSettingFetching = false;
      state.addWorkTypeSettingErrorMessage = "";
      return state;
    },
    clearCreateRoofingSeachableStateState: (state) => {
      state.isCreateRoofingSeachableStateError = false;
      state.isCreateRoofingSeachableStateSuccess = false;
      state.isCreateRoofingSeachableStateFetching = false;
      state.createRoofingSeachableStateErrorMessage = "";
      return state;
    },
    clearCreateRoofingWorkTypeState: (state) => {
        state.isCreateRoofingWorkTypeError = false;
        state.isCreateRoofingWorkTypeSuccess = false;
        state.isCreateRoofingWorkTypeFetching = false;
        return state;
      },
    clearUpdateWorkTypeState: (state) => {
      state.isUpdateWorkTypeError = false;
      state.isUpdateWorkTypeSuccess = false;
      state.isUpdateWorkTypeFetching = false;
      state.updateWorkTypeErrorMessage = "";
      return state;
    },
    clearDeleteWorkTypeState: (state) => {
      state.isDeleteWorkTypeError = false;
      state.isDeleteWorkTypeSuccess = false;
      state.isDeleteWorkTypeFetching = false;
      state.deleteWorkTypeErrorMessage = "";
      return state;
    },
    clearDeleteSearchableState: (state) => {
      state.isDeleteSearchableStateError = false;
      state.isDeleteSearchableStateSuccess = false;
      state.isDeleteSearchableStateFetching = false;
      state.deleteSearchableStateErrorMessage = "";
      return state;
    },
    clearWorkTypesState: (state) => {
      state.isWorkTypesError = false;
      state.isWorkTypesSuccess = false;
      state.isWorkTypesFetching = false;
      state.workTypesList = [];
      return state;
    },
    clearRoofingWorkTypesState: (state) => {
      state.isRoofingWorkTypesError = false;
      state.isRoofingWorkTypesSuccess = false;
      state.isRoofingWorkTypesFetching = false;
      state.roofingWorkTypesList = [];
      return state;
    },
    clearSearchWorkTypesState: (state) => {
      state.isSearchWorkTypesError = false;
      state.isSearchWorkTypesSuccess = false;
      state.isSearchWorkTypesFetching = false;
      state.searchWorkTypesList = [];
      return state;
    },
    clearPartnerSearchWorkTypesState: (state) => {
      state.isPartnerSearchWorkTypesError = false;
      state.isPartnerSearchWorkTypesSuccess = false;
      state.isPartnerSearchWorkTypesFetching = false;
      state.partnerSearchWorkTypesList = [];
      return state;
    },
    updateWorkTypeDetailId: (state, payload) => {
      if (payload.payload.workTypeId) {
        state.workTypeId = payload.payload.workTypeId;
      }
      if (payload.payload.workTypeName) {
        state.workTypeName = payload.payload.workTypeName;
      }
    },
    updateWorkTypesList: (state, payload) => {
      state.workTypesList = payload.payload;
    },
  },
  extraReducers: {
    [createWorkType.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isCreateWorkTypeFetching = false;
      if (payload.status == "error") {
        state.isCreateWorkTypeError = true;
        state.createWorkTypeErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isCreateWorkTypeSuccess = true;
      }
    },
    [createWorkType.pending]: (state) => {
      state.isCreateWorkTypeFetching = true;
    },
    [createWorkType.rejected]: (state, { payload }) => {
      state.isCreateWorkTypeFetching = false;
      state.isCreateWorkTypeError = true;
      state.createWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [createPartnerSearchWorkType.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isCreatePartnerSearchWorkTypeFetching = false;
      if (payload.status == "error") {
        state.isCreatePartnerSearchWorkTypeError = true;
        state.createPartnerSearchWorkTypeErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isCreatePartnerSearchWorkTypeSuccess = true;
      }
    },
    [createPartnerSearchWorkType.pending]: (state) => {
      state.isCreatePartnerSearchWorkTypeFetching = true;
    },
    [createPartnerSearchWorkType.rejected]: (state, { payload }) => {
      state.isCreatePartnerSearchWorkTypeFetching = false;
      state.isCreatePartnerSearchWorkTypeError = true;
      state.createPartnerSearchWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [createRoofingSeachableState.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isCreateRoofingSeachableStateFetching = false;
      if (payload.status == "error") {
        state.isCreateRoofingSeachableStateError = true;
        state.createPartnerSearchWorkTypeErrorMessage =
          payload.error || "Invalid file. Please try agian.";
      } else {
        state.isCreateRoofingSeachableStateSuccess = true;
      }
    },
    [createRoofingSeachableState.pending]: (state) => {
      state.isCreateRoofingSeachableStateFetching = true;
    },
    [createRoofingSeachableState.rejected]: (state, { payload }) => {
      state.isCreateRoofingSeachableStateFetching = false;
      state.isCreateRoofingSeachableStateError = true;
      state.createRoofingSeachableStateErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [createRoofingWorkType.fulfilled]: (state, { payload }) => {
        console.log("payload", payload);
        state.isCreateRoofingWorkTypeFetching = false;
        if (payload.status == "error") {
          state.isCreateRoofingWorkTypeError = true;
          state.createRoofingWorkTypeErrorMessage =
            payload.error || "Invalid file. Please try agian.";
        } else {
          state.isCreateRoofingWorkTypeSuccess = true;
        }
      },
      [createRoofingWorkType.pending]: (state) => {
        state.isCreateRoofingWorkTypeFetching = true;
      },
      [createRoofingWorkType.rejected]: (state, { payload }) => {
        state.isCreateRoofingWorkTypeFetching = false;
        state.isCreateRoofingWorkTypeError = true;
        state.createRoofingWorkTypeErrorMessage =
          payload && payload.error_description
            ? payload.error_description
            : "Invalid Credentials. Please try again!";
      },
    [getWorkTypes.fulfilled]: (state, { payload }) => {
      state.isWorkTypesFetching = false;
      state.workTypesList = payload;
      state.isWorkTypesSuccess = true;
      return state;
    },
    [getWorkTypes.rejected]: (state, { payload }) => {
      state.isWorkTypesFetching = false;
      state.isWorkTypesError = true;
      state.workTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getWorkTypes.pending]: (state) => {
      state.isWorkTypesFetching = true;
    },
    [getRoofingWorkTypes.fulfilled]: (state, { payload }) => {
      state.isRoofingWorkTypesFetching = false;
      state.roofingWorkTypesList = payload;
      state.isRoofingWorkTypesSuccess = true;
      return state;
    },
    [getRoofingWorkTypes.rejected]: (state, { payload }) => {
      state.isRoofingWorkTypesFetching = false;
      state.isRoofingWorkTypesError = true;
      state.roofingWorkTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getRoofingWorkTypes.pending]: (state) => {
      state.isRoofingWorkTypesFetching = true;
    },
    [getSearchWorkTypes.fulfilled]: (state, { payload }) => {
      state.isSearchWorkTypesFetching = false;
      state.searchWorkTypesList = payload;
      state.isSearchWorkTypesSuccess = true;
      return state;
    },
    [getSearchWorkTypes.rejected]: (state, { payload }) => {
      state.isSearchWorkTypesFetching = false;
      state.isSearchWorkTypesError = true;
      state.searchWorkTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getSearchWorkTypes.pending]: (state) => {
      state.isSearchWorkTypesFetching = true;
    },
    [getPartnerSearchWorkTypes.fulfilled]: (state, { payload }) => {
      state.isPartnerSearchWorkTypesFetching = false;
      state.partnerSearchWorkTypesList = payload;
      state.isPartnerSearchWorkTypesSuccess = true;
      return state;
    },
    [getPartnerSearchWorkTypes.rejected]: (state, { payload }) => {
      state.isPartnerSearchWorkTypesFetching = false;
      state.isPartnerSearchWorkTypesError = true;
      state.partnerSearchWorkTypesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getPartnerSearchWorkTypes.pending]: (state) => {
      state.isPartnerSearchWorkTypesFetching = true;
    },
    [updateWorkType.fulfilled]: (state, { payload }) => {
      state.isUpdateWorkTypeFetching = false;
      state.isUpdateWorkTypeSuccess = true;
      return state;
    },
    [updateWorkType.rejected]: (state, { payload }) => {
      state.isUpdateWorkTypeFetching = false;
      state.isUpdateWorkTypeError = true;
      state.updateWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateWorkType.pending]: (state) => {
      state.isUpdateWorkTypeFetching = true;
    },
    [deleteWorkType.fulfilled]: (state, { payload }) => {
      state.isDeleteWorkTypeFetching = false;
      state.isDeleteWorkTypeSuccess = true;
      return state;
    },
    [deleteWorkType.rejected]: (state, { payload }) => {
      state.isDeleteWorkTypeFetching = false;
      state.isDeleteWorkTypeError = true;
      state.deleteWorkTypeErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteWorkType.pending]: (state) => {
      state.isDeleteWorkTypeFetching = true;
    },
    [deleteSearchableState.fulfilled]: (state, { payload }) => {
      state.isDeleteSearchableStateFetching = false;
      state.isDeleteSearchableStateSuccess = true;
      return state;
    },
    [deleteSearchableState.rejected]: (state, { payload }) => {
      state.isDeleteSearchableStateFetching = false;
      state.isDeleteSearchableStateError = true;
      state.deleteSearchableStateErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteSearchableState.pending]: (state) => {
      state.isDeleteSearchableStateFetching = true;
    },
    [getWorkTypeSettings.fulfilled]: (state, { payload }) => {
      state.isGetWorkTypeSettingsFetching = false;
      state.workTypeSettings = payload && payload.data || [];
      state.isGetWorkTypeSettingsSuccess = true;
      return state;
    },
    [getWorkTypeSettings.rejected]: (state, { payload }) => {
      state.isGetWorkTypeSettingsFetching = false;
      state.isGetWorkTypeSettingsError = true;
      state.getWorkTypeSettingsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [getWorkTypeSettings.pending]: (state) => {
      state.isGetWorkTypeSettingsFetching = true;
    },
    [addWorkTypeSettings.fulfilled]: (state, { payload }) => {
      state.isAddWorkTypeSettingsFetching = false;
      state.workTypeSettings = payload && payload.data || "";
      state.isAddWorkTypeSettingsSuccess = true;
      return state;
    },
    [addWorkTypeSettings.rejected]: (state, { payload }) => {
      state.isAddWorkTypeSettingsFetching = false;
      state.isAddWorkTypeSettingsError = true;
      state.addWorkTypeSettingsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [addWorkTypeSettings.pending]: (state) => {
      state.isAddWorkTypeSettingsFetching = true;
      return state;
    },
    [addWorkTypeSetting.fulfilled]: (state, { payload }) => {
      state.isAddWorkTypeSettingFetching = false;
      state.workTypeSettings = payload && payload.data || "";
      state.isAddWorkTypeSettingsSuccess = true;
      return state;
    },
    [addWorkTypeSetting.rejected]: (state, { payload }) => {
      state.isAddWorkTypeSettingFetching = false;
      state.isAddWorkTypeSettingError = true;
      state.addWorkTypeSettingErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
      return state;
    },
    [addWorkTypeSetting.pending]: (state) => {
      state.isAddWorkTypeSettingFetching = true;
      return state;
    },
  },
});

export const {
  clearWorkTypesState,
  clearCreateWorkTypeState,
  updateWorkTypesList,
  updateWorkTypeDetailId,
  clearDeleteSearchableState,
  clearUpdateWorkTypeState,
  clearDeleteWorkTypeState,
  clearRoofingWorkTypesState,
  clearCreateRoofingWorkTypeState,
  clearCreatePartnerSearchWorkTypeState,
  clearCreateRoofingSeachableStateState,
  clearSearchWorkTypesState,
  clearPartnerSearchWorkTypesState,
  clearAddWorkTypeSettingsState,
  clearGetWorkTypeSettingsState,
  clearAddWorkTypeSettingState
} = workTypesSlice.actions;

export const workTypesSelector = (state) => state.workTypes;

export default workTypesSlice.reducer;
