import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import jwtService from "../../auth/services/jwtService";
import { useThemeMediaQuery } from "@fuse/hooks";
import toast from "react-hot-toast";
import { clearResetPasswordState, clearSendForgotCodeState, clearVerifyForgotCodeState, resetPassword, selectUser, sendForgotCode, verifyForgotCode } from "app/store/userSlice";
import { useSelector, useDispatch } from "react-redux";
import history from '@history';


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(4, "Password is too short - must be at least 4 chars."),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

function ForgotPassword() {
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState("1");
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotCode, setForgotCode] = useState("");
  const [forgotPassword, setForgotPassword] = useState("");
  const [forgotConfirmPassword, setForgotConfirmPassword] = useState("");
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { isValid, dirtyFields, errors } = formState;

  
  const { isSendForgotCodeSuccess, isSendForgotCodeError, isResetPasswordSuccess, isResetPasswordError, isVerifyForgotCodeError, isVerifyForgotCodeSuccess, verifyForgotCodeErrorMessage, resetPasswordErrorMessage, sendForgotCodeErrorMessage, redirectURL } = useSelector(
    selectUser
  );


  useEffect(() => {
    if(isSendForgotCodeSuccess){
      toast.success("One time code sent your mail address. Please check and enter.");
      setCurrentStep("2");
      dispatch(clearSendForgotCodeState());
    }
    if(isSendForgotCodeError && sendForgotCodeErrorMessage){
      toast.error(sendForgotCodeErrorMessage);
      dispatch(clearSendForgotCodeState());
    }
    else if(isSendForgotCodeError){
      toast.error("Please try again");
      dispatch(clearSendForgotCodeState());
    }
  },[isSendForgotCodeSuccess, isSendForgotCodeError, sendForgotCodeErrorMessage]);

  useEffect(() => {
    if(isResetPasswordSuccess){
      toast.success("Password reset completed successfully. Please login.");
      dispatch(clearResetPasswordState());
      setTimeout(() => history.push('/sign-in'), 300);
    }
    if(isResetPasswordError){
      toast.error("Unable to update password. Please try again.");
      dispatch(clearResetPasswordState());
    }
  },[isResetPasswordError, isResetPasswordSuccess]);

  useEffect(() => {
    if(isVerifyForgotCodeSuccess){
      toast.success("Code verified. Please enter your password.");
      dispatch(clearVerifyForgotCodeState());
      setCurrentStep("3");
    }
    if(isVerifyForgotCodeError && verifyForgotCodeErrorMessage){
      toast.error(verifyForgotCodeErrorMessage);
      dispatch(clearVerifyForgotCodeState());
    }
    else if(isVerifyForgotCodeError){
      toast.error("Invalid Code. Please try again.");
      dispatch(clearVerifyForgotCodeState());
    }
  },[isVerifyForgotCodeError, isVerifyForgotCodeSuccess, verifyForgotCodeErrorMessage]);

  const handleSubmitButton = () => {
    console.log(currentStep);
    if(currentStep == "1"){
        sendCodeToEmail();
    }
    else if(currentStep == "2"){
        handleVerifyCode();
    }
    else if(currentStep == "3"){
        resetPasswordBtn();
    }

  };

  const sendCodeToEmail = () => {
    if(forgotEmail == ""){
      toast.error("Please enter valid Email address");
    }
    else{
      dispatch(sendForgotCode({email:forgotEmail}));
    }
  }

  const handleVerifyCode = () => {
    if(forgotCode == ""){
      toast.error("Please enter valid code");
    }
    else{
      dispatch(verifyForgotCode({email:forgotEmail,code:forgotCode}));
    }
  }

  const resetPasswordBtn = () => {
    if(forgotConfirmPassword && forgotPassword && forgotPassword === forgotConfirmPassword){
      let passwordRegEx = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      if(forgotPassword.length < 6 || forgotPassword.length > 16){
        toast.error("Password should be between 6 to 16 characters.");
        return false;
      }      
      if(!passwordRegEx.test(forgotPassword)){
        toast.error("Only Alphanumerics with !@#$%^&* characters are allowed.");
        return false;
      }
      else{
        dispatch(resetPassword({email:forgotEmail, password:forgotPassword}));
      }
    }
    else{
      toast.error("Please enter valid Password");
      return false;
    }
  }

  useEffect(() => {
    // setValue('email', 'admin@fusetheme.com', { shouldDirty: true, shouldValidate: true });
    // setValue('password', 'admin', { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  function onSubmit({ email, password }) {
    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((error_message) => {
        toast.error(error_message);
      });
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
      <Box
        className={
          isMobile
            ? " mobile-sign-in-page relative md:flex flex-auto items-center justify-center h-full p-64 lg:px-64 overflow-hidden"
            : "relative md:flex flex-auto items-center justify-center h-full p-64 lg:px-64 overflow-hidden"
        }
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className={
            isMobile
              ? "absolute left-64 -top-125 -right-64 opacity-20 primary-color"
              : "absolute left-64 -top-64 -right-64 opacity-20 primary-color"
          }
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div className={isMobile ? "mobile-sign-in-page-heading" : ""}>
              We Connect
            </div>
            <div className="primary-color login-sub-heading">
              Your Business to Contractors
            </div>
          </div>
          {/* <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400 grey-color">
            SubcontractIt helps contracts description goes here.
          </div> */}
        </div>
      </Box>

      <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-start w-full sm:w-auto md:h-full md:w-2/5 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Forgot Password
          </Typography>
          {/* <div className="flex items-baseline mt-2 font-medium">
            <Typography>Don't have an account?</Typography>
            <Link className="ml-4" to="/sign-up">
              Sign up
            </Link>
          </div> */}

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32 sign-in-page"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <>
                  <label>Email Address</label>
                  <TextField
                    {...field}
                    className="mb-24 forgot-password-input"
                    // label="Email"
                    autoFocus
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                    fullWidth
                    // autoComplete="off"
                    disabled={currentStep !="1"}
                    value={forgotEmail}
                    onChange={(e)=>setForgotEmail(e.target.value)}
                  />
                </>
              )}
            />
            {currentStep == "2" && (
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <>
                    <label>Code</label>
                    <TextField
                      {...field}
                      className="mb-24 forgot-password-input"
                      // label="Email"
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={forgotCode}
                      onChange={(e)=> setForgotCode(e.target.value)}
                    />
                  </>
                )}
              />
            )}
            {currentStep == "3" && (
              <><Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <>
                    <label>Password</label>
                    <TextField
                      {...field}
                      className="mb-24 forgot-password-input"
                      // label="Password"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      value={forgotPassword}
                      onChange={(e)=>setForgotPassword(e.target.value)}
                    />
                  </>
                )}
              />
              <Controller
                name="confirm-password"
                control={control}
                render={({ field }) => (
                  <>
                    <label>Confirm Password</label>
                    <TextField
                      {...field}
                      className="mb-24 forgot-password-input"
                      // label="Password"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      value={forgotConfirmPassword}
                      onChange={(e)=>setForgotConfirmPassword(e.target.value)}
                      />
                  </>
                )}
              /></>
            )}

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16 primary-button"
              aria-label="Sign in"
              type="button"
              size="large"
              onClick={(e)=> handleSubmitButton()}
            >
              {currentStep == "1"
                ? "Send Code"
                : currentStep == "2"
                ? "Verify Code"
                : "Reset Password"}
            </Button>
          </form>
          <br />
          <br />
          <div
            className={
              isMobile
                ? "sm:flex-row items-center justify-center sm:justify-between"
                : "flex flex-col sm:flex-row items-center justify-center sm:justify-between"
            }
          >
            <Link
              className={
                isMobile
                  ? "text-md font-medium mobile-forgot-password"
                  : "text-md font-medium"
              }
              to="/sign-in"
            >
              Already have an account? Sign in?
            </Link>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default ForgotPassword;
