import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const getCustomerPortalDetails = createAsyncThunk(
  "customerPortal/details",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "CustomerPortal/getProductsByVisibilityId",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProductSelection = createAsyncThunk(
  "customerPortal/update",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "CustomerPortal/updateProductSelection",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateInstallationDate = createAsyncThunk(
  "customerPortal/updateDate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "CustomerPortal/updateInstallationDate",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const customerPortalSlice = createSlice({
  name: "customerPortal",
  initialState: {
    isCustomerPortalDetailsFetching: false,
    isCustomerPortalDetailsSuccess: false,
    isCustomerPortalDetailsError: false,
    customerPortalDetailsErrorMessage: "",
    customerPortalDetails: [],

    isUpdateProductSelectionFetching: false,
    isUpdateProductSelectionSuccess: false,
    isUpdateProductSelectionError: false,
    updateProductSelectionErrorMessage: "",

    isUpdateInstallationDateFetching: false,
    isUpdateInstallationDateSuccess: false,
    isUpdateInstallationDateError: false,
    updateInstallationDateErrorMessage: "",
  },
  reducers: {
    clearGetCustomerPortalDetailsState: (state) => {
      state.isCustomerPortalDetailsError = false;
      state.isCustomerPortalDetailsSuccess = false;
      state.isCustomerPortalDetailsFetching = false;
      state.customerPortalDetails = [];
      state.customerPortalDetailsErrorMessage = "";
      return state;
    },
    clearUpdateProductSelectionState: (state) => {
      state.isUpdateProductSelectionError = false;
      state.isUpdateProductSelectionSuccess = false;
      state.isUpdateProductSelectionFetching = false;
      state.updateProductSelectionErrorMessage = "";
      return state;
    },
    clearUpdateInstallationDateState: (state) => {
      state.isUpdateInstallationDateError = false;
      state.isUpdateInstallationDateSuccess = false;
      state.isUpdateInstallationDateFetching = false;
      state.updateInstallationDateErrorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [getCustomerPortalDetails.fulfilled]: (state, { payload }) => {
      state.isCustomerPortalDetailsFetching = false;
      if (payload && payload.status === "success") {
        state.customerPortalDetails = (payload && payload.data) || [];
        state.isCustomerPortalDetailsSuccess = true;
      } else if (payload && payload.status === "error") {
        state.isCustomerPortalDetailsError = true;
        state.customerPortalDetailsErrorMessage = "Invalid Visibility ID";
      }
      return state;
    },
    [getCustomerPortalDetails.rejected]: (state, { payload }) => {
      state.isCustomerPortalDetailsFetching = false;
      state.isCustomerPortalDetailsError = true;
      state.customerPortalDetailsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getCustomerPortalDetails.pending]: (state) => {
      state.isCustomerPortalDetailsFetching = true;
    },
    [updateProductSelection.fulfilled]: (state, { payload }) => {
      state.isUpdateProductSelectionFetching = false;
      state.isUpdateProductSelectionSuccess = true;
      return state;
    },
    [updateProductSelection.rejected]: (state, { payload }) => {
      state.isUpdateProductSelectionFetching = false;
      state.isUpdateProductSelectionError = true;
      state.updateProductSelectionErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateProductSelection.pending]: (state) => {
      state.isUpdateProductSelectionFetching = true;
    },
    [updateInstallationDate.fulfilled]: (state, { payload }) => {
      state.isUpdateInstallationDateFetching = false;
      state.isUpdateInstallationDateSuccess = true;
      return state;
    },
    [updateInstallationDate.rejected]: (state, { payload }) => {
      state.isUpdateInstallationDateFetching = false;
      state.isUpdateInstallationDateError = true;
      state.updateInstallationDateErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateInstallationDate.pending]: (state) => {
      state.isUpdateInstallationDateFetching = true;
    },
  },
});

export const {
  clearGetCustomerPortalDetailsState,
  clearUpdateProductSelectionState,
  clearUpdateInstallationDateState
} = customerPortalSlice.actions;
export const customerPortalSelector = (state) => state.customerPortal;
export default customerPortalSlice.reducer;
