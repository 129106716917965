import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { selectUser } from "app/store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import noImage from "../../../images/no-profile.jpg";
import {profileSelector, clearGetUserDataState, updateProfile, updateProfilePhoto, clearUpdateProfileState, clearUpdateProfilePhotoState} from 'app/store/profileSlice';
import * as Constants from '../../configs/constants';
import toast from 'react-hot-toast';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fcfcfb",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    color: "rgb(17, 24, 39)",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function UpdateUserDetails(props) {
  const userDetails = useSelector(selectUser);
  const { userProfileImage } = useSelector(selectUser);
  const { isUpdateProfilePhotoFetching, isUpdateProfilePhotoSuccess, updateProfilePhotoErrorMessage, isUpdateProfilePhotoError, isUpdateProfileFetching, isUpdateProfileSuccess, updateProfileErrorMessage, isUpdateProfileError } = useSelector(profileSelector);
  const dispatch = useDispatch();
  const { userData } = useSelector(profileSelector);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const inputFile = useRef(null);
  const [selectedPhoto, setSelectedPhoto] = useState(noImage);
  const [file, setFile] = useState("");

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "first-name": {
        setFirstName(e.target.value);
        setFirstNameError(false);
        break;
      }
      case "last-name": {
        setLastName(e.target.value);
        setLastNameError(false);
        break;
      }
      case "customer-email": {
        setEmail(e.target.value);
        setEmailError(false);
        break;
      }
      case "customer-phone": {
        setPhone(e.target.value);
        setPhoneError(false);
        break;
      }
      default: {
        console.log("Default", e.target.id);
        break;
      }
    }
  };

//   useEffect(() => {
//     if (userProfileImage) {
//       setSelectedPhoto(userProfileImage);
//     }
//   }, [userProfileImage]);

  useEffect(() => {
    if(userData){
        setFirstName((userData.first_name || ""));
        setLastName((userData.last_name || ""));
        setEmail(userData.email);
        setPhone(userData.phone);
        setSelectedPhoto(userData.user_profile_image ? Constants.API_URL.replace("index.php/","")+"profile_images/"+userData.user_profile_image : "");
    }
  },[userData]);

  const handleUploadImage = () => {};

  const handleUploadFile = () => {
    if (file && selectedPhoto) {
      const formData = new FormData();
      formData.append("file", file); // appending file
      formData.append("removePhoto", "N"); // appending file
      dispatch(uploadPhoto(formData));
    } else {
      //removePhoto
      dispatch(uploadPhoto({ removePhoto: "Y" }));
    }
  };

  const handleFileChange = (e) => {
    console.log(e);
    if (e.target.files[0]) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function (e) {
        setSelectedPhoto(reader.result);
      }.bind(this);
      setFile(e.target.files[0]);
    }
  };

  const handleSelectPhoto = () => {
    inputFile.current.click();
  };

  const handleSaveUserData = () => {
    if(firstName.trim() == ""){
        setFirstNameError(true);
        return false;
    }
    if(phone.trim() == ""){
        setPhoneError(true);
        return false;
    }
    let userDataObj = {
        firstName:firstName,
        lastName:lastName,
        phone:phone,
    }
    dispatch(updateProfile(userDataObj));
    if (file && selectedPhoto) {
        const formData = new FormData();
        formData.append("file", file); // appending file
        formData.append("removePhoto", "N"); // appending file
        dispatch(updateProfilePhoto(formData));
    }
  }

  useEffect(() => {
    if(props.saveUserDetails){
        handleSaveUserData();
    }
  },[props.saveUserDetails]);

  useEffect(() => {
    if(isUpdateProfilePhotoSuccess || isUpdateProfileSuccess ){
        toast.success("Profile details updated successfully");
        dispatch(clearUpdateProfilePhotoState());
        dispatch(clearUpdateProfileState());
        props.handleSaveUserDetails(false);
        props.handleProfileClose();
    }
    if(isUpdateProfilePhotoError || isUpdateProfileError){
        toast.success("Please try again.");
        dispatch(clearUpdateProfilePhotoState());
        dispatch(clearUpdateProfileState());
        props.handleSaveUserDetails(false);
        props.handleProfileClose();
    }
  }, [isUpdateProfilePhotoError, isUpdateProfilePhotoSuccess, isUpdateProfileError, isUpdateProfileSuccess, ]);

  return (
    <div className={isMobile ? "margin-top-20 mobile-profile-update" : "margin-top-20 web-profile-update"}>
      <div className="pb-16 items-center">
        <InputLabel shrink htmlFor="customer-name" className="form-input-label">
          Avatar
        </InputLabel>
        <img
          src={selectedPhoto || noImage}
          className="no-profile-image display-profile-image"
        />
        <Button
          onClick={() => handleSelectPhoto()}
          className="profile-image-upload-btn"
        >
          Browse Photo
        </Button>
        <input
          className="select-file display-none"
          aria-label="Select File"
          onChange={handleFileChange}
          id="image-upload"
          fullWidth
          type="file"
          multiple
          ref={inputFile}
        />
      </div>
      <div className="pb-16 items-center">
        <InputLabel shrink htmlFor="first-name" className="form-input-label">
          First Name
        </InputLabel>
        <BootstrapInput
          onChange={(e) => handleInputChange(e)}
          value={firstName}
          id="first-name"
          className="form-input-content"
          error={true}
        />
        <div
          className={
            firstNameError
              ? "form-field-error"
              : "form-field-error field-hide"
          }
        >
          Please enter your First Name
        </div>
      </div>
      <div className="pb-16 items-center">
        <InputLabel shrink htmlFor="last-name" className="form-input-label">
          Last Name
        </InputLabel>
        <BootstrapInput
          onChange={(e) => handleInputChange(e)}
          value={lastName}
          id="last-name"
          className="form-input-content"
          error={true}
        />
        <div
          className={
            lastNameError
              ? "form-field-error"
              : "form-field-error field-hide"
          }
        >
          Please enter your Last Name
        </div>
      </div>
      <div className="pb-16 items-center">
        <InputLabel
          shrink
          htmlFor="customer-email"
          className="form-input-label"
        >
          Email
        </InputLabel>
        <BootstrapInput
          onChange={(e) => handleInputChange(e)}
          value={email}
          id="customer-email"
          className="form-input-content"
          error={true}
          disabled
        />
        <div
          className={
            emailError ? "form-field-error" : "form-field-error field-hide"
          }
        >
          Please enter your Email
        </div>
      </div>
      <div className="pb-16 items-center">
        <InputLabel
          shrink
          htmlFor="customer-phone"
          className="form-input-label"
        >
          Phone
        </InputLabel>
        <BootstrapInput
          onChange={(e) => handleInputChange(e)}
          value={phone}
          id="customer-phone"
          className="form-input-content"
          error={true}
        />
        <div
          className={
            phoneError ? "form-field-error" : "form-field-error field-hide"
          }
        >
          Please enter your Phone Number
        </div>
      </div>
    </div>
  );
}
