import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  partnersInviteSelector,
  partnersSelector
} from "app/store/partnersSlice";
import UpdateUserDetails from "./UpdateUserDetails";
import ChangePassword from "./ChangePassword";
import { selectUser } from "app/store/userSlice";
import { getUserData } from "app/store/profileSlice";

export default function ProfilePage(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.open);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { invite_list, partners_list } = useSelector(partnersInviteSelector);
  const [tabValue, setTabValue] = React.useState(0);
  const [saveUserDetails, setSaveUserDetails] = React.useState(false);
  const [savePassword, setSavePassword] = React.useState(false);
  const { isRequestAQuoteSuccess } = useSelector(partnersSelector);
  const userDetails = useSelector(selectUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      dispatch(getUserData());
    }
  }, [props.open]);

  function handleTabChange(event, value) {
    setTabValue(value);
  }

  const handleClose = () => {
    setOpen(false);
    props.handleProfilePageClose();
  };

  const handleSave = () => {
    if (tabValue == 0) {
      setSaveUserDetails(true);
    }
    if (tabValue == 1) {
      setSavePassword(true);
    }
  };

  const handleSaveUserDetails = (flag) => {
    setSaveUserDetails(flag);
  };

  const handleSavePassword = (flag) => {
    setSavePassword(flag);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="sci-dialog user-profile-dialog"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <IconButton
              onClick={() => handleClose()}
              className="user-profile-icon-button"
            >
              <CloseIcon className="user-profile-icon" />
            </IconButton>
            <Typography variant="div" className="dialog-title">
              My Profile
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
          className={
            isMobile
              ? "mobile-user-profile-dialog-content"
              : "web-user-profile-dialog-content"
          }
        >
          <div className="user-profile-dialog-tabs-div">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: "w-full border-b-1" }}
            >
              <Tab
                className="jobs-list-tab"
                label={
                  <React.Fragment>
                    <div>
                      <FuseSvgIcon size={18} className="user-profile-page-icon">
                        heroicons-solid:user
                      </FuseSvgIcon>
                      <span>Edit Profile&nbsp;&nbsp;</span>
                    </div>
                  </React.Fragment>
                }
              />
              <Tab
                className="jobs-list-tab"
                label={
                  <React.Fragment>
                    <div>
                      <FuseSvgIcon size={18} className="user-profile-page-icon">
                        heroicons-solid:key
                      </FuseSvgIcon>
                      <span>Password&nbsp;&nbsp;</span>
                    </div>
                  </React.Fragment>
                }
              />
            </Tabs>
            <div className="w-full">
              {tabValue === 0 && (
                <UpdateUserDetails
                  saveUserDetails={saveUserDetails}
                  handleSaveUserDetails={handleSaveUserDetails}
                  handleProfileClose={handleClose}
                />
              )}
              {tabValue === 1 && (
                <ChangePassword
                  savePassword={savePassword}
                  handleSavePassword={handleSavePassword}
                  handleProfileClose={handleClose}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button
            onClick={() => handleClose()}
            size="medium"
            className="dialog-button-back"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSave()}
            size="medium"
            className="dialog-button-continue"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
