import { lazy } from 'react';

const PartnersList = lazy(() => import('./PartnersList'));
const PartnerDetailsPage = lazy(() => import('./PartnerDetailsPage'));

const JobsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'partners',
      element: <PartnersList />,
    },
    {
      path: 'partner/:partnerUniqueId/*',
      element: <PartnerDetailsPage />,
    },
  ],
};

export default JobsConfig;
