import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const requestInvoice = createAsyncThunk(
  'invoice/request',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/requestInvoice', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCoordinatorInvoices = createAsyncThunk(
  'invoice/getCoordinator',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/getCoordinatorInvoices', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const getInvoicesByJob = createAsyncThunk(
  'invoice/getInvoicesByJob',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      let APIUrl = Constants.API_URL + 'Invoices/getInvoicesByJob';
      if (userData.jobUniqueId) {
        APIUrl = Constants.API_URL + 'UserAuth/getInvoicesByJob'
      }
      return await axios.post(APIUrl, userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getInvoiceById = createAsyncThunk(
  'invoice/getInvoiceById',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/getInvoiceById', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateInvoiceStatus = createAsyncThunk(
  'invoice/updateStatus',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'UserAuth/updateInvoiceReadStatus', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createInvoice = createAsyncThunk(
  'invoice/create',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/createInvoice', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveInvoice = createAsyncThunk(
  'invoice/approve',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/approveInvoice', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const declineInvoice = createAsyncThunk(
  'invoice/decline',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/declineInvoice', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cancelInvoice = createAsyncThunk(
  'invoice/cancel',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Invoices/cancelInvoice', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getChargebacksByJob = createAsyncThunk(
  "jobDetail/chargebacks",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Invoices/getChargebacksByJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    isRequestInvoiceFetching: false,
    isRequestInvoiceSuccess: false,
    isRequestInvoiceError: false,
    requestInvoiceErrorMessage: '',
    isGetCoordinatorInvoicesFetching: false,
    isGetCoordinatorInvoicesSuccess: false,
    isGetCoordinatorInvoicesError: false,
    getCoordinatorInvoicesErrorMessage: '',
    invoices: [],
    isGetInvoicesByJobFetching: false,
    isGetInvoicesByJobSuccess: false,
    isGetInvoicesByJobError: false,
    getInvoicesByJobErrorMessage: '',
    invoicesByJob: [],
    isGetInvoiceByIdFetching: false,
    isGetInvoiceByIdSuccess: false,
    isGetInvoiceByIdError: false,
    getInvoiceByIdErrorMessage: '',
    invoiceById: [],
    isCreateInvoiceFetching: false,
    isCreateInvoiceSuccess: false,
    isCreateInvoiceError: false,
    createInvoiceErrorMessage: '',
    isApproveInvoiceFetching: false,
    isApproveInvoiceSuccess: false,
    isApproveInvoiceError: false,
    approveInvoiceErrorMessage: '',
    isDeclineInvoiceFetching: false,
    isDeclineInvoiceSuccess: false,
    isDeclineInvoiceError: false,
    declineInvoiceErrorMessage: '',
    isCancelInvoiceFetching: false,
    isCancelInvoiceSuccess: false,
    isCancelInvoiceError: false,
    cancelInvoiceErrorMessage: '',

    isInvoiceStatusUpdateFetching: false,
    isInvoiceStatusUpdateSuccess: false,
    isInvoiceStatusUpdateError: false,
    invoiceStatusUpdateErrorMessage: '',

    isGetChargebacksByJobFetching: false,
    isGetChargebacksByJobSuccess: false,
    isGetChargebacksByJobError: false,
    getChargebacksByJobErrorMessage: "",
    jobChargebacks: []
  },
  reducers: {
    clearRequestInvoiceState: (state) => {
      state.isRequestInvoiceError = false;
      state.isRequestInvoiceSuccess = false;
      state.isRequestInvoiceFetching = false;
      return state;
    },
    clearGetInvoicesByJobState: (state) => {
      state.isGetInvoicesByJobError = false;
      state.isGetInvoicesByJobSuccess = false;
      state.isGetInvoicesByJobFetching = false;
      state.getInvoicesByJobErrorMessage = "";
      state.invoicesByJob = [];
      return state;
    },
    clearGetInvoiceByIdState: (state) => {
      state.isGetInvoiceByIdError = false;
      state.isGetInvoiceByIdSuccess = false;
      state.isGetInvoiceByIdFetching = false;
      state.getInvoiceByIdErrorMessage = "";
      state.invoiceById = [];
      return state;
    },
    clearCreateInvoiceState: (state) => {
      state.isCreateInvoiceError = false;
      state.isCreateInvoiceSuccess = false;
      state.isCreateInvoiceFetching = false;
      state.createInvoiceErrorMessage = "";
      return state;
    },
    clearApproveInvoiceState: (state) => {
      state.isApproveInvoiceError = false;
      state.isApproveInvoiceSuccess = false;
      state.isApproveInvoiceFetching = false;
      state.approveInvoiceErrorMessage = "";
      return state;
    },
    clearDeclineInvoiceState: (state) => {
      state.isDeclineInvoiceError = false;
      state.isDeclineInvoiceSuccess = false;
      state.isDeclineInvoiceFetching = false;
      state.declineInvoiceErrorMessage = "";
      return state;
    },
    clearInvoiceStatusUpdateState: (state) => {
      state.isInvoiceStatusUpdateError = false;
      state.isInvoiceStatusUpdateSuccess = false;
      state.isInvoiceStatusUpdateFetching = false;
      state.invoiceStatusUpdateErrorMessage = "";
      return state;
    },
    clearCancelInvoiceState: (state) => {
      state.isCancelInvoiceError = false;
      state.isCancelInvoiceSuccess = false;
      state.isCancelInvoiceFetching = false;
      state.cancelInvoiceErrorMessage = "";
      return state;
    },
    clearGetCoordinatorInvoicesState: (state) => {
      state.isGetCoordinatorInvoicesError = false;
      state.isGetCoordinatorInvoicesSuccess = false;
      state.isGetCoordinatorInvoicesFetching = false;
      state.getCoordinatorInvoicesErrorMessage = "";
      state.invoices = [];
      return state;
    },
  },
  extraReducers: {
    [requestInvoice.fulfilled]: (state, { payload }) => {
      state.isRequestInvoiceFetching = false;
      if (payload.status == 'error') {
        state.isRequestInvoiceError = true;
        state.requestInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isRequestInvoiceSuccess = true;
      }
    },
    [requestInvoice.pending]: (state) => {
      state.isRequestInvoiceFetching = true;
    },
    [requestInvoice.rejected]: (state, { payload }) => {
      state.isRequestInvoiceFetching = false;
      state.isRequestInvoiceError = true;
      state.requestInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getCoordinatorInvoices.fulfilled]: (state, { payload }) => {
      state.isGetCoordinatorInvoicesFetching = false;
      if (payload.status == 'error') {
        state.isGetCoordinatorInvoicesError = true;
        state.getInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isGetCoordinatorInvoicesSuccess = true;
        state.invoices = payload;
      }
    },
    [getCoordinatorInvoices.pending]: (state) => {
      state.isGetCoordinatorInvoicesFetching = true;
    },
    [getCoordinatorInvoices.rejected]: (state, { payload }) => {
      state.isGetCoordinatorInvoicesFetching = false;
      state.isGetCoordinatorInvoicesError = true;
      state.getInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getInvoicesByJob.fulfilled]: (state, { payload }) => {
      state.isGetInvoicesByJobFetching = false;
      if (payload.status == 'error') {
        state.isGetInvoicesByJobError = true;
        state.getInvoicesByJobErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isGetInvoicesByJobSuccess = true;
        state.invoicesByJob = payload && payload.invoices || [];
        state.jobChargebacks = payload && payload.chargebacks || [];
      }
    },
    [getInvoicesByJob.pending]: (state) => {
      state.isGetInvoicesByJobFetching = true;
    },
    [getInvoicesByJob.rejected]: (state, { payload }) => {
      state.isGetInvoicesByJobFetching = false;
      state.isGetInvoicesByJobError = true;
      state.getInvoicesByJobErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },

    [getInvoiceById.fulfilled]: (state, { payload }) => {
      state.isGetInvoiceByIdFetching = false;
      if (payload.status == 'error') {
        state.isGetInvoiceByIdError = true;
        state.getInvoiceByIdErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isGetInvoiceByIdSuccess = true;
        state.invoiceById = payload;
      }
    },
    [getInvoicesByJob.pending]: (state) => {
      state.isGetInvoiceByIdFetching = true;
    },
    [getInvoicesByJob.rejected]: (state, { payload }) => {
      state.isGetInvoiceByIdFetching = false;
      state.isGetInvoiceByIdError = true;
      state.getInvoiceByIdErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [createInvoice.fulfilled]: (state, { payload }) => {
      state.isCreateInvoiceFetching = false;
      if (payload.status == 'error') {
        state.isCreateInvoiceError = true;
        state.createInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isCreateInvoiceSuccess = true;
      }
    },
    [createInvoice.pending]: (state) => {
      state.isCreateInvoiceFetching = true;
    },
    [createInvoice.rejected]: (state, { payload }) => {
      state.isCreateInvoiceFetching = false;
      state.isCreateInvoiceError = true;
      state.createInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [declineInvoice.fulfilled]: (state, { payload }) => {
      state.isDeclineInvoiceFetching = false;
      if (payload.status == 'error') {
        state.isDeclineInvoiceError = true;
        state.declineInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isDeclineInvoiceSuccess = true;
      }
    },
    [declineInvoice.pending]: (state) => {
      state.isDeclineInvoiceFetching = true;
    },
    [declineInvoice.rejected]: (state, { payload }) => {
      state.isDeclineInvoiceFetching = false;
      state.isDeclineInvoiceError = true;
      state.declineInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [approveInvoice.fulfilled]: (state, { payload }) => {
      state.isApproveInvoiceFetching = false;
      if (payload.status == 'error') {
        state.isApproveInvoiceError = true;
        state.approveInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isApproveInvoiceSuccess = true;
      }
    },
    [approveInvoice.pending]: (state) => {
      state.isApproveInvoiceFetching = true;
    },
    [approveInvoice.rejected]: (state, { payload }) => {
      state.isApproveInvoiceFetching = false;
      state.isApproveInvoiceError = true;
      state.approveInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [cancelInvoice.fulfilled]: (state, { payload }) => {
      state.isCancelInvoiceFetching = false;
      if (payload.status == 'error') {
        state.isCancelInvoiceError = true;
        state.cancelInvoiceErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isCancelInvoiceSuccess = true;
      }
    },
    [cancelInvoice.pending]: (state) => {
      state.isCancelInvoiceFetching = true;
    },
    [cancelInvoice.rejected]: (state, { payload }) => {
      state.isCancelInvoiceFetching = false;
      state.isCancelInvoiceError = true;
      state.cancelInvoiceErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },

    [updateInvoiceStatus.fulfilled]: (state, { payload }) => {
      state.isInvoiceStatusUpdateFetching = false;
      if (payload.status == 'error') {
        state.isInvoiceStatusUpdateError = true;
        state.invoiceStatusUpdateErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else {
        state.isInvoiceStatusUpdateSuccess = true;
      }
    },
    [updateInvoiceStatus.pending]: (state) => {
      state.isInvoiceStatusUpdateFetching = true;
    },
    [updateInvoiceStatus.rejected]: (state, { payload }) => {
      state.isInvoiceStatusUpdateFetching = false;
      state.isInvoiceStatusUpdateError = true;
      state.invoiceStatusUpdateErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getChargebacksByJob.fulfilled]: (state, { payload }) => {
      state.isGetChargebacksByJobFetching = false;
      state.jobChargebacks = payload && payload.data || [];
      state.isGetChargebacksByJobSuccess = true;
      return state;
    },
    [getChargebacksByJob.rejected]: (state, { payload }) => {
      state.isGetChargebacksByJobFetching = false;
      state.isGetChargebacksByJobError = true;
      state.getChargebacksByJobErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getChargebacksByJob.pending]: (state) => {
      state.isGetChargebacksByJobFetching = true;
    },
  },
});




export const { clearRequestInvoiceState, clearCancelInvoiceState, clearGetCoordinatorInvoicesState, clearCreateInvoiceState, clearGetInvoiceByIdState, clearGetInvoicesByJobState, clearApproveInvoiceState, clearDeclineInvoiceState, clearInvoiceStatusUpdateState } = invoicesSlice.actions;

export const invoicesSelector = (state) => state.invoices;
export const invoicesListSelector = (state) => state.invoices || [];
export const invoicesListByJobSelector = (state) => state.invoices && state.invoices.invoicesByJob || [];
export const invoicesListByIdSelector = (state) => state.invoices && state.invoices.invoiceById || [];
export const requestedInvoicesListSelector = (state) => state.invoices && state.invoices.invoices.requested || [];
export const submittedInvoicesListSelector = (state) => state.invoices && state.invoices.invoices.submitted || [];

export default invoicesSlice.reducer;
