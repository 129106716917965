import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

export const setAdminUser = createAsyncThunk(
  "user/setAdminUser",
  async (user, { dispatch, getState }) => {
    return user;
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    userData: {},
  },
  reducers: {},
  extraReducers: {
    [setAdminUser.fulfilled]: (state, action) => {
        if(action.payload && action.payload.account_type && action.payload.account_type === Constants.SUPER_ADMIN){
            state.userData = action.payload;
        }
    },
  },
});

export const {  } = adminSlice.actions;
export const adminSelector = (state) => state.admin;

export default adminSlice.reducer;
