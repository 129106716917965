import FuseNavigation from "@fuse/core/FuseNavigation";
import clsx from "clsx";
import { memo, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNavigation,
  updateConnectedNavigation,
  updateConnectedwithProductCatalogNavigation,
  updateRoofingNavigation,
  updateRoofingWithProductCatalogNavigation,
  updateProjectManagerWithOutProductCatalogNavigation,
  updateSalesAdminNavigation,
  updateSalesRepNavigation,
  updateSalesUserNavigation,
  updateProjectManagerNavigation,
  updateSolarNavigation,
  updateSolarWithOutCalendarNavigation,
  updateSolarWithOutProductCatalogNavigation,
  updateSuperAdmingNavigation,
} from "app/store/fuse/navigationSlice";
import { selectUser } from "app/store/userSlice";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarCloseMobile } from "app/store/fuse/navbarSlice";
import {
  CONNECTED_CONTRACTOR,
  PROJECT_MANAGER,
  SALES_ONLY_ADMIN,
  SALES_ONLY_USER,
  SALES_REPRESENTATIVE,
  SOLAR_COMPANY,
  SUPER_ADMIN,
} from "../../configs/constants";
import { adminSelector } from "app/store/adminSlice";
import { accessRulesSelector } from "app/store/accessRulesSlice";
import { solarAccountWithOutProductCatalogNavigationConfig } from "app/configs/navigationConfig";

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const user = useSelector(selectUser);
  const { userData } = useSelector(adminSelector);
  const { rules } = useSelector(accessRulesSelector);
  const sessionId = sessionStorage.getItem("userSessionId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      userData &&
      userData.account_type &&
      userData.account_type === SUPER_ADMIN &&
      !sessionId
    ) {
      dispatch(updateSuperAdmingNavigation());
    } else if (
      user &&
      user.account_type &&
      user.account_type == "Roofing Company"
    ) {
      if (rules && rules.is_customer_portal_accessible) {
        dispatch(updateRoofingWithProductCatalogNavigation());
      } else {
        dispatch(updateRoofingNavigation());
      }
    } else if (
      user &&
      user.account_type &&
      user.account_type == CONNECTED_CONTRACTOR
    ) {
      if (rules && rules.is_customer_portal_accessible) {
        dispatch(updateConnectedwithProductCatalogNavigation());
      } else {
        dispatch(updateConnectedNavigation());
      }
    } else if (
      user &&
      user.account_type &&
      user.account_type == SALES_ONLY_ADMIN
    ) {
      dispatch(updateSalesAdminNavigation());
    } else if (
      user &&
      user.account_type &&
      user.account_type == SALES_ONLY_USER
    ) {
      dispatch(updateSalesUserNavigation());
    } else if (
      user &&
      user.account_type &&
      user.account_type == SALES_REPRESENTATIVE
    ) {
      dispatch(updateSalesRepNavigation());
    } else if (
      user &&
      user.account_type &&
      user.account_type == PROJECT_MANAGER
    ) {
      if (rules && rules.is_customer_portal_accessible) {
        dispatch(updateProjectManagerNavigation());
      } else {
        dispatch(updateProjectManagerWithOutProductCatalogNavigation());
      }
    } else if (user && user.account_type && user.account_type === SUPER_ADMIN) {
      dispatch(updateSuperAdmingNavigation());
    } else {
      if (user && user.account_type && user.account_type === SOLAR_COMPANY) {
        if (rules && rules.is_customer_portal_accessible) {
          dispatch(updateSolarNavigation());
        } else {
          dispatch(updateSolarWithOutProductCatalogNavigation());
        }
      } else {
        dispatch(updateSolarNavigation());
      }
    }
  }, [user]);

  return useMemo(() => {
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx("navigation", props.className)}
        navigation={navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
  ]);
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
