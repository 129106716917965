import { lazy } from 'react';
const ApprovalsLanding = lazy(() => import('./ApprovalsLanding'));

const ApprovalsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'approvals',
      element: <ApprovalsLanding />,
    },
  ],
};

export default ApprovalsConfig;
