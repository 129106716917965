import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createContact = createAsyncThunk(
  "contact/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Contacts/createContact", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contact/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Contacts/deleteContact", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getContacts = createAsyncThunk(
  "contact/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Contacts/getContactsList", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getContactDetailsByKey = createAsyncThunk(
  "contactdetails/key",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Contacts/getContactDetailsByKey", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateContact = createAsyncThunk(
  "contact/update",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Contacts/updateContact", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    isContactsFetching: false,
    isContactsSuccess: false,
    isContactsError: false,
    contactsErrorMessage: "",
    isCreateContactFetching: false,
    isCreateContactSuccess: false,
    isCreateContactError: false,
    createContactErrorMessage: "",
    isUpdateContactFetching: false,
    isUpdateContactSuccess: false,
    isUpdateContactError: false,
    updateContactErrorMessage: "",
    isDeleteContactFetching: false,
    isDeleteContactSuccess: false,
    isDeleteContactError: false,
    deleteContactErrorMessage: "",
    contactsList: [],
    contactId: "",
    isGetContactDetailsFetching: false,
    isGetContactDetailsSuccess: false,
    isGetContactDetailsError: false,
    getContactDetailsErrorMessage: "",
    contactDetails: [],
  },
  reducers: {
    clearCreateContactState: (state) => {
      state.isCreateContactError = false;
      state.isCreateContactSuccess = false;
      state.isCreateContactFetching = false;
      state.createContactErrorMessage = "";
      return state;
    },
    clearUpdateContactState: (state) => {
      state.isUpdateContactError = false;
      state.isUpdateContactSuccess = false;
      state.isUpdateContactFetching = false;
      state.updateContactErrorMessage = "";
      return state;
    },
    clearGetContactDetailsState: (state) => {
      state.isGetContactDetailsError = false;
      state.isGetContactDetailsSuccess = false;
      state.isGetContactDetailsFetching = false;
      state.getContactDetailsErrorMessage = "";
      state.contactDetails = [];
      return state;
    },
    clearDeleteContactState: (state) => {
      state.isDeleteContactError = false;
      state.isDeleteContactSuccess = false;
      state.isDeleteContactFetching = false;
      state.deleteContactErrorMessage = "";
      return state;
    },
    clearContactState: (state) => {
      state.isContactsError = false;
      state.isContactsSuccess = false;
      state.isContactsFetching = false;
      state.contactsList = [];
      return state;
    },
    updateContactDetailId: (state, payload) => {
      if (payload.payload.contactId) {
        state.contactId = payload.payload.contactId;
      }
    },
  },
  extraReducers: {
    [createContact.fulfilled]: (state, { payload }) => {
      state.isCreateContactFetching = false;
      if (payload.status == "error") {
        state.isCreateContactError = true;
        state.createContactErrorMessage =
          payload.error || "Unable to add contact member. Please try again.";
      } else {
        state.isCreateContactSuccess = true;
      }
    },
    [createContact.pending]: (state) => {
      state.isCreateContactFetching = true;
    },
    [createContact.rejected]: (state, { payload }) => {
      state.isCreateContactFetching = false;
      state.isCreateContactError = true;
      state.createContactErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateContact.fulfilled]: (state, { payload }) => {
      state.isUpdateContactFetching = false;
      if (payload.status == "error") {
        state.isUpdateContactError = true;
        state.updateContactErrorMessage =
          payload.error || "Unable to add contact member. Please try again.";
      } else {
        state.isUpdateContactSuccess = true;
      }
    },
    [updateContact.pending]: (state) => {
      state.isUpdateContactFetching = true;
    },
    [updateContact.rejected]: (state, { payload }) => {
      state.isUpdateContactFetching = false;
      state.isUpdateContactError = true;
      state.updateContactErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteContact.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isDeleteContactFetching = false;
      if (payload.status == "error") {
        state.isDeleteContactError = true;
        state.deleteContactErrorMessage =
          payload.error || "Unable to add contact member. Please try again.";
      } else {
        state.isDeleteContactSuccess = true;
      }
    },
    [deleteContact.pending]: (state) => {
      state.isDeleteContactFetching = true;
    },
    [deleteContact.rejected]: (state, { payload }) => {
      state.isDeleteContactFetching = false;
      state.isDeleteContactError = true;
      state.deleteContactErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getContactDetailsByKey.fulfilled]: (state, { payload }) => {
      state.isGetContactDetailsFetching = false;
      if (payload.status == "error") {
        state.isGetContactDetailsError = true;
        state.getContactDetailsErrorMessage =
          payload.error || "Unable to add contact member. Please try again.";
      } else {
        state.isGetContactDetailsSuccess = true;
        state.contactDetails = payload.data;
      }
    },
    [getContactDetailsByKey.pending]: (state) => {
      state.isGetContactDetailsFetching = true;
    },
    [getContactDetailsByKey.rejected]: (state, { payload }) => {
      state.isGetContactDetailsFetching = false;
      state.isGetContactDetailsError = true;
      state.getContactDetailsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getContacts.fulfilled]: (state, { payload }) => {
      state.isContactsFetching = false;
      state.contactsList = payload;
      state.isContactsSuccess = true;
      return state;
    },
    [getContacts.rejected]: (state, { payload }) => {
      state.isContactsFetching = false;
      state.isContactsError = true;
      state.contactErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getContacts.pending]: (state) => {
      state.isContactsFetching = true;
    },
  },
});

export const {
  clearContactState,
  clearCreateContactState,
  updateContactDetailId,
  clearDeleteContactState,
  clearGetContactDetailsState,
  clearUpdateContactState
} = contactsSlice.actions;

export const contactsSelector = (state) => state.contacts;
export const contactDetailsSelector = (state) => state.contacts && state.contacts.contactDetails;
export const contactJobsSelector = (state) => state.contacts && state.contacts.contactDetails;

export default contactsSlice.reducer;
