import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import * as Constants from '../../../configs/constants';
import { validateAndUpdateLocalStorageBuild } from 'src/app/main/common/Helpers';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid Credentials. Please try again.');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  // On every page refresh this method will be triggered
  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      const userData = {
        grant_type: "password",
        email: email,
        password: password,
        client_id: "testclient",
        client_secret: "testpass",
        scope: "userinfo cloud file node"
      }
      // To validate login credentials
      axios
        .post(Constants.API_URL + 'UserAuth/validateUserLogin', userData)
        .then((response) => {
          if (response.data.access_token) {
            this.setSession(response.data.access_token);            
            axios
            .post(Constants.API_URL + 'Resource', {access_token: response.data.access_token})
            .then((userResponse) => {
              if(userResponse.data.user){
                if(userResponse.data.user.account_type && userResponse.data.user.account_type == 'Roofing Company'){
                    userResponse.data.user.loginRedirectUrl = "quotes";
                }
                else{
                  userResponse.data.user.loginRedirectUrl = "jobs";
                }
                if(userResponse.data && userResponse.data.user && userResponse.data.user.account_type === Constants.SUPER_ADMIN){
                  localStorage.setItem('admin_jwt_access_token', userResponse.data.access_token);
                }
              }
              resolve(userResponse.data.user);
              this.emit('onLogin', userResponse.data.user, userResponse.data);
            });            
          } else {
            reject(response.data.error_description);
          }
        })
        .catch((error) => {
          reject('Invalid Credentials. Please try again!');
        });
    });
  };

  signInWithToken = () => {
    let access_token_value = this.getAccessToken();
    if(localStorage.getItem('admin_jwt_access_token') && localStorage.getItem('admin_jwt_access_token') != "" && !sessionStorage.getItem('userSessionId')){
      access_token_value = localStorage.getItem('admin_jwt_access_token');
    }
    return new Promise((resolve, reject) => {
      axios
      .post(Constants.API_URL + 'Resource', {access_token: access_token_value, user_session_id: this.getSessionToken()})
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token ? response.data.access_token : this.getAccessToken());
            resolve(response.data);
            if(response.data && response.data.build_version && response.data.build_version != ""){
              validateAndUpdateLocalStorageBuild(response.data.build_version);
            }
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('admin_jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    // const decoded = jwtDecode(access_token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   console.warn('access token expired');
    //   return false;
    // }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };

  getSessionToken = () => {
    return sessionStorage.getItem('userSessionId');
  }
}

const instance = new JwtService();

export default instance;
