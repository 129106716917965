import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createServiceTerritory = createAsyncThunk(
  'serviceTerritory/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritory/createServiceTerritory', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createPartnerServiceTerritory = createAsyncThunk(
  'partnerServiceTerritory/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritory/createPartnerServiceTerritory', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getServiceTerritories = createAsyncThunk(
  'serviceTerritories/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritory/getServiceTerritories', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPartnerServiceTerritories = createAsyncThunk(
  'partnerServiceTerritories/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritory/getPartnerServiceTerritories', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const deleteServiceTerritory = createAsyncThunk(
  'serviceTerritory/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/deleteServiceTerritory', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deletePartnerServiceTerritory = createAsyncThunk(
  'partnerServiceTerritory/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritory/deletePartnerServiceTerritory', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const serviceTerritoriesSlice = createSlice({
  name: 'serviceTerritories',
  initialState: {
    isServiceTerritoriesFetching: false,
    isServiceTerritoriesSuccess: false,
    isServiceTerritoriesError: false,
    serviceTerritoriesErrorMessage: '',
    isPartnerServiceTerritoriesFetching: false,
    isPartnerServiceTerritoriesSuccess: false,
    isPartnerServiceTerritoriesError: false,
    partnerServiceTerritoriesErrorMessage: '',
    isCreateServiceTerritoryFetching: false,
    isCreateServiceTerritorySuccess: false,
    isCreateServiceTerritoryError: false,
    createServiceTerritoryErrorMessage: '',
    isCreatePartnerServiceTerritoryFetching: false,
    isCreatePartnerServiceTerritorySuccess: false,
    isCreatePartnerServiceTerritoryError: false,
    createPartnerServiceTerritoryErrorMessage: '',
    serviceTerritoriesList:[],
    partnerServiceTerritoriesList:[],
    serviceTerritoryId:"",
    serviceTerritoryName:"",
    isDeleteServiceTerritoryFetching: false,
    isDeleteServiceTerritorySuccess: false,
    isDeleteServiceTerritoryError: false,
    deleteServiceTerritoryErrorMessage: '',
    isDeletePartnerServiceTerritoryFetching: false,
    isDeletePartnerServiceTerritorySuccess: false,
    isDeletePartnerServiceTerritoryError: false,
    deletePartnerServiceTerritoryErrorMessage: '',
  },
  reducers: {
    clearCreateServiceTerritoryState: (state) => {
      state.isCreateServiceTerritoryError = false;
      state.isCreateServiceTerritorySuccess = false;
      state.isCreateServiceTerritoryFetching = false;
      state.createServiceTerritoryErrorMessage = "";
      return state;
    },
    clearCreatePartnerServiceTerritoryState: (state) => {
      state.isCreatePartnerServiceTerritoryError = false;
      state.isCreatePartnerServiceTerritorySuccess = false;
      state.isCreatePartnerServiceTerritoryFetching = false;
      state.createPartnerServiceTerritoryErrorMessage = "";
      return state;
    },
    clearDeleteServiceTerritoryState: (state) => {
      state.isDeleteServiceTerritoryError = false;
      state.isDeleteServiceTerritorySuccess = false;
      state.isDeleteServiceTerritoryFetching = false;
      state.deleteServiceTerritoryErrorMessage = "";
      return state;
    },
    clearServiceTerritoriesState: (state) => {
      state.isServiceTerritoriesError = false;
      state.isServiceTerritoriesSuccess = false;
      state.isServiceTerritoriesFetching = false;
      state.serviceTerritoriesList=[];
      return state;
    },
    clearDeletePartnerServiceTerritoryState: (state) => {
      state.isDeletePartnerServiceTerritoryError = false;
      state.isDeletePartnerServiceTerritorySuccess = false;
      state.isDeletePartnerServiceTerritoryFetching = false;
      state.deletePartnerServiceTerritoryErrorMessage = "";
      return state;
    },
    clearPartnerServiceTerritoriesState: (state) => {
      state.isPartnerServiceTerritoriesError = false;
      state.isPartnerServiceTerritoriesSuccess = false;
      state.isPartnerServiceTerritoriesFetching = false;
      state.partnerServiceTerritoriesList=[];
      return state;
    },
    updateServiceTerritoryDetailId:(state, payload) =>{
      if(payload.payload.serviceTerritoryId){
        state.serviceTerritoryId = payload.payload.serviceTerritoryId;
      }
      if(payload.payload.serviceTerritoryName){
        state.serviceTerritoryName = payload.payload.serviceTerritoryName;
      }
    },
    updateServiceTerritoriesList:(state,payload) => {
      state.serviceTerritoriesList = payload.payload;
    }
  },
  extraReducers: {
    [createServiceTerritory.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isCreateServiceTerritoryFetching = false;      
      if(payload.status == 'error'){
        state.isCreateServiceTerritoryError = true;
        state.createServiceTerritoryErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCreateServiceTerritorySuccess = true;
      }
    },
    [createServiceTerritory.pending]: (state) => {
      state.isCreateServiceTerritoryFetching = true;
    },
    [createServiceTerritory.rejected]: (state, { payload }) => {
      state.isCreateServiceTerritoryFetching = false;
      state.isCreateServiceTerritoryError = true;
      state.createServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [createPartnerServiceTerritory.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isCreatePartnerServiceTerritoryFetching = false;      
      if(payload.status == 'error'){
        state.isCreatePartnerServiceTerritoryError = true;
        state.createPartnerServiceTerritoryErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCreatePartnerServiceTerritorySuccess = true;
      }
    },
    [createPartnerServiceTerritory.pending]: (state) => {
      state.isCreatePartnerServiceTerritoryFetching = true;
    },
    [createPartnerServiceTerritory.rejected]: (state, { payload }) => {
      state.isCreatePartnerServiceTerritoryFetching = false;
      state.isCreatePartnerServiceTerritoryError = true;
      state.createPartnerServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getServiceTerritories.fulfilled]: (state, { payload }) => {
      state.isServiceTerritoriesFetching = false;
      state.serviceTerritoriesList = payload && payload.data || [];
      state.isServiceTerritoriesSuccess = true;
      return state;
    },
    [getServiceTerritories.rejected]: (state, { payload }) => {
      state.isServiceTerritoriesFetching = false;
      state.isServiceTerritoriesError = true;
      state.serviceTerritoriesErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getServiceTerritories.pending]: (state) => {
      state.isServiceTerritoriesFetching = true;
    },    
    [deleteServiceTerritory.fulfilled]: (state, { payload }) => {
      state.isDeleteServiceTerritoryFetching = false;
      state.isDeleteServiceTerritorySuccess = true;
      return state;
    },
    [deleteServiceTerritory.rejected]: (state, { payload }) => {
      state.isDeleteServiceTerritoryFetching = false;
      state.isDeleteServiceTerritoryError = true;
      state.deleteServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deleteServiceTerritory.pending]: (state) => {
      state.isDeleteServiceTerritoryFetching = true;
    },
    [getPartnerServiceTerritories.fulfilled]: (state, { payload }) => {
      state.isPartnerServiceTerritoriesFetching = false;
      state.partnerServiceTerritoriesList = payload && payload.data || [];
      state.isPartnerServiceTerritoriesSuccess = true;
      return state;
    },
    [getPartnerServiceTerritories.rejected]: (state, { payload }) => {
      state.isPartnerServiceTerritoriesFetching = false;
      state.isPartnerServiceTerritoriesError = true;
      state.partnerServiceTerritoriesErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerServiceTerritories.pending]: (state) => {
      state.isPartnerServiceTerritoriesFetching = true;
    },    
    [deletePartnerServiceTerritory.fulfilled]: (state, { payload }) => {
      state.isDeletePartnerServiceTerritoryFetching = false;
      state.isDeletePartnerServiceTerritorySuccess = true;
      return state;
    },
    [deletePartnerServiceTerritory.rejected]: (state, { payload }) => {
      state.isDeletePartnerServiceTerritoryFetching = false;
      state.isDeletePartnerServiceTerritoryError = true;
      state.deletePartnerServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deletePartnerServiceTerritory.pending]: (state) => {
      state.isDeletePartnerServiceTerritoryFetching = true;
    },    
  },
});




export const { clearServiceTerritoriesState,clearCreatePartnerServiceTerritoryState, clearCreateServiceTerritoryState, updateServiceTerritoriesList, updateServiceTerritoryDetailId, clearDeleteServiceTerritoryState, clearDeletePartnerServiceTerritoryState, clearPartnerServiceTerritoriesState } = serviceTerritoriesSlice.actions;

export const serviceTerritoriesSelector = (state) => state.serviceTerritories;

export default serviceTerritoriesSlice.reducer;
