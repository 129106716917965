import { lazy } from 'react';

const ContactDetails = lazy(() => import('./ContactDetails'));

const ContactConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'contact/:contactUniqueId/*',
      element: <ContactDetails />,
    },
  ],
};

export default ContactConfig;
