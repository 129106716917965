function PrivacyPolicy() {
  const myHTML = `<html>

  <head>
  <meta http-equiv=Content-Type content="text/html; charset=utf-8">
  <meta name=Generator content="Microsoft Word 15 (filtered)">
  <style>
  <!--
   /* Font Definitions */
   @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
  @font-face
      {font-family:Roboto;
      panose-1:2 0 0 0 0 0 0 0 0 0;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin:0in;
      line-height:115%;
      font-size:11.0pt;
      font-family:"Arial",sans-serif;}
  .MsoChpDefault
      {font-family:"Arial",sans-serif;}
  .MsoPapDefault
      {line-height:115%;}
  @page WordSection1
      {size:8.5in 11.0in;
      margin:1.0in 1.0in 1.0in 1.0in;}
  div.WordSection1
      {page:WordSection1;}
   /* List Definitions */
   ol
      {margin-bottom:0in;}
  ul
      {margin-bottom:0in;}
  -->
  </style>
  
  </head>
  
  <body lang=EN-US style='word-wrap:break-word'>
  
  <div class=WordSection1>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-bottom:15.0pt;border:none;padding:0in;
  padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Privacy
  Policy for Subcontractit.com</span></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in;padding-bottom:
  0in;border-bottom:0in none #D9D9E3'><i><span lang=EN style='font-size:12.0pt;
  line-height:115%;font-family:Roboto;color:#374151'>Owned by Connected
  Contractors, LLC</span></i></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in;padding-bottom:
  0in;border-bottom:0in none #D9D9E3'><i><span lang=EN style='font-size:12.0pt;
  line-height:115%;font-family:Roboto;color:#374151'>Last Updated: 12/31/2023</span></i></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Introduction:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Welcome
  to Subcontractit.com, owned and operated by Connected Contractors, LLC.</span></p>
  
  <p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;border:none;
  padding:0in;padding-top:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Description
  of services: A platform enabling solar companies to hire contractors and
  partner with other companies, managed by Connected Contractors, LLC.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Commitment
  by Connected Contractors, LLC to the protection of user privacy and data
  security.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Information
  Collection and Use:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Types
  of information collected by Connected Contractors, LLC: Personal and
  professional details of solar companies, contractors, and customers (including
  names, contact information, job specifics).</span></p>
  
  <p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;border:none;
  padding:0in;padding-top:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Methods
  of collection: Direct user input on Subcontractit.com, and through third-party
  integrations.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Purpose
  of collection by Connected Contractors, LLC: To enable job creation,
  partnership facilitation, and quote processing on Subcontractit.com.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Information Sharing
  and Disclosure:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Circumstances
  under which Connected Contractors, LLC may share information: With partners and
  contractors for job management and quote preparation.</span></p>
  
  <p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;border:none;
  padding:0in;padding-top:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Connected
  Contractors, LLC’s measures to ensure third-party compliance with privacy
  standards.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Limitations
  on the use of customer information by partners and third parties.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Data Security:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Security
  measures implemented by Connected Contractors, LLC: Encryption, access control,
  and secure server deployment.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Connected
  Contractors, LLC’s responsibility and response protocols in the event of data
  breaches.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>User Rights and
  Control:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Rights
  granted to users by Connected Contractors, LLC regarding their data: Access,
  amendment, and deletion.</span></p>
  
  <p class=MsoNormal style='margin-left:.25in;text-indent:-.25in;border:none;
  padding:0in;padding-top:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>How
  users can exercise their rights on Subcontractit.com.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>User
  options to opt-out of specific data uses.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Cookies and Tracking
  Technologies:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Use
  of cookies and tracking technologies by Connected Contractors, LLC on
  Subcontractit.com for operational and analytical purposes.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>User
  options to manage these technologies.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Data Retention:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Connected
  Contractors, LLC’s policy on data retention duration and criteria.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Data
  deletion procedures post-retention period.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Cross-Border Data
  Transfers:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Connected
  Contractors, LLC’s approach to international data transfers.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Safeguards
  for international data transfers and compliance.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Changes to the
  Privacy Policy:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  0in;margin-left:.25in;margin-bottom:.0001pt;text-indent:-.25in;border:none;
  padding:0in;padding-bottom:0in;border-bottom:0in none #D9D9E3'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Connected
  Contractors, LLC’s rights to update the policy.</span></p>
  
  <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;
  margin-left:.25in;text-indent:-.25in;border:none;padding:0in;padding-top:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Notification
  methods for significant policy changes.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Contact Information:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:.25in;text-indent:-.25in;border:none;padding:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Contact
  details for privacy-related inquiries to Connected Contractors, LLC.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in'><span lang=EN style='font-size:
  12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Governing Law and
  Jurisdiction:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:.25in;text-indent:-.25in;border:none;padding:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Jurisdiction
  and legal framework governing the policy as established by Connected
  Contractors, LLC.</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-bottom:0in;border-bottom:
  0in none #D9D9E3'><span lang=EN style='font-size:12.0pt;line-height:115%;
  font-family:Roboto;color:#374151'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in;padding-bottom:
  0in;border-bottom:0in none #D9D9E3'><span lang=EN style='font-size:12.0pt;
  line-height:115%;font-family:Roboto;color:#374151'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in;padding-bottom:
  0in;border-bottom:0in none #D9D9E3'><span lang=EN style='font-size:12.0pt;
  line-height:115%;font-family:Roboto;color:#374151'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:0in;border:none;padding:0in;padding-top:0in'><span lang=EN
  style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Acknowledgment
  and Consent:</span></p>
  
  </div>
  
  <div style='border:none #D9D9E3 1.0pt;padding:0in 0in 0in 0in;margin-left:.25in;
  margin-right:0in'>
  
  <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
  15.0pt;margin-left:.25in;text-indent:-.25in;border:none;padding:0in'><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>●<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  lang=EN style='font-size:12.0pt;line-height:115%;font-family:Roboto;color:#374151'>Statement
  that usage of Subcontractit.com, under Connected Contractors, LLC, constitutes
  acknowledgment and consent to this Privacy Policy.</span></p>
  
  </div>
  
  </div>
  
  </body>
  
  </html>
  `;

  return (
    <div className="terms-and-conditions-div">
      <div dangerouslySetInnerHTML={{ __html: myHTML }}></div>
    </div>
  );
}

export default PrivacyPolicy;
