import {
  ROOFING_COMPANY,
  SOLAR_COMPANY,
  SUPER_ADMIN,
} from "app/configs/constants";
import {
  clearVerifyUserSessionState,
  usersSelector,
  verifyUserSession,
} from "app/store/usersSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function UserLoginSession(props) {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { sessionId } = routeParams;
  const {
    isVerifyUserSessionFetching,
    isVerifyUserSessionError,
    verifyUserSessionErrorMessage,
    isVerifyUserSessionSuccess,
    verifyUserSessionData,
  } = useSelector(usersSelector);

  useEffect(() => {
    if (sessionId) {
      sessionStorage.setItem("userSessionId", sessionId);
      dispatch(verifyUserSession({ userSessionId: sessionId }));
    }
  }, [sessionId]);

  useEffect(() => {
    if (isVerifyUserSessionSuccess && verifyUserSessionData) {
      dispatch(clearVerifyUserSessionState());
      if (
        verifyUserSessionData &&
        verifyUserSessionData.account_type &&
        verifyUserSessionData.account_type === SOLAR_COMPANY
      ) {
        window.location.href = window.location.origin + "/jobs";
      } else if (
        verifyUserSessionData &&
        verifyUserSessionData.account_type &&
        verifyUserSessionData.account_type === ROOFING_COMPANY
      ) {
        window.location.href = window.location.origin + "/quotes";
      } else {
        window.location.href = window.location.origin + "/dashboard";
      }
    }
    if (isVerifyUserSessionError) {
      dispatch(clearVerifyUserSessionState());
    }
  }, [isVerifyUserSessionError, isVerifyUserSessionSuccess]);
}
export default UserLoginSession;
