import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { selectUser } from "app/store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import {
  profileSelector,
  clearGetUserDataState,
  updateProfile,
  updateProfilePassword,
  clearUpdateProfilePasswordState
} from "app/store/profileSlice";
import * as Constants from "../../configs/constants";
import toast from "react-hot-toast";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fcfcfb",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    color: "rgb(17, 24, 39)",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function ChangePassword(props) {
  const dispatch = useDispatch();
  const { userData, isUpdateProfilePasswordFetching, isUpdateProfilePasswordSuccess, updateProfilePasswordErrorMessage, isUpdateProfilePasswordError } = useSelector(profileSelector);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);

  useEffect(() => {
    if(props.savePassword){
        handleSavePasswordData();
    }
  },[props.savePassword]);

  useEffect(() => {
    if(isUpdateProfilePasswordSuccess){
        toast.success("Password changed successfully");
        dispatch(clearUpdateProfilePasswordState());
        props.handleSavePassword(false);
        props.handleProfileClose();
    }
    if(isUpdateProfilePasswordError){
        toast.error("Please try again.");
        dispatch(clearUpdateProfilePasswordState());
        props.handleSavePassword(false);
        props.handleProfileClose();
    }

  },[isUpdateProfilePasswordSuccess, isUpdateProfilePasswordError])

  const handleSavePasswordData = () =>{
    if(confirmPassword !== password){
        setConfirmPasswordError(true);
        return false;
    }
    dispatch(updateProfilePassword({password:password}));
  }

  return (
    <div className="margin-top-20">
      <div className="pb-16 items-center">
        <InputLabel shrink htmlFor="password" className="form-input-label">
          Password
        </InputLabel>
        <BootstrapInput
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          id="password"
          className="form-input-content"
          error={true}
        />
        <div
          className={
            passwordError ? "form-field-error" : "form-field-error field-hide"
          }
        >
          Please enter your Password
        </div>
      </div>
      <div className="pb-16 items-center">
        <InputLabel
          shrink
          htmlFor="confirm-password"
          className="form-input-label"
        >
          Confirm Password
        </InputLabel>
        <BootstrapInput
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          id="confirm-password"
          className="form-input-content"
          error={true}
        />
        <div
          className={
            confirmPasswordError
              ? "form-field-error"
              : "form-field-error field-hide"
          }
        >
          Passwords should match
        </div>
      </div>
    </div>
  );
}
