import { lazy } from 'react';
const AccountsList = lazy(() => import('./AccountsList'));
const AccountDetails = lazy(() => import('./AccountDetails'));

const AccountsListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'accounts',
      element: <AccountsList />,
    },
    {
      path: 'account/:accountUniqueId',
      element: <AccountDetails />,
    },
  ],
};

export default AccountsListConfig;
