import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';
import axios from 'axios';

export const fetchUserBytoken = createAsyncThunk(
  'users/fetchUserByTokenJob',
  async ({ token }, thunkAPI) => {
    try {
      const userData = new URLSearchParams({
        access_token:token
      });
      return await axios.post(Constants.API_URL+'Resource', userData)
      .then(function(response){
        if (response.status === 200) {            
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response);
        }
      })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createJob = createAsyncThunk(
  'job/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/createJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobs = createAsyncThunk(
  'job/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/getJobs', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteJob = createAsyncThunk(
  'job/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/deleteJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJobScheduledDate = createAsyncThunk(
  'job/updateDate',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Jobs/updateJobScheduledDate', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    isJobFetching: false,
    isJobSuccess: false,
    isJobError: false,
    jobErrorMessage: '',
    isJobsFetching: false,
    isJobsSuccess: false,
    isJobsError: false,
    jobsErrorMessage: '',
    jobsList:[],
    editJobDetails:[],
    isDeleteJobFetching:false,
    isDeleteJobSuccess:false,
    isDeleteJobError:false,
    deleteJobErrorMessage:"",
    isUpdateJobDateFetching: false,
    isUpdateJobDateSuccess: false,
    isUpdateJobDateError: false,
    updateJobDateErrorMessage: '',
    selectedPartner:"0",
    selectedJobTypeTab:0
  },
  reducers: {
    clearJobState: (state) => {
      state.isJobError = false;
      state.isJobSuccess = false;
      state.isJobFetching = false;
      return state;
    },
    clearJobsState: (state) => {
      state.isJobsError = false;
      state.isJobsSuccess = false;
      state.isJobsFetching = false;
      state.jobsList=[];
      return state;
    },
    clearDeleteJobState: (state) => {
      state.isDeleteJobError = false;
      state.isDeleteJobSuccess = false;
      state.isDeleteJobFetching = false;
      state.deleteJobErrorMessage = "";
      return state;
    },
    clearUpdateJobDateState: (state) => {
      state.isUpdateJobDateError = false;
      state.isUpdateJobDateFetching = false;
      state.isUpdateJobDateSuccess = false;
      state.updateJobDateErrorMessage = "";
      return state;
    },
    updateEditJobDetails:(state, payload) =>{
      state.editJobDetails = payload.payload
    },
    updateSelectedPartner:(state, { payload }) => {
      state.selectedPartner = payload || "0"
      return state;
    },
    updateSelectedJobTab:(state, { payload }) => {
      state.selectedJobTypeTab = payload || 0
      return state;
    }
  },
  extraReducers: {
    [createJob.fulfilled]: (state, { payload }) => {
      state.isJobFetching = false;
      if(payload.status == 'error'){
        state.isJobError = true;
        state.jobErrorMessage = payload.error || "Unable to create job. Please try aagain.";
      }
      else{
        state.isJobSuccess = true;
      }
      return state;
    },
    [createJob.rejected]: (state, { payload }) => {
      state.isJobFetching = false;
      state.isJobError = true;
      state.jobErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [createJob.pending]: (state) => {
      state.isJobFetching = true;
    },
    [getJobs.fulfilled]: (state, { payload }) => {
      state.isJobsFetching = false;
      state.jobsList = payload;
      state.isJobsSuccess = true;
      return state;
    },
    [getJobs.rejected]: (state, { payload }) => {
      state.isJobsFetching = false;
      state.isJobsError = true;
      state.jobsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getJobs.pending]: (state) => {
      state.isJobsFetching = true;
    },
    [deleteJob.fulfilled]: (state, { payload }) => {
      state.isDeleteJobFetching = false;
      state.isDeleteJobSuccess = true;
      return state;
    },
    [deleteJob.rejected]: (state, { payload }) => {
      state.isDeleteJobFetching = false;
      state.isDeleteJobError = true;
      state.deleteJobErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deleteJob.pending]: (state) => {
      state.isDeleteJobFetching = true;
    },
    [fetchUserBytoken.pending]: (state) => {
      state.isJobFetching = true;
    },
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.isJobFetching = false;
      // state.isJobSuccess = true;

      state.first_name = payload.data.first_name ? payload.data.first_name : "";
      state.last_name = payload.data.last_name ? payload.data.last_name : "";
      state.account_type = payload.data.account_type ? payload.data.account_type : "";
    },
    [fetchUserBytoken.rejected]: (state) => {
      console.log('fetchUserBytoken');
      state.isJobFetching = false;
      state.isJobError = true;
      state.jobErrorMessage = "Session Expired. Please login again.";
    },    
    [updateJobScheduledDate.fulfilled]: (state, { payload }) => {
      state.isUpdateJobDateFetching = false;
      state.isUpdateJobDateSuccess = true;
      return state;
    },
    [updateJobScheduledDate.rejected]: (state, { payload }) => {
      state.isUpdateJobDateFetching = false;
      state.isUpdateJobDateError = true;
      state.updateJobDateErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [updateJobScheduledDate.pending]: (state) => {
      state.isUpdateJobDateFetching = true;
    },
  },
});

export const { clearJobState, clearJobsState, updateEditJobDetails,clearUpdateJobDateState, updateSelectedPartner, updateSelectedJobTab, clearDeleteJobState } = jobsSlice.actions;

export const jobsSelector = (state) => state.jobs.jobsList;
export const editJobSelector = (state) => state.jobs.editJobDetails;
export const createJobSelector = (state) => state.jobs;
export const newJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.new || [];
export const notScheduledJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.not_scheduled || [];
export const readyToScheduleJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.ready_to_schedule || [];
export const completedJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.completed || [];
export const cancelledJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.cancelled || [];
export const scheduledJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.scheduled || [];
export const submittedJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.submitted || [];
export const requestedJobsSelector = (state) => state.jobs && state.jobs.jobsList && state.jobs.jobsList.requested || [];

export default jobsSlice.reducer;