import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createCalendarEvent = createAsyncThunk(
  "calendarEvent/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "CalendarEvents/createCalendarEvent", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteCalendarEvent = createAsyncThunk(
  "calendarEvent/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "CalendarEvents/deleteCalendarEvent", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCalendarEvents = createAsyncThunk(
  "calendarEvent/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "CalendarEvents/getEvents", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCalendarEventDetailsByKey = createAsyncThunk(
  "calendarEventdetails/key",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "CalendarEvents/getCalendarEventDetailsByKey", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateCalendarEvent = createAsyncThunk(
  "calendarEvent/update",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "CalendarEvents/updateCalendarEvent", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const calendarEventsSlice = createSlice({
  name: "calendarEvents",
  initialState: {
    isCalendarEventsFetching: false,
    isCalendarEventsSuccess: false,
    isCalendarEventsError: false,
    calendarEventsErrorMessage: "",
    isCreateCalendarEventFetching: false,
    isCreateCalendarEventSuccess: false,
    isCreateCalendarEventError: false,
    createCalendarEventErrorMessage: "",
    isUpdateCalendarEventFetching: false,
    isUpdateCalendarEventSuccess: false,
    isUpdateCalendarEventError: false,
    updateCalendarEventErrorMessage: "",
    isDeleteCalendarEventFetching: false,
    isDeleteCalendarEventSuccess: false,
    isDeleteCalendarEventError: false,
    deleteCalendarEventErrorMessage: "",
    calendarEventsList: [],
    calendarEventId: "",
    isGetCalendarEventDetailsFetching: false,
    isGetCalendarEventDetailsSuccess: false,
    isGetCalendarEventDetailsError: false,
    getCalendarEventDetailsErrorMessage: "",
    calendarEventDetails: [],
  },
  reducers: {
    clearCreateCalendarEventState: (state) => {
      state.isCreateCalendarEventError = false;
      state.isCreateCalendarEventSuccess = false;
      state.isCreateCalendarEventFetching = false;
      state.createCalendarEventErrorMessage = "";
      return state;
    },
    clearUpdateCalendarEventState: (state) => {
      state.isUpdateCalendarEventError = false;
      state.isUpdateCalendarEventSuccess = false;
      state.isUpdateCalendarEventFetching = false;
      state.updateCalendarEventErrorMessage = "";
      return state;
    },
    clearGetCalendarEventDetailsState: (state) => {
      state.isGetCalendarEventDetailsError = false;
      state.isGetCalendarEventDetailsSuccess = false;
      state.isGetCalendarEventDetailsFetching = false;
      state.getCalendarEventDetailsErrorMessage = "";
      state.calendarEventDetails = [];
      return state;
    },
    clearDeleteCalendarEventState: (state) => {
      state.isDeleteCalendarEventError = false;
      state.isDeleteCalendarEventSuccess = false;
      state.isDeleteCalendarEventFetching = false;
      state.deleteCalendarEventErrorMessage = "";
      return state;
    },
    clearCalendarEventState: (state) => {
      state.isCalendarEventsError = false;
      state.isCalendarEventsSuccess = false;
      state.isCalendarEventsFetching = false;
      state.calendarEventsList = [];
      return state;
    },
    updateCalendarEventDetailId: (state, payload) => {
      if (payload.payload.calendarEventId) {
        state.calendarEventId = payload.payload.calendarEventId;
      }
    },
  },
  extraReducers: {
    [createCalendarEvent.fulfilled]: (state, { payload }) => {
      state.isCreateCalendarEventFetching = false;
      if (payload.status == "error") {
        state.isCreateCalendarEventError = true;
        state.createCalendarEventErrorMessage =
          payload.error || "Unable to add calendarEvent member. Please try again.";
      } else {
        state.isCreateCalendarEventSuccess = true;
      }
    },
    [createCalendarEvent.pending]: (state) => {
      state.isCreateCalendarEventFetching = true;
    },
    [createCalendarEvent.rejected]: (state, { payload }) => {
      state.isCreateCalendarEventFetching = false;
      state.isCreateCalendarEventError = true;
      state.createCalendarEventErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateCalendarEvent.fulfilled]: (state, { payload }) => {
      state.isUpdateCalendarEventFetching = false;
      if (payload.status == "error") {
        state.isUpdateCalendarEventError = true;
        state.updateCalendarEventErrorMessage =
          payload.error || "Unable to add calendarEvent member. Please try again.";
      } else {
        state.isUpdateCalendarEventSuccess = true;
      }
    },
    [updateCalendarEvent.pending]: (state) => {
      state.isUpdateCalendarEventFetching = true;
    },
    [updateCalendarEvent.rejected]: (state, { payload }) => {
      state.isUpdateCalendarEventFetching = false;
      state.isUpdateCalendarEventError = true;
      state.updateCalendarEventErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteCalendarEvent.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isDeleteCalendarEventFetching = false;
      if (payload.status == "error") {
        state.isDeleteCalendarEventError = true;
        state.deleteCalendarEventErrorMessage =
          payload.error || "Unable to add calendarEvent member. Please try again.";
      } else {
        state.isDeleteCalendarEventSuccess = true;
      }
    },
    [deleteCalendarEvent.pending]: (state) => {
      state.isDeleteCalendarEventFetching = true;
    },
    [deleteCalendarEvent.rejected]: (state, { payload }) => {
      state.isDeleteCalendarEventFetching = false;
      state.isDeleteCalendarEventError = true;
      state.deleteCalendarEventErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getCalendarEventDetailsByKey.fulfilled]: (state, { payload }) => {
      state.isGetCalendarEventDetailsFetching = false;
      if (payload.status == "error") {
        state.isGetCalendarEventDetailsError = true;
        state.getCalendarEventDetailsErrorMessage =
          payload.error || "Unable to add calendarEvent member. Please try again.";
      } else {
        state.isGetCalendarEventDetailsSuccess = true;
        state.calendarEventDetails = payload.data;
      }
    },
    [getCalendarEventDetailsByKey.pending]: (state) => {
      state.isGetCalendarEventDetailsFetching = true;
    },
    [getCalendarEventDetailsByKey.rejected]: (state, { payload }) => {
      state.isGetCalendarEventDetailsFetching = false;
      state.isGetCalendarEventDetailsError = true;
      state.getCalendarEventDetailsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getCalendarEvents.fulfilled]: (state, { payload }) => {
      state.isCalendarEventsFetching = false;
      state.calendarEventsList = payload;
      state.isCalendarEventsSuccess = true;
      return state;
    },
    [getCalendarEvents.rejected]: (state, { payload }) => {
      state.isCalendarEventsFetching = false;
      state.isCalendarEventsError = true;
      state.calendarEventErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getCalendarEvents.pending]: (state) => {
      state.isCalendarEventsFetching = true;
    },
  },
});

export const {
  clearCalendarEventState,
  clearCreateCalendarEventState,
  updateCalendarEventDetailId,
  clearDeleteCalendarEventState,
  clearGetCalendarEventDetailsState,
  clearUpdateCalendarEventState
} = calendarEventsSlice.actions;

export const calendarEventsSelector = (state) => state.calendarEvents;
export const calendarEventDetailsSelector = (state) => state.calendarEvents && state.calendarEvents.calendarEventDetails;
export const calendarEventJobsSelector = (state) => state.calendarEvents && state.calendarEvents.calendarEventDetails;

export default calendarEventsSlice.reducer;
