import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const setAccessRules = createAsyncThunk(
    "user/setAccessRules",
    async (rules, { dispatch, getState }) => {
      return rules;
    }
  );

export const accessRulesSlice = createSlice({
    name: "accessRules",
    initialState: {
      rules: {},
    },
    reducers: {},
    extraReducers: {
      [setAccessRules.fulfilled]: (state, action) => {
        if(action.payload && action.payload.access_rules){
            state.rules = action.payload.access_rules;
        }
      }
    }
});

export const {  } = accessRulesSlice.actions;
export const accessRulesSelector = (state) => state.accessRules;

export default accessRulesSlice.reducer;