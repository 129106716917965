import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const getUsersList = createAsyncThunk(
  "users/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/getUsersList", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("admin_jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const verifyUserSession = createAsyncThunk(
  "users/verifyUserSession",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Users/verifyUserSession", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isGetUsersListError: false,
    isGetUsersListSuccess: false,
    getUsersListErrorMessage: "",
    usersList: [],
    isVerifyUserSessionFetching: false,
    isVerifyUserSessionSuccess: false,
    isVerifyUserSessionError: false,
    verifyUserSessionErrorMessage: "",
    verifyUserSessionData: "",
    userSessionToken:""
  },
  reducers: {
    clearGetUsersListState: (state) => {
      state.isGetUsersListError = false;
      state.isGetUsersListSuccess = false;
      state.isGetUsersListFetching = false;
      state.getUsersListErrorMessage = "";
      state.usersList = [];
      return state;
    },
    clearVerifyUserSessionState: (state) => {
      state.isVerifyUserSessionError = false;
      state.isVerifyUserSessionFetching = false;
      state.isVerifyUserSessionSuccess = false;
      state.verifyUserSessionErrorMessage = "";
      state.verifyUserSessionData = "";
      state.userSessionToken = "";
      return state;
    },
  },
  extraReducers: {
    [getUsersList.fulfilled]: (state, { payload }) => {
      state.isGetUsersListFetching = false;
      state.usersList = payload;
      state.isGetUsersListSuccess = true;
      return state;
    },
    [getUsersList.rejected]: (state, { payload }) => {
      state.isGetUsersListFetching = false;
      state.isGetUsersListError = true;
      state.getUsersListErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getUsersList.pending]: (state) => {
      state.isGetUsersListFetching = true;
    },
    [verifyUserSession.fulfilled]: (state, { payload }) => {
      state.isVerifyUserSessionFetching = false;
      if (payload.status == "error") {
        state.isVerifyUserSessionError = true;
        state.verifyUserSessionErrorMessage = payload.error;
      } else {
        state.isVerifyUserSessionSuccess = true;
        state.verifyUserSessionData = payload.data;
      }
      return state;
    },
    [verifyUserSession.rejected]: (state, { payload }) => {
      state.isVerifyUserSessionFetching = false;
      state.isVerifyUserSessionError = true;
      state.verifyUserSessionErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [verifyUserSession.pending]: (state) => {
      state.isVerifyUserSessionFetching = true;
    },
  },
});

export const { clearGetUsersListState, clearVerifyUserSessionState } = usersSlice.actions;
export const usersSelector = (state) => state.users;

export default usersSlice.reducer;
