export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateAndUpdateLocalStorageBuild = (build) => {
  let buildData = localStorage.getItem("build");
  if (!buildData) {
    console.log("No Build is avialble");
    localStorage.setItem("build", build);
    emptyBrowserCache();
  }
  else if(buildData < build){
    console.log("Build is old.");
    localStorage.setItem("build", build);
    emptyBrowserCache();
  }
  else{
    console.log("Build is upto date");
  }
};

export const emptyBrowserCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
};
