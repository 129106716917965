import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import userInterfaceConfigs from '../main/user-interface/UserInterfaceConfigs';
import SignInConfig from '../main/sign-in/SignInConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';
import appsConfigs from '../main/apps/appsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import authRoleExamplesConfigs from '../main/auth/authRoleExamplesConfigs';
import DocumentationConfig from '../main/documentation/DocumentationConfig';
import JobsConfig from '../main/jobs/JobsConfig';
import UsersListConfig from '../main/users/UsersListConfig';
import AccountsListConfig from '../main/accounts/AccountsListConfig';
import DocumentsConfig from '../main/documents/DocumentsConfig';
import PartnersConfig from '../main/partners/PartnersConfig';
import JobConfig from '../main/job/JobConfig';
import CalendarAppConfig from '../main/calendar/CalendarAppConfig';
import DashboardConfig from '../main/dashboard/DashboardConfig';
import QuotesConfig from '../main/quotes/QuotesConfig';
import QuoteConfig from '../main/quote/QuoteConfig';
import OrganizationConfig from '../main/organization/OrganizationConfig';
import ProposalsConfig from '../main/proposals/ProposalsConfig';
import ContactsConfig from '../main/contacts/ContactsConfig';
import ContactConfig from '../main/contact/ContactConfig';
import ReportsConfig from '../main/reports/ReportsConfig';
import CustomerPortalConfig from '../main/customer-portal/CustomerPortalConfig';
import ApprovalsConfig from '../main/approvals/ApprovalsConfig';
import ProductCatalogsConfig from '../main/product-catalogs/ProductCatalogsConfig';

const routeConfigs = [
  ...appsConfigs,
  ...dashboardsConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  ...userInterfaceConfigs,
  DocumentationConfig,
  SignOutConfig,
  ForgotPasswordConfig,
  SignInConfig,
  SignUpConfig,
  JobsConfig,
  UsersListConfig,
  AccountsListConfig,
  DocumentsConfig,
  JobConfig,
  PartnersConfig,
  DashboardConfig,
  QuotesConfig,
  QuoteConfig,
  OrganizationConfig,
  ProposalsConfig,
  ContactsConfig,
  CalendarAppConfig,
  ContactConfig,
  ProductCatalogsConfig,
  CustomerPortalConfig,
  ReportsConfig,
  AccountsListConfig,
  ApprovalsConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="jobs" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
