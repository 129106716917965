import React from "react";
import { Fade, Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdateProductSelectionState, customerPortalSelector, updateProductSelection } from "app/store/customerPortalSlice";
import toast from "react-hot-toast";
const spanStyle = {
  padding: "20px",
  background: "#efefef",
  color: "#000000",
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "400px",
};
export default function ProductOrderImageViewer(props) {
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.open);
  const [slideImages, setSlideImages] = React.useState(props.imagesData);
  const [product, setProduct] = React.useState(props.productData);

  const {isUpdateProductSelectionFetching, isUpdateProductSelectionError, isUpdateProductSelectionSuccess} = useSelector(customerPortalSelector);
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  React.useEffect(() => {
    if(isUpdateProductSelectionError){
        toast.error("Unable update the product selection");
        dispatch(clearUpdateProductSelectionState());
        setOpen(false);
        props.handleProductImageViewerClose();
    }
    if(isUpdateProductSelectionSuccess){
        toast.success("Succesfully updated Shingle color");
        dispatch(clearUpdateProductSelectionState());
        setOpen(false);
        props.handleProductImageViewerClose();
        location.reload();
    }
  }, [isUpdateProductSelectionError, isUpdateProductSelectionSuccess]);

  React.useEffect(() => {
    setSlideImages(props.imagesData);
  }, [props.imagesData]);

  React.useEffect(() => {
    setProduct(props.productData);
  }, [props.productData]);

  const handleClose = () => {
    setOpen(false);
    props.handleProductImageViewerClose();
  };

  const handleConfirmOrder = () => {
    dispatch(updateProductSelection({productId: product.product_id, jobId: props.jobId}));
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="sci-dialog">
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="div" className="dialog-title">
              Product Images
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
          className={
            isMobile
              ? "mobile-create-job-dialog-content"
              : "web-create-job-dialog-content"
          }
        >
          <Slide autoplay={false}>
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    ...divStyle,
                    backgroundImage: `url(${slideImage.image_url})`,
                  }}
                  className="each-slide-effect"
                >
                  {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                </div>
              </div>
            ))}
          </Slide>
        </DialogContent>
        <DialogActions className='dialog-buttons'>
            <Button size="medium"  onClick={handleClose}>Cancel</Button>
            <Button size="medium" className="dialog-button-continue" onClick={() => handleConfirmOrder()}>Confirm Order</Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}
