import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createQuoteLineItem = createAsyncThunk(
    'quoteLineItem/createQuoteLineItem',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let createQuoteLineItemURL = Constants.API_URL + 'QuoteLineItems/createQuoteLineItem';
            return await axios.post(createQuoteLineItemURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
                    "Content-Type" : "multipart/form-data",
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getQuoteLineItems = createAsyncThunk(
    'quoteLineItems/list',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'QuoteLineItems/getQuoteLineItems';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteQuoteLineItem = createAsyncThunk(
    'quoteLineItems/delete',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'QuoteLineItems/deleteQuoteLineItem';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getQuoteLineItemAttachments = createAsyncThunk(
    'quoteLineItems/attachments/list',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'QuoteLineItems/getQuoteLineItemDetails';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const createQuoteLineItemSettings = createAsyncThunk(
    'quoteLineItem/createQuoteLineItemSettings',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let createQuoteLineItemURL = Constants.API_URL + 'QuoteLineItems/createQuoteLineItemSettings';
            return await axios.post(createQuoteLineItemURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQuoteLineItem = createAsyncThunk(
    'quoteLineItems/update',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'QuoteLineItems/updateQuoteLineItem';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
                    "Content-Type" : "multipart/form-data",
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const quoteLineItemsSlice = createSlice({
    name: 'quoteLineItems',
    initialState: {
        isQuoteLineItemsFetching: false,
        isQuoteLineItemsSuccess: false,
        isQuoteLineItemsError: false,
        quoteLineItemsErrorMessage: '',
        isCreateQuoteLineItemFetching: false,
        isCreateQuoteLineItemSuccess: false,
        isCreateQuoteLineItemError: false,
        createQuoteLineItemErrorMessage: '',
        quoteLineItemsList: [],
        quoteLineItemAttachmentsList:[],
        quoteLineItemId: "",
        quoteLineItemName: "",

        isDeleteQuoteLineItemFetching: false,
        isDeleteQuoteLineItemSuccess: false,
        isDeleteQuoteLineItemError: false,
        deleteQuoteLineItemErrorMessage: '',

        isUpdateQuoteLineItemFetching: false,
        isUpdateQuoteLineItemSuccess: false,
        isUpdateQuoteLineItemError: false,
        updateQuoteLineItemErrorMessage: '',

        isCreateQuoteLineItemSettingsFetching: false,
        isCreateQuoteLineItemSettingsSuccess: false,
        isCreateQuoteLineItemSettingsError: false,
        createQuoteLineItemSettingsErrorMessage: '',

        isQuoteLineItemAttachmentsFetching: false,
        isQuoteLineItemAttachmentsSuccess: false,
        isQuoteLineItemAttachmentsError: false,
        quoteLineItemAttachmentsErrorMessage: '',
    },
    reducers: {
        clearCreateQuoteLineItemState: (state) => {
            state.isCreateQuoteLineItemError = false;
            state.isCreateQuoteLineItemSuccess = false;
            state.isCreateQuoteLineItemFetching = false;
            state.quoteLineItemId = "";
            state.quoteLineItemName = "";
            return state;
        },
        clearCreateQuoteLineItemSettingsState: (state) => {
            state.isCreateQuoteLineItemSettingsError = false;
            state.isCreateQuoteLineItemSettingsSuccess = false;
            state.isCreateQuoteLineItemSettingsFetching = false;
            state.createQuoteLineItemSettingsErrorMessage = "";
            return state;
        },
        clearDeleteQuoteLineItemState: (state) => {
            state.isDeleteQuoteLineItemError = false;
            state.isDeleteQuoteLineItemSuccess = false;
            state.isDeleteQuoteLineItemFetching = false;
            state.deleteQuoteLineItemErrorMessage = "";
            return state;
        },
        clearUpdateQuoteLineItemState: (state) => {
            state.isUpdateQuoteLineItemError = false;
            state.isUpdateQuoteLineItemSuccess = false;
            state.isUpdateQuoteLineItemFetching = false;
            state.updateQuoteLineItemErrorMessage = "";
            return state;
        },
        clearQuoteLineItemsState: (state) => {
            state.isQuoteLineItemsError = false;
            state.isQuoteLineItemsSuccess = false;
            state.isQuoteLineItemsFetching = false;
            state.quoteLineItemsList = [];
            return state;
        },
        clearQuoteLineItemAttachmentsState: (state) => {
            state.isQuoteLineItemAttachmentsError = false;
            state.isQuoteLineItemAttachmentsSuccess = false;
            state.isQuoteLineItemAttachmentsFetching = false;
            state.quoteLineItemAttachmentsList = [];
            return state;
        }
    },
    extraReducers: {
        [createQuoteLineItem.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.isCreateQuoteLineItemFetching = false;
            state.isCreateQuoteLineItemSuccess = true;
        },
        [createQuoteLineItem.pending]: (state) => {
            state.isCreateQuoteLineItemFetching = true;
        },
        [createQuoteLineItem.rejected]: (state, { payload }) => {
            state.isCreateQuoteLineItemFetching = false;
            state.isCreateQuoteLineItemError = true;
            state.createQuoteLineItemErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [createQuoteLineItemSettings.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.isCreateQuoteLineItemSettingsFetching = false;
            state.isCreateQuoteLineItemSettingsSuccess = true;
        },
        [createQuoteLineItemSettings.pending]: (state) => {
            state.isCreateQuoteLineItemSettingsFetching = true;
        },
        [createQuoteLineItemSettings.rejected]: (state, { payload }) => {
            state.isCreateQuoteLineItemSettingsFetching = false;
            state.isCreateQuoteLineItemSettingsError = true;
            state.createQuoteLineItemSettingsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getQuoteLineItems.fulfilled]: (state, { payload }) => {
            state.isQuoteLineItemsFetching = false;
            state.quoteLineItemsList = payload;
            state.isQuoteLineItemsSuccess = true;
            return state;
        },
        [getQuoteLineItems.rejected]: (state, { payload }) => {
            state.isQuoteLineItemsFetching = false;
            state.isQuoteLineItemsError = true;
            state.quoteLineItemsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getQuoteLineItems.pending]: (state) => {
            state.isQuoteLineItemsFetching = true;
        },
        [getQuoteLineItemAttachments.fulfilled]: (state, { payload }) => {
            state.isQuoteLineItemAttachmentsFetching = false;
            state.quoteLineItemAttachmentsList = payload && payload.data && payload.data.attachments || [];
            state.isQuoteLineItemAttachmentsSuccess = true;
            return state;
        },
        [getQuoteLineItemAttachments.rejected]: (state, { payload }) => {
            state.isQuoteLineItemAttachmentsFetching = false;
            state.isQuoteLineItemAttachmentsError = true;
            state.quoteLineItemAttachmentsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getQuoteLineItemAttachments.pending]: (state) => {
            state.isQuoteLineItemAttachmentsFetching = true;
        },
        [deleteQuoteLineItem.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.isDeleteQuoteLineItemFetching = false;
            state.isDeleteQuoteLineItemSuccess = true;
        },
        [deleteQuoteLineItem.pending]: (state) => {
            state.isDeleteQuoteLineItemFetching = true;
        },
        [deleteQuoteLineItem.rejected]: (state, { payload }) => {
            state.isDeleteQuoteLineItemFetching = false;
            state.isDeleteQuoteLineItemError = true;
            state.deleteQuoteLineItemErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateQuoteLineItem.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload);
            state.isUpdateQuoteLineItemFetching = false;
            state.isUpdateQuoteLineItemSuccess = true;
        },
        [updateQuoteLineItem.pending]: (state) => {
            state.isUpdateQuoteLineItemFetching = true;
        },
        [updateQuoteLineItem.rejected]: (state, { payload }) => {
            state.isUpdateQuoteLineItemFetching = false;
            state.isUpdateQuoteLineItemError = true;
            state.updateQuoteLineItemErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
    },
});

export const { clearCreateQuoteLineItemSettingsState, clearQuoteLineItemAttachmentsState , clearQuoteLineItemsState, clearCreateQuoteLineItemState, clearDeleteQuoteLineItemState, clearUpdateQuoteLineItemState } = quoteLineItemsSlice.actions;

export const quoteLineItemsSelector = (state) => state.quoteLineItems;

export default quoteLineItemsSlice.reducer;