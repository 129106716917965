import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createManufacturer = createAsyncThunk(
  "manufacturer/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/createManufacturer",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteManufacturer = createAsyncThunk(
  "manufacturer/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/deleteManufacturer",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getManufacturers = createAsyncThunk(
  "manufacturers/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/getManufacturers",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobInstallationDates = createAsyncThunk(
  "productCatalogs/dates",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/getJobInstallationDates",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllProductsList = createAsyncThunk(
  "catalogs/products/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/getAllProductsList",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createProductCatalog = createAsyncThunk(
  "productCatalog/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/createProductCatalog",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteProductCatalog = createAsyncThunk(
  "productCatalog/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/deleteProductCatalog",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProductCatalogs = createAsyncThunk(
  "productCatalogs/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/getProductCatalogs",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createProduct = createAsyncThunk(
  "productCatalog/product/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "ProductCatalogs/createProduct", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "productCatalog/product/delete",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "ProductCatalogs/deleteProduct", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProducts = createAsyncThunk(
  "productCatalogs/products/list",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "ProductCatalogs/getProducts", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createProductVisibility = createAsyncThunk(
  "productCatalog/createVisibility",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/createProductVisibility",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateProductVisibility = createAsyncThunk(
  "productCatalog/updateVisibility",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/updateProductVisibility",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const shareCustomerPortalLinkEmail = createAsyncThunk(
  "productCatalog/shareCustomerPortalLinkEmail",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/sharePortalLinkViaEmail",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const shareCustomerPortalLinkSMS = createAsyncThunk(
  "productCatalog/shareCustomerPortalLinkSMS",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/sharePortalLinkViaSMS",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateInstallationDates = createAsyncThunk(
  "productCatalog/updateInstallationDates",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "ProductCatalogs/updateInstallationDates",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const productCatalogsSlice = createSlice({
  name: "productCatalogs",
  initialState: {
    isManufacturersFetching: false,
    isManufacturersSuccess: false,
    isManufacturersError: false,
    manufacturersErrorMessage: "",
    isProductsFetching: false,
    isProductsSuccess: false,
    isProductsError: false,
    productsErrorMessage: "",
    isCreateManufacturerFetching: false,
    isCreateManufacturerSuccess: false,
    isCreateManufacturerError: false,
    isCreateProductVisibilityFetching: false,
    isCreateProductVisibilitySuccess: false,
    isCreateProductVisibilityError: false,
    createProductVisibilityErrorMessage: "",
    createManufacturerErrorMessage: "",
    isDeleteManufacturerFetching: false,
    isDeleteManufacturerSuccess: false,
    isDeleteManufacturerError: false,
    deleteManufacturerErrorMessage: "",
    manufacturerList: [],
    manufacturerId: "",
    productVisibilityId: "",
    isProductCatalogsFetching: false,
    isProductCatalogsSuccess: false,
    isProductCatalogsError: false,
    manufacturersErrorMessage: "",

    isShareCustomerPortalLinkSMSFetching: false,
    isShareCustomerPortalLinkSMSSuccess: false,
    isShareCustomerPortalLinkSMSError: false,
    shareCustomerPortalLinkSMSErrorMessage: "",

    isUpdateInstallationDatesFetching: false,
    isUpdateInstallationDatesSuccess: false,
    isUpdateInstallationDatesError: false,
    updateInstallationDatesErrorMessage: "",

    isShareCustomerPortalLinkEmailFetching: false,
    isShareCustomerPortalLinkEmailSuccess: false,
    isShareCustomerPortalLinkEmailError: false,
    shareCustomerPortalLinkEmailErrorMessage: "",

    isProductCatalogsFetching: false,
    isProductCatalogsSuccess: false,
    isProductCatalogsError: false,
    manufacturersErrorMessage: "",

    isJobInstallationDatesFetching: false,
    isJobInstallationDatesSuccess: false,
    isJobInstallationDatesError: false,
    jobInstallationDatesErrorMessage: "",
    jobInstallationDates: [],

    isCreateProductCatalogFetching: false,
    isCreateProductCatalogSuccess: false,
    isCreateProductCatalogError: false,
    createProductCatalogErrorMessage: "",
    isDeleteProductCatalogFetching: false,
    isDeleteProductCatalogSuccess: false,
    isDeleteProductCatalogError: false,
    deleteProductCatalogErrorMessage: "",
    isCreateProductFetching: false,
    isCreateProductSuccess: false,
    isCreateProductError: false,
    createProductErrorMessage: "",
    isDeleteProductFetching: false,
    isDeleteProductSuccess: false,
    isDeleteProductError: false,
    deleteProductErrorMessage: "",
    productCatalogList: [],
    productList: [],
    productItemsList: [],
    visibilityProductsList: [],
  },
  reducers: {
    clearCreateManufacturerState: (state) => {
      state.isCreateManufacturerError = false;
      state.isCreateManufacturerSuccess = false;
      state.isCreateManufacturerFetching = false;
      state.createManufacturerErrorMessage = "";
      return state;
    },
    clearJobInstallationDatesState: (state) => {
      state.isJobInstallationDatesError = false;
      state.isJobInstallationDatesSuccess = false;
      state.isJobInstallationDatesFetching = false;
      state.jobInstallationDatesErrorMessage = "";
      state.jobInstallationDates = [];
      return state;
    },
    clearDeleteManufacturerState: (state) => {
      state.isDeleteManufacturerError = false;
      state.isDeleteManufacturerSuccess = false;
      state.isDeleteManufacturerFetching = false;
      state.deleteManufacturerErrorMessage = "";
      return state;
    },
    clearUpdateInstallationDatesState: (state) => {
      state.isUpdateInstallationDatesError = false;
      state.isUpdateInstallationDatesSuccess = false;
      state.isUpdateInstallationDatesFetching = false;
      state.updateInstallationDatesErrorMessage = "";
      return state;
    },
    clearManufacturersState: (state) => {
      state.isManufacturersError = false;
      state.isManufacturersSuccess = false;
      state.isManufacturersFetching = false;
      state.manufacturerList = [];
      return state;
    },
    clearCreateProductCatalogState: (state) => {
      state.isCreateProductCatalogError = false;
      state.isCreateProductCatalogSuccess = false;
      state.isCreateProductCatalogFetching = false;
      state.createProductCatalogErrorMessage = "";
      return state;
    },
    clearDeleteProductCatalogState: (state) => {
      state.isDeleteProductCatalogError = false;
      state.isDeleteProductCatalogSuccess = false;
      state.isDeleteProductCatalogFetching = false;
      state.deleteProductCatalogErrorMessage = "";
      return state;
    },
    clearProductCatalogsState: (state) => {
      state.isProductCatalogsError = false;
      state.isProductCatalogsSuccess = false;
      state.isProductCatalogsFetching = false;
      state.productCatalogList = [];
      return state;
    },
    clearCreateProductState: (state) => {
      state.isCreateProductError = false;
      state.isCreateProductSuccess = false;
      state.isCreateProductFetching = false;
      state.createProductErrorMessage = "";
      return state;
    },
    clearDeleteProductState: (state) => {
      state.isDeleteProductError = false;
      state.isDeleteProductSuccess = false;
      state.isDeleteProductFetching = false;
      state.deleteProductErrorMessage = "";
      return state;
    },
    clearProductsState: (state) => {
      state.isProductsError = false;
      state.isProductsSuccess = false;
      state.isProductsFetching = false;
      return state;
    },

    clearShareCustomerPortalLinkEmailState: (state) => {
      state.isShareCustomerPortalLinkEmailError = false;
      state.isShareCustomerPortalLinkEmailSuccess = false;
      state.isShareCustomerPortalLinkEmailFetching = false;
      state.shareCustomerPortalLinkEmailErrorMessage = "";
      return state;
    },

    clearShareCustomerPortalLinkSMSState: (state) => {
      state.isShareCustomerPortalLinkSMSError = false;
      state.isShareCustomerPortalLinkSMSSuccess = false;
      state.isShareCustomerPortalLinkSMSFetching = false;
      state.shareCustomerPortalLinkSMSErrorMessage = false;
      return state;
    },
  },
  extraReducers: {
    [createManufacturer.fulfilled]: (state, { payload }) => {
      state.isCreateManufacturerFetching = false;
      if (payload.status == "error") {
        state.isCreateManufacturerError = true;
        state.createManufacturerErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isCreateManufacturerSuccess = true;
      }
    },
    [createManufacturer.pending]: (state) => {
      state.isCreateManufacturerFetching = true;
    },
    [createManufacturer.rejected]: (state, { payload }) => {
      state.isCreateManufacturerFetching = false;
      state.isCreateManufacturerError = true;
      state.createAccountErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updateInstallationDates.fulfilled]: (state, { payload }) => {
      state.isUpdateInstallationDatesFetching = false;
      if (payload.status == "error") {
        state.isUpdateInstallationDatesError = true;
        state.updateInstallationDatesErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isUpdateInstallationDatesSuccess = true;
      }
    },
    [updateInstallationDates.pending]: (state) => {
      state.isUpdateInstallationDatesFetching = true;
    },
    [updateInstallationDates.rejected]: (state, { payload }) => {
      state.isUpdateInstallationDatesFetching = false;
      state.isUpdateInstallationDatesError = true;
      state.updateInstallationDatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteManufacturer.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isDeleteManufacturerFetching = false;
      if (payload.status == "error") {
        state.isDeleteManufacturerError = true;
        state.deleteManufacturerErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isDeleteManufacturerSuccess = true;
      }
    },
    [deleteManufacturer.pending]: (state) => {
      state.isDeleteManufacturerFetching = true;
    },
    [deleteManufacturer.rejected]: (state, { payload }) => {
      state.isDeleteManufacturerFetching = false;
      state.isDeleteManufacturerError = true;
      state.deleteManufacturerErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getManufacturers.fulfilled]: (state, { payload }) => {
      state.isManufacturersFetching = false;
      state.manufacturerList = (payload && payload.data) || [];
      state.isManufacturersSuccess = true;
      return state;
    },
    [getManufacturers.rejected]: (state, { payload }) => {
      state.isManufacturersFetching = false;
      state.isManufacturersError = true;
      state.manufacturersErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getManufacturers.pending]: (state) => {
      state.isManufacturersFetching = true;
    },
    [getJobInstallationDates.fulfilled]: (state, { payload }) => {
      state.isJobInstallationDatesFetching = false;
      state.jobInstallationDates = (payload && payload.data) || [];
      state.isJobInstallationDatesSuccess = true;
      return state;
    },
    [getJobInstallationDates.rejected]: (state, { payload }) => {
      state.isJobInstallationDatesFetching = false;
      state.isJobInstallationDatesError = true;
      state.jobInstallationDatesErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getJobInstallationDates.pending]: (state) => {
      state.isJobInstallationDatesFetching = true;
    },
    [getAllProductsList.fulfilled]: (state, { payload }) => {
      state.isProductsFetching = false;
      if (payload && payload.data) {
        state.manufacturerList =
          (payload && payload.data && payload.data.manufacturers) || [];
        state.productCatalogList =
          (payload && payload.data && payload.data.product_catalogs) || [];
        state.productList =
          (payload && payload.data && payload.data.products) || [];
        state.productItemsList =
          (payload && payload.data && payload.data.product_items_list) || [];
        state.visibilityProductsList =
          (payload && payload.data && payload.data.visibility_products) || [];
        if (
          payload.data.visibility_products &&
          payload.data.visibility_products.length > 0
        ) {
          state.productVisibilityId =
            payload.data.visibility_products[0].job_product_unique_id || "";
        }
        if(payload.data.product_visible_unique_id){
          state.productVisibilityId = payload.data.product_visible_unique_id;
        }
      }
      state.isProductsSuccess = true;
      return state;
    },
    [getAllProductsList.rejected]: (state, { payload }) => {
      state.isProductsFetching = false;
      state.isProductsError = true;
      state.productsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getAllProductsList.pending]: (state) => {
      state.isProductsFetching = true;
    },
    [createProductCatalog.fulfilled]: (state, { payload }) => {
      state.isCreateProductCatalogFetching = false;
      if (payload.status == "error") {
        state.isCreateProductCatalogError = true;
        state.createProductCatalogErrorMessage =
          payload.error || "Unable to add Catalog. Please try again.";
      } else {
        state.isCreateProductCatalogSuccess = true;
      }
    },
    [createProductCatalog.pending]: (state) => {
      state.isCreateProductCatalogFetching = true;
    },
    [createProductCatalog.rejected]: (state, { payload }) => {
      state.isCreateProductCatalogFetching = false;
      state.isCreateProductCatalogError = true;
      state.createProductCatalogErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [deleteProductCatalog.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isDeleteProductCatalogFetching = false;
      if (payload.status == "error") {
        state.isDeleteProductCatalogError = true;
        state.deleteProductCatalogErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isDeleteProductCatalogSuccess = true;
      }
    },
    [deleteProductCatalog.pending]: (state) => {
      state.isDeleteProductCatalogFetching = true;
    },
    [deleteProductCatalog.rejected]: (state, { payload }) => {
      state.isDeleteProductCatalogFetching = false;
      state.isDeleteProductCatalogError = true;
      state.deleteProductCatalogErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [createProductVisibility.fulfilled]: (state, { payload }) => {
      state.isCreateProductVisibilityFetching = false;
      if (payload.status == "error") {
        state.isCreateProductVisibilityError = true;
        state.createProductVisibilityErrorMessage =
          payload.error || "Unable to add Catalog. Please try again.";
      } else {
        state.isCreateProductVisibilitySuccess = true;
        state.productVisibilityId = payload.data;
      }
    },
    [createProductVisibility.pending]: (state) => {
      state.isCreateProductVisibilityFetching = true;
    },
    [createProductVisibility.rejected]: (state, { payload }) => {
      state.isCreateProductVisibilityFetching = false;
      state.isCreateProductVisibilityError = true;
      state.createProductVisibilityErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getProductCatalogs.rejected]: (state, { payload }) => {
      state.isProductCatalogsFetching = false;
      state.isProductCatalogsError = true;
      state.productCatalogsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getProductCatalogs.pending]: (state) => {
      state.isProductCatalogsFetching = true;
    },
    [getProductCatalogs.fulfilled]: (state, { payload }) => {
      state.isProductCatalogsFetching = false;
      state.productCatalogList = (payload && payload.data) || [];
      state.isProductCatalogsSuccess = true;
      return state;
    },
    [getProducts.fulfilled]: (state, { payload }) => {
      state.isProductsFetching = false;
      state.productsList = (payload && payload.data) || [];
      state.isProductsSuccess = true;
      return state;
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.isProductsFetching = false;
      state.isProductsError = true;
      state.productsErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [getProducts.pending]: (state) => {
      state.isProductsFetching = true;
    },
    [createProduct.fulfilled]: (state, { payload }) => {
      state.isCreateProductFetching = false;
      if (payload.status == "error") {
        state.isCreateProductError = true;
        state.createProductErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isCreateProductSuccess = true;
      }
    },
    [createProduct.pending]: (state) => {
      state.isCreateProductFetching = true;
    },
    [createProduct.rejected]: (state, { payload }) => {
      state.isCreateProductFetching = false;
      state.isCreateProductError = true;
      state.createAccountErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },

    [shareCustomerPortalLinkEmail.fulfilled]: (state, { payload }) => {
      state.isShareCustomerPortalLinkEmailFetching = false;
      if (payload.status == "error") {
        state.isShareCustomerPortalLinkEmailError = true;
        state.customerPortalLinkEmailErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isShareCustomerPortalLinkEmailSuccess = true;
      }
    },
    [shareCustomerPortalLinkEmail.pending]: (state) => {
      state.isShareCustomerPortalLinkEmailFetching = true;
    },
    [shareCustomerPortalLinkEmail.rejected]: (state, { payload }) => {
      state.isShareCustomerPortalLinkEmailFetching = false;
      state.isShareCustomerPortalLinkEmailError = true;
      state.customerPortalLinkEmailErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },

    [shareCustomerPortalLinkSMS.fulfilled]: (state, { payload }) => {
      state.isCustomerPortalLinkSMSFetching = false;
      if (payload.status == "error") {
        state.isCustomerPortalLinkSMSError = true;
        state.customerPortalLinkSMSErrorMessage =
          payload.error ||
          "Unable to add manufacturer member. Please try again.";
      } else {
        state.isCustomerPortalLinkSMSSuccess = true;
      }
    },
    [shareCustomerPortalLinkSMS.pending]: (state) => {
      state.isCustomerPortalLinkSMSFetching = true;
    },
    [shareCustomerPortalLinkSMS.rejected]: (state, { payload }) => {
      state.isCustomerPortalLinkSMSFetching = false;
      state.isCustomerPortalLinkSMSError = true;
      state.customerPortalLinkSMSErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
  },
});

export const {
  clearManufacturersState,
  clearCreateManufacturerState,
  clearDeleteManufacturerState,
  clearCreateProductCatalogState,
  clearDeleteProductCatalogState,
  clearProductCatalogsState,
  clearCreateProductState,
  clearDeleteProductState,
  clearProductsState,
  clearShareCustomerPortalLinkEmailState,
  clearShareCustomerPortalLinkSMSState,
  clearUpdateInstallationDatesState,
} = productCatalogsSlice.actions;

export const productCatalogsSelector = (state) => state.productCatalogs;

export default productCatalogsSlice.reducer;
