import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const requestProposal = createAsyncThunk(
  'proposal/request',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/requestProposal', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getCoordinatorProposals = createAsyncThunk(
  'proposal/getCoordinator',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/getCoordinatorProposals', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProposalsByJob = createAsyncThunk(
  'proposal/getProposalsByJob',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/getProposalsByJob', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProposalById = createAsyncThunk(
  'proposal/getProposalById',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/getProposalById', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createProposal = createAsyncThunk(
  'proposal/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/createProposal', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveProposal = createAsyncThunk(
  'proposal/approve',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/approveProposal', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const declineProposal = createAsyncThunk(
  'proposal/decline',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/declineProposal', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const cancelProposal = createAsyncThunk(
  'proposal/cancel',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Proposals/cancelProposalRequest', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token'),
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const proposalsSlice = createSlice({
  name: 'proposals',
  initialState: {
    isRequestProposalFetching: false,
    isRequestProposalSuccess: false,
    isRequestProposalError: false,
    requestProposalErrorMessage: '',
    isGetCoordinatorProposalsFetching: false,
    isGetCoordinatorProposalsSuccess: false,
    isGetCoordinatorProposalsError: false,
    getCoordinatorProposalsErrorMessage: '',
    proposals:[],
    isGetProposalsByJobFetching: false,
    isGetProposalsByJobSuccess: false,
    isGetProposalsByJobError: false,
    getProposalsByJobErrorMessage: '',
    proposalsByJob:[],
    isGetProposalByIdFetching: false,
    isGetProposalByIdSuccess: false,
    isGetProposalByIdError: false,
    getProposalByIdErrorMessage: '',
    proposalById:[],
    isCreateProposalFetching: false,
    isCreateProposalSuccess: false,
    isCreateProposalError: false,
    createProposalErrorMessage: '',
    isApproveProposalFetching: false,
    isApproveProposalSuccess: false,
    isApproveProposalError: false,
    approveProposalErrorMessage: '',
    isDeclineProposalFetching: false,
    isDeclineProposalSuccess: false,
    isDeclineProposalError: false,
    declineProposalErrorMessage: '',
    isCancelProposalFetching: false,
    isCancelProposalSuccess: false,
    isCancelProposalError: false,
    cancelProposalErrorMessage: '',
  },
  reducers: {
    clearRequestProposalState: (state) => {
      state.isRequestProposalError = false;
      state.isRequestProposalSuccess = false;
      state.isRequestProposalFetching = false;
      return state;
    },
    clearGetProposalsByJobState: (state) => {
      state.isGetProposalsByJobError = false;
      state.isGetProposalsByJobSuccess = false;
      state.isGetProposalsByJobFetching = false;
      state.getProposalsByJobErrorMessage = "";
      state.proposalsByJob = [];
      return state;
    },
    clearGetProposalByIdState: (state) => {
      state.isGetProposalByIdError = false;
      state.isGetProposalByIdSuccess = false;
      state.isGetProposalByIdFetching = false;
      state.getProposalByIdErrorMessage = "";
      state.proposalById = [];
      return state;
    },
    clearCreateProposalState: (state) => {
      state.isCreateProposalError = false;
      state.isCreateProposalSuccess = false;
      state.isCreateProposalFetching = false;
      state.createProposalErrorMessage = "";
      return state;
    },
    clearApproveProposalState: (state) => {
      state.isApproveProposalError = false;
      state.isApproveProposalSuccess = false;
      state.isApproveProposalFetching = false;
      state.approveProposalErrorMessage = "";
      return state;
    },
    clearDeclineProposalState: (state) => {
      state.isDeclineProposalError = false;
      state.isDeclineProposalSuccess = false;
      state.isDeclineProposalFetching = false;
      state.declineProposalErrorMessage = "";
      return state;
    },
    clearCancelProposalState: (state) => {
      state.isCancelProposalError = false;
      state.isCancelProposalSuccess = false;
      state.isCancelProposalFetching = false;
      state.cancelProposalErrorMessage = "";
      return state;
    },
    clearGetCoordinatorProposalsState: (state) => {
      state.isGetCoordinatorProposalsError = false;
      state.isGetCoordinatorProposalsSuccess = false;
      state.isGetCoordinatorProposalsFetching = false;
      state.getCoordinatorProposalsErrorMessage = "";
      state.proposals = [];
      return state;
    },
  },
  extraReducers: {
    [requestProposal.fulfilled]: (state, { payload }) => {
      state.isRequestProposalFetching = false;      
      if(payload.status == 'error'){
        state.isRequestProposalError = true;
        state.requestProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isRequestProposalSuccess = true;
      }
    },
    [requestProposal.pending]: (state) => {
      state.isRequestProposalFetching = true;
    },
    [requestProposal.rejected]: (state, { payload }) => {
      state.isRequestProposalFetching = false;
      state.isRequestProposalError = true;
      state.requestProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    }, 
    [getCoordinatorProposals.fulfilled]: (state, { payload }) => {
      state.isGetCoordinatorProposalsFetching = false;      
      if(payload.status == 'error'){
        state.isGetCoordinatorProposalsError = true;
        state.getProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isGetCoordinatorProposalsSuccess = true;
        state.proposals = payload;
      }
    },
    [getCoordinatorProposals.pending]: (state) => {
      state.isGetCoordinatorProposalsFetching = true;
    },
    [getCoordinatorProposals.rejected]: (state, { payload }) => {
      state.isGetCoordinatorProposalsFetching = false;
      state.isGetCoordinatorProposalsError = true;
      state.getProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getProposalsByJob.fulfilled]: (state, { payload }) => {
      state.isGetProposalsByJobFetching = false;      
      if(payload.status == 'error'){
        state.isGetProposalsByJobError = true;
        state.getProposalsByJobErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isGetProposalsByJobSuccess = true;
        state.proposalsByJob = payload;
      }
    },
    [getProposalsByJob.pending]: (state) => {
      state.isGetProposalsByJobFetching = true;
    },
    [getProposalsByJob.rejected]: (state, { payload }) => {
      state.isGetProposalsByJobFetching = false;
      state.isGetProposalsByJobError = true;
      state.getProposalsByJobErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },    
    
    [getProposalById.fulfilled]: (state, { payload }) => {
      state.isGetProposalByIdFetching = false;      
      if(payload.status == 'error'){
        state.isGetProposalByIdError = true;
        state.getProposalByIdErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isGetProposalByIdSuccess = true;
        state.proposalById = payload;
      }
    },
    [getProposalsByJob.pending]: (state) => {
      state.isGetProposalByIdFetching = true;
    },
    [getProposalsByJob.rejected]: (state, { payload }) => {
      state.isGetProposalByIdFetching = false;
      state.isGetProposalByIdError = true;
      state.getProposalByIdErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [createProposal.fulfilled]: (state, { payload }) => {
      state.isCreateProposalFetching = false;      
      if(payload.status == 'error'){
        state.isCreateProposalError = true;
        state.createProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCreateProposalSuccess = true;
      }
    },
    [createProposal.pending]: (state) => {
      state.isCreateProposalFetching = true;
    },
    [createProposal.rejected]: (state, { payload }) => {
      state.isCreateProposalFetching = false;
      state.isCreateProposalError = true;
      state.createProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },    
    [declineProposal.fulfilled]: (state, { payload }) => {
      state.isDeclineProposalFetching = false;      
      if(payload.status == 'error'){
        state.isDeclineProposalError = true;
        state.declineProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isDeclineProposalSuccess = true;
      }
    },
    [declineProposal.pending]: (state) => {
      state.isDeclineProposalFetching = true;
    },
    [declineProposal.rejected]: (state, { payload }) => {
      state.isDeclineProposalFetching = false;
      state.isDeclineProposalError = true;
      state.declineProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    }, 
    [approveProposal.fulfilled]: (state, { payload }) => {
      state.isApproveProposalFetching = false;      
      if(payload.status == 'error'){
        state.isApproveProposalError = true;
        state.approveProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isApproveProposalSuccess = true;
      }
    },
    [approveProposal.pending]: (state) => {
      state.isApproveProposalFetching = true;
    },
    [approveProposal.rejected]: (state, { payload }) => {
      state.isApproveProposalFetching = false;
      state.isApproveProposalError = true;
      state.approveProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    }, 
    [cancelProposal.fulfilled]: (state, { payload }) => {
      state.isCancelProposalFetching = false;      
      if(payload.status == 'error'){
        state.isCancelProposalError = true;
        state.cancelProposalErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCancelProposalSuccess = true;
      }
    },
    [cancelProposal.pending]: (state) => {
      state.isCancelProposalFetching = true;
    },
    [cancelProposal.rejected]: (state, { payload }) => {
      state.isCancelProposalFetching = false;
      state.isCancelProposalError = true;
      state.cancelProposalErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    }, 
  },
});




export const { clearRequestProposalState,clearCancelProposalState, clearGetCoordinatorProposalsState, clearCreateProposalState, clearGetProposalByIdState, clearGetProposalsByJobState, clearApproveProposalState, clearDeclineProposalState } = proposalsSlice.actions;

export const proposalsSelector = (state) => state.proposals;
export const proposalsListSelector = (state) => state.proposals || [];
export const proposalsListByJobSelector = (state) => state.proposals && state.proposals.proposalsByJob || [];
export const proposalsListByIdSelector = (state) => state.proposals && state.proposals.proposalById || [];
export const requestedProposalsListSelector = (state) => state.proposals && state.proposals.proposals.requested || [];
export const submittedProposalsListSelector = (state) => state.proposals && state.proposals.proposals.submitted || [];

export default proposalsSlice.reducer;
