import { lazy } from 'react';

const JobsPage = lazy(() => import('./JobsPage'));

const JobsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'jobs',
      element: <JobsPage />,
    },
  ],
};

export default JobsConfig;
