import SignUpPage from './SignUpPage';
import authRoles from '../../auth/authRoles';
import SignUpTrailAccount from './SignUpTrialAccount';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import InvoiceStatusUpdate from '../job/InvoiceStatusUpdate';

const SignUpConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'sign-up',
      element: <SignUpPage />,
    },
    {
      path: 'user/:id',
      element: <SignUpPage />,
    },
    {
      path: 'create-trial-account',
      element: <SignUpTrailAccount />,
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: 'InvoiceStatus/:jobUniqueId/:invoiceUniqueId/*',
      element: <InvoiceStatusUpdate />,
    },
  ],
};

export default SignUpConfig;
