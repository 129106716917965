import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createChargeback = createAsyncThunk(
    'chargeback/createChargeback',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let createChargebackURL = Constants.API_URL + 'Invoices/createJobInvoiceChargeback';
            return await axios.post(createChargebackURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getJobChargebacks = createAsyncThunk(
    'chargebacks/list',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getChargebacksURL = Constants.API_URL + 'Jobs/getChargebacks';
            return await axios.post(getChargebacksURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteChargeback = createAsyncThunk(
    'chargebacks/delete',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getChargebacksURL = Constants.API_URL + 'Invoices/deleteChargeback';
            return await axios.post(getChargebacksURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getJobChargebackAttachments = createAsyncThunk(
    'chargebacks/attachments/list',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getChargebacksURL = Constants.API_URL + 'Jobs/getChargebackDetails';
            return await axios.post(getChargebacksURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateChargeback = createAsyncThunk(
    'chargebacks/update',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getChargebacksURL = Constants.API_URL + 'Invoices/updateJobInvoiceChargeback';
            return await axios.post(getChargebacksURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token'),
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const chargebacksSlice = createSlice({
    name: 'chargebacks',
    initialState: {

        isJobChargebacksFetching: false,
        isJobChargebacksSuccess: false,
        isJobChargebacksError: false,
        jobChargebacksErrorMessage: '',

        isCreateChargebackFetching: false,
        isCreateChargebackSuccess: false,
        isCreateChargebackError: false,
        createChargebackErrorMessage: '',

        jobChargebacksList: [],
        chargebackAttachmentsList: [],
        chargebackId: "",
        chargebackName: "",

        isDeleteChargebackFetching: false,
        isDeleteChargebackSuccess: false,
        isDeleteChargebackError: false,
        deleteChargebackErrorMessage: '',

        isUpdateChargebackFetching: false,
        isUpdateChargebackSuccess: false,
        isUpdateChargebackError: false,
        updateChargebackErrorMessage: '',

    },
    reducers: {
        clearCreateChargebackState: (state) => {
            state.isCreateChargebackError = false;
            state.isCreateChargebackSuccess = false;
            state.isCreateChargebackFetching = false;
            state.chargebackId = "";
            state.chargebackName = "";
            return state;
        },
        clearDeleteChargebackState: (state) => {
            state.isDeleteChargebackError = false;
            state.isDeleteChargebackSuccess = false;
            state.isDeleteChargebackFetching = false;
            state.deleteChargebackErrorMessage = "";
            return state;
        },
        clearUpdateChargebackState: (state) => {
            state.isUpdateChargebackError = false;
            state.isUpdateChargebackSuccess = false;
            state.isUpdateChargebackFetching = false;
            state.updateChargebackErrorMessage = "";
            return state;
        },
        clearJobChargebacksState: (state) => {
            state.isJobChargebacksError = false;
            state.isJobChargebacksSuccess = false;
            state.isJobChargebacksFetching = false;
            state.jobChargebacksList = [];
            return state;
        }
    },
    extraReducers: {
        [createChargeback.fulfilled]: (state, { payload }) => {
            state.isCreateChargebackFetching = false;
            state.isCreateChargebackSuccess = true;
        },
        [createChargeback.pending]: (state) => {
            state.isCreateChargebackFetching = true;
        },
        [createChargeback.rejected]: (state, { payload }) => {
            state.isCreateChargebackFetching = false;
            state.isCreateChargebackError = true;
            state.createChargebackErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getJobChargebacks.fulfilled]: (state, { payload }) => {
            state.isJobChargebacksFetching = false;
            state.jobChargebacksList = payload;
            state.isJobChargebacksSuccess = true;
            return state;
        },
        [getJobChargebacks.rejected]: (state, { payload }) => {
            state.isJobChargebacksFetching = false;
            state.isJobChargebacksError = true;
            state.jobChargebacksErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getJobChargebacks.pending]: (state) => {
            state.isJobChargebacksFetching = true;
        },
        [deleteChargeback.fulfilled]: (state, { payload }) => {
            state.isDeleteChargebackFetching = false;
            state.isDeleteChargebackSuccess = true;
        },
        [deleteChargeback.pending]: (state) => {
            state.isDeleteChargebackFetching = true;
        },
        [deleteChargeback.rejected]: (state, { payload }) => {
            state.isDeleteChargebackFetching = false;
            state.isDeleteChargebackError = true;
            state.deleteChargebackErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateChargeback.fulfilled]: (state, { payload }) => {
            state.isUpdateChargebackFetching = false;
            state.isUpdateChargebackSuccess = true;
        },
        [updateChargeback.pending]: (state) => {
            state.isUpdateChargebackFetching = true;
        },
        [updateChargeback.rejected]: (state, { payload }) => {
            state.isUpdateChargebackFetching = false;
            state.isUpdateChargebackError = true;
            state.updateChargebackErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
    },
});

export const { clearJobChargebacksState, clearCreateChargebackState, clearDeleteChargebackState, clearUpdateChargebackState } = chargebacksSlice.actions;

export const chargebacksSelector = (state) => state.chargebacks;

export default chargebacksSlice.reducer;