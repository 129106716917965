import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createServiceTerritoryRule = createAsyncThunk(
  'serviceTerritoryRule/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritoryRules/createServiceTerritoryRule', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getServiceTerritoryRules = createAsyncThunk(
  'serviceTerritoryRules/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritoryRules/getServiceTerritoryRules', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const deleteServiceTerritoryRule = createAsyncThunk(
  'serviceTerritoryRule/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'ServiceTerritoryRules/deleteServiceTerritoryRule', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const serviceTerritoryRulesSlice = createSlice({
  name: 'serviceTerritoryRules',
  initialState: {
    isServiceTerritoryRulesFetching: false,
    isServiceTerritoryRulesSuccess: false,
    isServiceTerritoryRulesError: false,
    serviceTerritoryRulesErrorMessage: '',
    isCreateServiceTerritoryRuleFetching: false,
    isCreateServiceTerritoryRuleSuccess: false,
    isCreateServiceTerritoryRuleError: false,
    createServiceTerritoryErrorMessage: '',
    serviceTerritoryRulesList:[],
    serviceTerritoryId:"",
    serviceTerritoryName:"",
    isDeleteServiceTerritoryRuleFetching: false,
    isDeleteServiceTerritoryRuleSuccess: false,
    isDeleteServiceTerritoryRuleError: false,
    deleteServiceTerritoryErrorMessage: '',
  },
  reducers: {
    clearCreateServiceTerritoryRuleState: (state) => {
      state.isCreateServiceTerritoryRuleError = false;
      state.isCreateServiceTerritoryRuleSuccess = false;
      state.isCreateServiceTerritoryRuleFetching = false;
      return state;
    },
    clearDeleteServiceTerritoryRuleState: (state) => {
      state.isDeleteServiceTerritoryRuleError = false;
      state.isDeleteServiceTerritoryRuleSuccess = false;
      state.isDeleteServiceTerritoryRuleFetching = false;
      state.deleteServiceTerritoryErrorMessage = "";
      return state;
    },
    clearServiceTerritoryRulesState: (state) => {
      state.isServiceTerritoryRulesError = false;
      state.isServiceTerritoryRulesSuccess = false;
      state.isServiceTerritoryRulesFetching = false;
      state.serviceTerritoryRulesList=[];
      return state;
    },
    updateServiceTerritoryDetailId:(state, payload) =>{
      if(payload.payload.serviceTerritoryId){
        state.serviceTerritoryId = payload.payload.serviceTerritoryId;
      }
      if(payload.payload.serviceTerritoryName){
        state.serviceTerritoryName = payload.payload.serviceTerritoryName;
      }
    },
    updateServiceTerritoryRulesList:(state,payload) => {
      state.serviceTerritoryRulesList = payload.payload;
    }
  },
  extraReducers: {
    [createServiceTerritoryRule.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isCreateServiceTerritoryRuleFetching = false;      
      if(payload.status == 'error'){
        state.isCreateServiceTerritoryRuleError = true;
        state.createServiceTerritoryErrorMessage = payload.error || "Invalid file. Please try agian.";
      }
      else{
        state.isCreateServiceTerritoryRuleSuccess = true;
      }
    },
    [createServiceTerritoryRule.pending]: (state) => {
      state.isCreateServiceTerritoryRuleFetching = true;
    },
    [createServiceTerritoryRule.rejected]: (state, { payload }) => {
      state.isCreateServiceTerritoryRuleFetching = false;
      state.isCreateServiceTerritoryRuleError = true;
      state.createServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getServiceTerritoryRules.fulfilled]: (state, { payload }) => {
      state.isServiceTerritoryRulesFetching = false;
      state.serviceTerritoryRulesList = payload && payload.data || [];
      state.isServiceTerritoryRulesSuccess = true;
      return state;
    },
    [getServiceTerritoryRules.rejected]: (state, { payload }) => {
      state.isServiceTerritoryRulesFetching = false;
      state.isServiceTerritoryRulesError = true;
      state.serviceTerritoryRulesErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getServiceTerritoryRules.pending]: (state) => {
      state.isServiceTerritoryRulesFetching = true;
    },    
    [deleteServiceTerritoryRule.fulfilled]: (state, { payload }) => {
      state.isDeleteServiceTerritoryRuleFetching = false;
      state.isDeleteServiceTerritoryRuleSuccess = true;
      return state;
    },
    [deleteServiceTerritoryRule.rejected]: (state, { payload }) => {
      state.isDeleteServiceTerritoryRuleFetching = false;
      state.isDeleteServiceTerritoryRuleError = true;
      state.deleteServiceTerritoryErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deleteServiceTerritoryRule.pending]: (state) => {
      state.isDeleteServiceTerritoryRuleFetching = true;
    },    
  },
});




export const { clearServiceTerritoryRulesState, clearCreateServiceTerritoryRuleState, updateServiceTerritoryRulesList, updateServiceTerritoryDetailId, clearDeleteServiceTerritoryRuleState } = serviceTerritoryRulesSlice.actions;

export const serviceTerritoryRulesSelector = (state) => state.serviceTerritoryRules;

export default serviceTerritoryRulesSlice.reducer;
