import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const addJobCollaborator = createAsyncThunk(
    'job/addJobCollaborator',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'Jobs/addJobCollaboratorId', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getJobCollaborators = createAsyncThunk(
    'job/getJobCollaborators',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'Jobs/getJobCollaborators';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteJobCollaborator = createAsyncThunk(
    'job/deleteJobCollaborator',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'Jobs/deleteJobCollaboratorId', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const jobCollaboratorsSlice = createSlice({
    name: 'jobCollaborators',
    initialState: {
        isGetJobCollaboratorsFetching: false,
        isGetJobCollaboratorsSuccess: false,
        isGetJobCollaboratorsError: false,
        getJobCollaboratorsErrorMessage: '',

        isCreateJobCollaboratorFetching: false,
        isCreateJobCollaboratorSuccess: false,
        isCreateJobCollaboratorError: false,
        createJobCollaboratorErrorMessage: '',
        jobCollaborators: [],

        isDeleteJobCollaboratorFetching: false,
        isDeleteJobCollaboratorSuccess: false,
        isDeleteJobCollaboratorError: false,
        deleteJobCollaboratorErrorMessage: '',

    },
    reducers: {
        clearCreateJobCollaboratorState: (state) => {
            state.isCreateJobCollaboratorError = false;
            state.isCreateJobCollaboratorSuccess = false;
            state.isCreateJobCollaboratorFetching = false;
            state.createJobCollaboratorErrorMessage = "";
            return state;
        },

        clearDeleteJobCollaboratorState: (state) => {
            state.isDeleteJobCollaboratorError = false;
            state.isDeleteJobCollaboratorSuccess = false;
            state.isDeleteJobCollaboratorFetching = false;
            state.deleteJobCollaboratorErrorMessage = "";
            return state;
        },
        clearGetJobCollaboratorsState: (state) => {
            state.isGetJobCollaboratorsError = false;
            state.isGetJobCollaboratorsSuccess = false;
            state.isGetJobCollaboratorsFetching = false;
            state.getJobCollaboratorsErrorMessage = "";
            return state;
        },
        clearGetJobCollaboratorsFullState: (state) => {
            state.isGetJobCollaboratorsError = false;
            state.isGetJobCollaboratorsSuccess = false;
            state.isGetJobCollaboratorsFetching = false;
            state.getJobCollaboratorsErrorMessage = "";
            state.jobCollaborators = [];
            return state;
        },
    },
    extraReducers: {
        [addJobCollaborator.fulfilled]: (state, { payload }) => {
            state.isCreateJobCollaboratorFetching = false;
            state.isCreateJobCollaboratorSuccess = true;
        },
        [addJobCollaborator.pending]: (state) => {
            state.isCreateJobCollaboratorFetching = true;
        },
        [addJobCollaborator.rejected]: (state, { payload }) => {
            state.isCreateJobCollaboratorFetching = false;
            state.isCreateJobCollaboratorError = true;
            state.createJobCollaboratorErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [deleteJobCollaborator.fulfilled]: (state, { payload }) => {
            state.isDeleteJobCollaboratorFetching = false;
            state.isDeleteJobCollaboratorSuccess = true;
        },
        [deleteJobCollaborator.pending]: (state) => {
            state.isDeleteJobCollaboratorFetching = true;
        },
        [deleteJobCollaborator.rejected]: (state, { payload }) => {
            state.isDeleteJobCollaboratorFetching = false;
            state.isDeleteJobCollaboratorError = true;
            state.deleteJobCollaboratorErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getJobCollaborators.fulfilled]: (state, { payload }) => {
            state.isGetJobCollaboratorsFetching = false;
            state.jobCollaborators = payload && payload.data || [];
            state.isGetJobCollaboratorsSuccess = true;
            return state;
        },
        [getJobCollaborators.rejected]: (state, { payload }) => {
            state.isGetJobCollaboratorsFetching = false;
            state.isGetJobCollaboratorsError = true;
            state.getJobCollaboratorsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getJobCollaborators.pending]: (state) => {
            state.isGetJobCollaboratorsFetching = true;
        },
    }
});

export const { clearCreateJobCollaboratorState, clearGetJobCollaboratorsState, clearDeleteJobCollaboratorState, clearGetJobCollaboratorsFullState } = jobCollaboratorsSlice.actions;

export const jobCollaboratorsSelector = (state) => state.jobCollaborators;

export default jobCollaboratorsSlice.reducer;